// src/features/user/api/index.ts
import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "config/baseQuery";
import {
  UserResponse,
  UserCreateRequest,
  UserCreateResponse,
  UserPermissionResponse,
  AvatarConfig,
} from "features/user/types";
import { BaseEntity } from "features/crud/types";
import { CompanyListResponse } from "features/auth/types/company";

export interface UserCompanyProjectAccess {
  empresa_id: number;
  perfil_id: number;
  empresa_projeto_usuario_id: number;
}

export interface UserByIdData extends BaseEntity {
  usuario_id: number;
  email: string;
  telefone: string;
  nome: string;
  usuario_ativo: boolean;
  empresa_projeto_usuario: UserCompanyProjectAccess[];
}

export interface UserByIdResponse {
  mensagem: string;
  data: UserByIdData;
}

export interface GetUserCompaniesParams {
  filtro_generico?: string;
  page?: number;
  perPage?: number;
  sortDirection?: string;
  sortBy?: string;
}

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["User", "UserCompanies"],
  endpoints: (builder) => ({
    getUser: builder.query<UserResponse, void>({
      query: () => ({
        url: "usuario",
        method: "GET",
      }),
      providesTags: ["User"],
    }),

    getUserCompanies: builder.query<
      CompanyListResponse,
      GetUserCompaniesParams
    >({
      query: (params) => ({
        url: "usuario/empresas",
        method: "GET",
        params,
      }),
      providesTags: ["UserCompanies"],
      keepUnusedDataFor: 0,
    }),

    getUserCompaniesNameOnly: builder.query<
      CompanyListResponse,
      GetUserCompaniesParams
    >({
      query: (params) => ({
        url: "usuario/empresas/v2",
        method: "GET",
        params,
      }),
    }),

    getUserById: builder.query<UserByIdResponse, number>({
      query: (id) => ({
        url: `usuario/${id}`,
        method: "GET",
      }),
      providesTags: (_result, _error, id) => [{ type: "User", id }],
      keepUnusedDataFor: 0,
    }),

    createUser: builder.mutation<UserCreateResponse, UserCreateRequest>({
      query: (payload) => ({
        url: "usuario",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["User"],
    }),

    getUserPermission: builder.query<
      UserPermissionResponse,
      { permissao_id: number; projeto_id?: number }
    >({
      query: ({ permissao_id, projeto_id }) => ({
        url: `usuario/permissao/${permissao_id}`,
        params: { projeto_id },
        method: "GET",
      }),
    }),

    registerMenuAccess: builder.mutation<void, number>({
      query: (menuId) => ({
        url: `usuario/acessomenu/${menuId}`,
        method: "POST",
      }),
    }),

    updateAvatarConfig: builder.mutation<
      { mensagem: string; data: AvatarConfig | null },
      { userId: number; config: AvatarConfig }
    >({
      query: ({ userId, config }) => ({
        url: `usuario/${userId}/avatar`,
        method: "PUT",
        body: config,
      }),
      invalidatesTags: ["User"],
    }),
  }),
});

export const {
  useGetUserQuery,
  useGetUserCompaniesQuery,
  useGetUserCompaniesNameOnlyQuery,
  useGetUserByIdQuery,
  useCreateUserMutation,
  useGetUserPermissionQuery,
  useRegisterMenuAccessMutation,
  useUpdateAvatarConfigMutation,
} = userApi;
