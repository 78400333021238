import { useCallback, useMemo } from "react";
import { Search, SlidersHorizontal } from "lucide-react";
import {
  ColumnDefinition,
  TableProps,
} from "common/components/ServerTable/types";
import { useTable } from "common/components/ServerTable/hooks/useTable";
import TableHeader from "common/components/ServerTable/components/TableHeader";
import Pagination from "common/components/ServerTable/components/Pagination";
import ActionButtons from "common/components/ServerTable/components/ActionButtons";
import Button from "common/components/Button";
import Input from "common/components/Input";
import {
  DEFAULT_TABLE_STATE,
  PER_PAGE_OPTIONS,
} from "common/components/ServerTable/constants/table";
import Spinner from "common/components/Spinner";
import { useTranslation } from "react-i18next";
import TableEditableInput from "./components/TableEditableInput";
import { useState } from "react";

interface TableHeaderProps {
  title: string;
  actionButton?: {
    label: string;
    onClick: () => void;
    disabled?: boolean;
  };
}

interface ExtendedTableProps<T extends Record<string, unknown>>
  extends TableProps<T> {
  headerConfig?: TableHeaderProps;
  pagination?: boolean;
  tableHeader?: boolean;
  isBody?: boolean;
  fixedColumnKey?: keyof T;
  showHeader?: boolean;
}

const Table = <T extends Record<string, unknown>>({
  columns,
  data,
  metadata,
  loading,
  onQueryChange,
  queryParams,
  emptyMessage = "Nenhum dado disponível",
  initialState,
  actionColumn,
  keyField = "id",
  headerConfig,
  pagination = true,
  tableHeader = true,
  isBody = true,
  fixedColumnKey,
  filterConfig,
  showHeader = true,
}: ExtendedTableProps<T>) => {
  const { t } = useTranslation();
  const {
    state,
    handleSort,
    handlePageChange,
    handlePerPageChange,
    handleSearch,
  } = useTable(
    { ...DEFAULT_TABLE_STATE, ...initialState },
    useCallback(
      (params) => {
        if (JSON.stringify(params) !== JSON.stringify(queryParams)) {
          onQueryChange?.(params);
        }
      },
      [onQueryChange, queryParams],
    ),
  );

  const [isFilterExpanded, setIsFilterExpanded] = useState(false);
  const [filterValues, setFilterValues] = useState<Record<string, any>>({});
  const [hasFilterError, setHasFilterError] = useState(false);

  const handleFilterChange = (key: string, values: any) => {
    setFilterValues((prev) => ({
      ...prev,
      [key]: values,
    }));
  };

  const handleFilterSubmit = () => {
    if (onQueryChange) {
      onQueryChange({
        ...queryParams,
        ...Object.values(filterValues).reduce(
          (acc, curr) => ({ ...acc, ...curr }),
          {},
        ),
        page: 1,
      });
    }
  };

  const renderCell = (
    column: ColumnDefinition<T>,
    value: T[keyof T],
    row: T,
  ) => {
    if (column.editable && column.editConfig) {
      return (
        <TableEditableInput
          initialValue={String(value)}
          type={column.editConfig.type}
          disabled={column.editConfig.disabled?.(row)}
          onValueChange={(newValue) => {
            column.editConfig?.onValueChange?.(newValue, row);
          }}
        />
      );
    }

    return column.render ? column.render(value, row) : String(value);
  };

  const renderContent = useMemo(() => {
    if (loading && (!data || data.length === 0)) {
      return (
        <tr>
          <td
            colSpan={columns.length + (actionColumn ? 1 : 0)}
            className="text-center py-4"
          >
            <div className="flex items-center justify-center">
              <div className="text-center">
                <Spinner size="xl" className="mx-auto mb-4" />
                <p className="text-text-secondary">Carregando...</p>
              </div>
            </div>
          </td>
        </tr>
      );
    }

    if (!loading && !data.length) {
      return (
        <tr>
          <td
            colSpan={columns.length + (actionColumn ? 1 : 0)}
            className="text-center py-8 text-[--text-secondary]"
          >
            {emptyMessage}
          </td>
        </tr>
      );
    }

    return data.map((row, index) => (
      <tr
        key={row[keyField] ? String(row[keyField]) : `row-${index}`}
        className="group hover:bg-[--table-header-bg] align-text-top"
      >
        {actionColumn?.position === "start" && (
          <td
            className="px-2 whitespace-nowrap w-0"
            style={{ width: actionColumn.width || "auto" }}
          >
            <ActionButtons actions={actionColumn.actions} row={row} />
          </td>
        )}
        {columns.map((column) => (
          <td
            key={String(column.key)}
            className={`px-2 py-1.5 text-sm text-[--text] ${column.className || ""} ${
              fixedColumnKey && column.key === fixedColumnKey
                ? "sticky left-0 z-10 bg-light-bg group-hover:bg-[--table-header-bg] align-middle"
                : ""
            } ${column.maxWidth ? "truncate" : "whitespace-nowrap"}`}
            style={{
              width: column.width || "auto",
              minWidth: column.minWidth || "auto",
              maxWidth: column.maxWidth || "none",
              overflow: column.maxWidth ? "hidden" : "visible",
              textOverflow: column.maxWidth ? "ellipsis" : "clip",
            }}
            title={column.maxWidth ? String(row[column.key]) : undefined}
          >
            {renderCell(column, row[column.key], row)}
          </td>
        ))}
        {actionColumn?.position === "end" && (
          <td
            className="px-2 whitespace-nowrap align-middle"
            style={{ width: actionColumn.width || "auto" }}
          >
            <ActionButtons actions={actionColumn.actions} row={row} />
          </td>
        )}
      </tr>
    ));
  }, [
    data,
    loading,
    columns,
    emptyMessage,
    actionColumn,
    keyField,
    fixedColumnKey,
  ]);

  return (
    <div className="space-y-2">
      {/* Custom Table Header */}
      {headerConfig && tableHeader && (
        <>
          <div className="sm:flex sm:items-center sm:justify-between">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-[--primary]">
                {headerConfig.title}
              </h1>
            </div>
            {headerConfig.actionButton && (
              <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                <Button
                  onClick={headerConfig.actionButton.onClick}
                  className="w-full sm:w-auto"
                  disabled={headerConfig.actionButton.disabled}
                >
                  {headerConfig.actionButton.label}
                </Button>
              </div>
            )}
          </div>

          <>
            <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
              <div className="flex-1">
                <div className="flex items-center w-full sm:w-72 relative">
                  <Search className="absolute left-3 h-4 w-4 text-[--text-secondary] z-10" />
                  <Input
                    type="text"
                    value={state.searchQuery || ""}
                    onChange={(e) => handleSearch(e.target.value)}
                    placeholder="Pesquisar"
                    variant="light"
                    className="pl-10 pr-10"
                  />
                  {filterConfig && (
                    <button
                      onClick={() => setIsFilterExpanded(!isFilterExpanded)}
                      className="absolute right-3 p-1 hover:bg-[--primary-hover-light] rounded-md"
                      title={
                        isFilterExpanded
                          ? "Recolher filtros"
                          : "Expandir filtros"
                      }
                    >
                      <SlidersHorizontal
                        className="h-5 w-5 text-primary"
                        strokeWidth={2.5}
                      />
                    </button>
                  )}
                </div>
              </div>

              <div className="flex items-center gap-2 shrink-0">
                <label
                  htmlFor="per-page"
                  className="text-sm text-[--text] whitespace-nowrap hidden sm:inline"
                >
                  {t("serverTable.pagination.perPage")}
                </label>
                <label
                  htmlFor="per-page"
                  className="text-sm text-[--text] sm:hidden"
                >
                  {t("serverTable.pagination.show")}
                </label>
                <select
                  id="per-page"
                  value={state.perPage}
                  onChange={(e) => handlePerPageChange(Number(e.target.value))}
                  className="border border-[--primary] rounded-md text-sm py-1.5 pl-2 pr-8 bg-white text-[--text]"
                >
                  {PER_PAGE_OPTIONS.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {isFilterExpanded && filterConfig && (
              <div className="mt-4 w-full bg-white space-y-4">
                {Object.entries(filterConfig.components).map(
                  ([key, config]) => (
                    <div key={key}>
                      <config.component
                        onChange={(values) => handleFilterChange(key, values)}
                        values={filterValues[key]}
                        onErrorChange={setHasFilterError}
                      />
                    </div>
                  ),
                )}
                <div className="flex justify-end">
                  <Button
                    onClick={handleFilterSubmit}
                    disabled={hasFilterError}
                  >
                    {t("serverTable.pagination.filterButton")}
                  </Button>
                </div>
              </div>
            )}
          </>
        </>
      )}

      {/* Table Content */}
      <div className="relative overflow-x-auto">
        <div className="inline-block min-w-full align-middle">
          <table
            className={`min-w-full divide-y divide-[--table-header-bg] divide-opacity-20 ${fixedColumnKey ? "table-fixed" : ""}`}
          >
            <TableHeader
              showHeader={showHeader}
              columns={columns}
              sortBy={state.sortBy}
              sortDirection={state.sortDirection}
              onSort={handleSort}
              actionColumn={actionColumn}
              fixedColumnKey={fixedColumnKey}
            />
            {isBody && (
              <tbody className="divide-y divide-[--table-header-bg] divide-opacity-20 bg-white">
                {renderContent}
              </tbody>
            )}
          </table>
        </div>
      </div>

      {pagination && (
        <Pagination
          currentPage={metadata?.currentPage || state.currentPage}
          totalPages={metadata?.totalPages || 1}
          totalRecords={metadata?.totalRecords || 0}
          onPageChange={handlePageChange}
          perPage={state.perPage}
          onPerPageChange={handlePerPageChange}
        />
      )}
    </div>
  );
};

export default Table;
