// src/features/invite/api/index.ts
import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "config/baseQuery";
import {
  InviteResponse,
  InviteQueryParams,
  CreateInviteRequest,
  InviteByUuidResponse,
  InviteByIdResponse,
} from "features/invite/types";

export const inviteApi = createApi({
  reducerPath: "inviteApi",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["Invite"],
  endpoints: (builder) => ({
    getInvites: builder.query<InviteResponse, InviteQueryParams | void>({
      query: (params) => ({
        url: "convite",
        method: "GET",
        params: params
          ? {
              filtro_generico: params.filtro_generico,
              page: params.page,
              perPage: params.perPage,
              sortDirection: params.sortDirection,
              sortBy: params.sortBy,
            }
          : undefined,
      }),
      providesTags: ["Invite"],
      transformResponse: (response: InviteResponse) => {
        if (!response.metadata) {
          response.metadata = {
            totalRecords: response.metadata,
            totalPages: 1,
            currentPage: 1,
          };
        }
        return response;
      },
    }),
    getInvite: builder.query<InviteResponse, string>({
      query: (uuid) => ({
        url: `convite/${uuid}`,
        method: "GET",
      }),
    }),
    acceptInvite: builder.mutation<void, string>({
      query: (uuid) => ({
        url: `convite/aceitar/${uuid}`,
        method: "POST",
      }),
    }),
    cancelInvite: builder.mutation<{ mensagem: string }, number>({
      query: (inviteId) => ({
        url: `convite/cancelar/${inviteId}`,
        method: "POST",
      }),
      invalidatesTags: ["Invite"],
    }),
    resendInvite: builder.mutation<{ mensagem: string }, number>({
      query: (inviteId) => ({
        url: `convite/enviarEmail/${inviteId}`,
        method: "POST",
      }),
      invalidatesTags: ["Invite"],
    }),
    createInvite: builder.mutation<void, CreateInviteRequest>({
      query: (body) => ({
        url: "convite",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Invite"],
    }),
    getInviteByUuid: builder.query<InviteByUuidResponse, string>({
      query: (uuid) => ({
        url: `convite/${uuid}`,
        method: "GET",
      }),
    }),
    // GetConviteByID  {{url_api}}/convite/id/1
    /* RESPONSE SAMPLE:
    {
    "mensagem": "Convite:",
    "data": {
        "uuid_convite": "93faba7a-ba0a-417d-92ab-b8801ebcfdc1",
        "email_convidado": "marlon.dirksen@berkan.com.br",
        "nome_convidado": "Marlon",
        "datahora_convite": "2025-01-17T13:20:20",
        "convite_aceito": true,
        "datahora_aceite": "2025-01-17T13:25:46",
        "usuario_convite": {
            "usuario_id": 1,
            "nome": "Admin SmartPDI"
        },
        "usuario_convite_empresa": [
            {
                "empresa_id": 7,
                "razao_social": "Empresa Distribuidora Ltda",
                "nome_fantasia": "Empresa Distribuidora",
                "perfil": {
                    "perfil_id": 2,
                    "nome_perfil": "Gestor PDI"
                }
            },
            {
                "empresa_id": 8,
                "razao_social": "Empresa Geradora/Transmissora Ltda",
                "nome_fantasia": "Empresa Geradora/Transmissora",
                "perfil": {
                    "perfil_id": 2,
                    "nome_perfil": "Gestor PDI"
                }
            }
        ]
    }
}
    */
    getInviteById: builder.query<InviteByIdResponse, number>({
      query: (id) => ({
        url: `convite/id/${id}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetInvitesQuery,
  useGetInviteQuery,
  useAcceptInviteMutation,
  useCancelInviteMutation,
  useResendInviteMutation,
  useCreateInviteMutation,
  useGetInviteByUuidQuery,
  useGetInviteByIdQuery,
} = inviteApi;
