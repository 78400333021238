import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IdentificationFormState {
  nome: string;
  tipo_projeto_id: string;
  titulo: string;
  titulo_resumido: string;
  codigo_ods: string;
  codigo_proposta: string;
  codigo_aneel: string;
  data_inicio_prevista: string | null;
  duracao: string;
  trl_inicial: string;
  trl_final: string;
  vl_projeto_total: number;
  vl_repasse_total: number;
  vl_contrapartida: number;
  vl_recurso_interno: number;
  captacao: string;
  tipo_produto_projeto_id: string;
  fase_inovacao_projeto_id: string;
  tipo_compartilhamento_projeto_id: string;
  codigo_tema: string;
  outro_tema: string;
  codigo_subtema: string;
  outro_subtema: string;
  projectTypeDisplay: string;
}

type IdentificationFieldValue = string | number | null;

export interface TagItem {
  tag_id: number;
  descricao: string;
}

export interface TagItemResponse {
  tag_item_id: number;
  tag: TagItem;
}

export interface DescriptionFormState {
  descricao: string;
  motivacao: string;
  originalidade: string;
  aplicabilidade: string;
  relevancia: string;
  razoabilidade_custos: string;
  pesquisa_correlata: string;
  palavras_chave: TagItem[];
}

type DescriptionFieldValue = string | TagItem[];

export interface EntitiesFormState {
  vl_projeto_total: number;
  vl_repasse_total: number;
  vl_contrapartida: number;
  vl_recurso_interno: number;
  proponentes_cooperadas: {
    projeto_entidade_id?: number;
    empresa_cooperada: {
      empresa_id: number;
      nome_fantasia: string;
      razao_social: string;
    };
    tipo_entidade: {
      tipo_entidade_id: number;
      descricao: string;
    };
    valor_repasse: number;
    recurso_interno: number;
  }[];
  executoras: {
    projeto_entidade_id?: number;
    empresa_executora: {
      empresa_id: number;
      nome_fantasia: string;
      razao_social: string;
    };
    tipo_entidade: {
      tipo_entidade_id: number;
      descricao: string;
    };
    valor_repasse: number;
    contrapartida: number;
  }[];
}

type EntitiesFieldValue = number;

export interface ProjectFormState {
  identification: IdentificationFormState;
  description: DescriptionFormState;
  entities: EntitiesFormState;
}

const initialIdentificationState: IdentificationFormState = {
  nome: "",
  tipo_projeto_id: "1",
  titulo: "",
  titulo_resumido: "",
  codigo_ods: "",
  codigo_proposta: "",
  codigo_aneel: "",
  data_inicio_prevista: null,
  duracao: "",
  trl_inicial: "",
  trl_final: "",
  vl_projeto_total: 0,
  vl_repasse_total: 0,
  vl_contrapartida: 0,
  vl_recurso_interno: 0,
  captacao: "",
  tipo_produto_projeto_id: "",
  fase_inovacao_projeto_id: "",
  tipo_compartilhamento_projeto_id: "",
  codigo_tema: "",
  outro_tema: "",
  codigo_subtema: "",
  outro_subtema: "",
  projectTypeDisplay: "1 - PDI - Pesquisa de Desenvolvimento e Inovação",
};

const initialDescriptionState: DescriptionFormState = {
  descricao: "",
  motivacao: "",
  originalidade: "",
  aplicabilidade: "",
  relevancia: "",
  razoabilidade_custos: "",
  pesquisa_correlata: "",
  palavras_chave: [],
};

const initialEntitiesState: EntitiesFormState = {
  vl_projeto_total: 0,
  vl_repasse_total: 0,
  vl_contrapartida: 0,
  vl_recurso_interno: 0,
  proponentes_cooperadas: [],
  executoras: [],
};

const initialState: ProjectFormState = {
  identification: initialIdentificationState,
  description: initialDescriptionState,
  entities: initialEntitiesState,
};

const projectFormSlice = createSlice({
  name: "projectForm",
  initialState,
  reducers: {
    updateIdentificationField: (
      state,
      action: PayloadAction<{
        field: keyof IdentificationFormState;
        value: IdentificationFieldValue;
      }>,
    ) => {
      const { field, value } = action.payload;
      (state.identification[field] as IdentificationFieldValue) = value;
    },

    updateDescriptionField: (
      state,
      action: PayloadAction<{
        field: keyof DescriptionFormState;
        value: DescriptionFieldValue;
      }>,
    ) => {
      const { field, value } = action.payload;
      (state.description[field] as DescriptionFieldValue) = value;
    },

    updateEntitiesField: (
      state,
      action: PayloadAction<{
        field: keyof EntitiesFormState;
        value: EntitiesFieldValue;
      }>,
    ) => {
      const { field, value } = action.payload;
      (state.entities[field] as EntitiesFieldValue) = value;
    },

    updateProponentsCooperadas: (
      state,
      action: PayloadAction<EntitiesFormState["proponentes_cooperadas"]>,
    ) => {
      state.entities.proponentes_cooperadas = action.payload;
    },

    updateExecutoras: (
      state,
      action: PayloadAction<EntitiesFormState["executoras"]>,
    ) => {
      state.entities.executoras = action.payload;
    },

    resetForm: (state) => {
      state.identification = initialIdentificationState;
      state.description = initialDescriptionState;
      state.entities = initialEntitiesState;
    },
  },
});

export const {
  updateIdentificationField,
  updateDescriptionField,
  updateEntitiesField,
  updateProponentsCooperadas,
  updateExecutoras,
  resetForm,
} = projectFormSlice.actions;

export default projectFormSlice.reducer;
