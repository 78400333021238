import { useTranslation } from "react-i18next";
import Input from "common/components/Input";
import DatePicker from "common/components/DatePicker";
import Select from "common/components/Select";
import { IdentificationFormState } from "features/project/slices/projectFormSlice";

interface ProjectCodesAndDatesProps {
  formData: IdentificationFormState;
  handleInputChange: (
    field: keyof IdentificationFormState,
    value: string | number | Date | null,
  ) => void;
  handleNumericInput: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  isViewMode: boolean;
  trlOptions: Array<{ value: string; label: string }>;
  isLoadingTrl: boolean;
}

const ProjectCodesAndDates = ({
  formData,
  handleInputChange,
  handleNumericInput,
  isViewMode,
  trlOptions,
  isLoadingTrl,
}: ProjectCodesAndDatesProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-3 p-2 rounded-md border border-[--table-header-bg] bg-[--breadcrumb-bg] shadow-sm">
      <h4 className="text-sm text-primary-dark font-semibold p-1.5 bg-[--primary-light] rounded-md shadow-sm text-center">
        {t("project.identificationStep.sections.codesAndDates")}
      </h4>

      <div className="flex flex-col gap-4 p-1">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3">
          <Input
            label={t("project.identificationStep.fields.odsCode")}
            value={formData.codigo_ods}
            onChange={(e) => handleInputChange("codigo_ods", e.target.value)}
            readOnly={isViewMode}
            disabled={isViewMode}
            className={`${isViewMode ? "bg-gray-100" : ""} border-[--table-header-bg]`}
          />
          <Input
            label={t("project.identificationStep.fields.proposalCode")}
            value={formData.codigo_proposta}
            onChange={(e) =>
              handleInputChange("codigo_proposta", e.target.value)
            }
            required
            readOnly={isViewMode}
            disabled={isViewMode}
            className={`${isViewMode ? "bg-gray-100" : ""} border-[--table-header-bg]`}
          />
          <Input
            label={t("project.identificationStep.fields.aneelCode")}
            value={formData.codigo_aneel}
            onChange={(e) => handleInputChange("codigo_aneel", e.target.value)}
            readOnly={isViewMode}
            disabled={isViewMode}
            className={`${isViewMode ? "bg-gray-100" : ""} border-[--table-header-bg]`}
          />
        </div>

        <div className="grid grid-cols-12 gap-3">
          <div className="col-span-12 sm:col-span-4">
            <DatePicker
              label={t("project.identificationStep.fields.startDate")}
              selectedDate={
                formData.data_inicio_prevista
                  ? new Date(formData.data_inicio_prevista)
                  : null
              }
              onChange={(date) =>
                handleInputChange("data_inicio_prevista", date)
              }
              required
              disabled={isViewMode}
            />
          </div>
          <div className="col-span-12 sm:col-span-2">
            <Input
              label={t("project.identificationStep.fields.duration")}
              value={formData.duracao}
              onChange={(e) => handleInputChange("duracao", e.target.value)}
              onKeyDown={handleNumericInput}
              required
              readOnly={isViewMode}
              disabled={isViewMode}
              className={`${isViewMode ? "bg-gray-100" : ""} border-[--table-header-bg]`}
            />
          </div>
          <div className="col-span-12 sm:col-span-3">
            <Select
              label={t("project.identificationStep.fields.initialTrl")}
              options={trlOptions}
              value={formData.trl_inicial}
              onChange={(value) => handleInputChange("trl_inicial", value)}
              placeholder={t("project.identificationStep.placeholders.trl")}
              isLoading={isLoadingTrl}
              required
              disabled={isViewMode}
            />
          </div>
          <div className="col-span-12 sm:col-span-3">
            <Select
              label={t("project.identificationStep.fields.finalTrl")}
              options={trlOptions}
              value={formData.trl_final}
              onChange={(value) => handleInputChange("trl_final", value)}
              placeholder={t("project.identificationStep.placeholders.trl")}
              isLoading={isLoadingTrl}
              required
              disabled={isViewMode}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectCodesAndDates;
