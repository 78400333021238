import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Modal from "common/components/Modal";
import Button from "common/components/Button";
import Select from "common/components/Select";
import Input from "common/components/Input";
import { RootState } from "store";
import { useGetCompanyUsersQuery } from "features/company/api";

interface UserOption {
  id: number;
  name: string;
}

interface AddUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAdd: (userId: number) => void;
  projectName: string;
  roleName: string;
  isSubmitting: boolean;
}

const AddUserModal = ({
  isOpen,
  onClose,
  onAdd,
  projectName,
  roleName,
  isSubmitting,
}: AddUserModalProps) => {
  const { t } = useTranslation();
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [users, setUsers] = useState<UserOption[]>([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);

  const companyId = useSelector(
    (state: RootState) => state.auth.user?.empresa_logada?.empresa_id,
  );

  console.log("companyId", companyId);

  const { data: companyUsers, isLoading } = useGetCompanyUsersQuery(
    { id: companyId || 0, params: { page: 1, perPage: 100 } },
    { skip: !companyId },
  );

  useEffect(() => {
    setIsLoadingUsers(isLoading);
    if (companyUsers?.data?.result) {
      const mappedUsers = companyUsers.data.result.map((user) => ({
        id: user.usuario_id,
        name: user.nome,
      }));
      setUsers(mappedUsers);
    }
  }, [companyUsers, isLoading]);

  const handleSubmit = () => {
    if (!selectedUserId) {
      setError("Selecione um usuário");
      return;
    }
    onAdd(selectedUserId);
  };

  const handleClose = () => {
    setSelectedUserId(null);
    setError(null);
    onClose();
  };

  const userOptions = users.map((user) => ({
    value: user.id.toString(),
    label: user.name,
  }));

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title={t("addUser.title")}
      size="md"
      closeOnBackdropClick={false}
      className="flex flex-col w-[1000px] max-w-[95vw] h-fit max-h-[90vh]"
      isLoading={isLoadingUsers}
      footer={
        <div className="flex justify-end gap-4">
          <Button
            variant="link"
            onClick={handleClose}
            disabled={isSubmitting || isLoadingUsers}
          >
            {t("button.cancel")}
          </Button>
          <Button
            onClick={handleSubmit}
            loading={isSubmitting}
            disabled={isLoadingUsers}
          >
            {t("button.add")}
          </Button>
        </div>
      }
    >
      <div className="p-4">
        <h2 className="text-lg font-semibold text-primary mb-4">
          {t("addUser.title")}
        </h2>

        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-2 rounded mb-4">
            {error}
          </div>
        )}

        <div className="mb-4">
          <Select
            label={t("user.label")}
            value={selectedUserId ? selectedUserId.toString() : ""}
            onChange={(value) =>
              setSelectedUserId(value ? parseInt(value) : null)
            }
            options={userOptions}
            placeholder={t("select.defaultPlaceholder")}
            disabled={isSubmitting || isLoadingUsers}
            isLoading={isLoadingUsers}
            required
            showNoneOption
          />
        </div>

        <div className="mb-4">
          <Input
            label={t("project.label")}
            value={projectName}
            readOnly
            variant="light"
          />
        </div>

        <div className="mb-4">
          <Input
            label={t("role.label")}
            value={roleName}
            readOnly
            variant="light"
          />
        </div>
      </div>
    </Modal>
  );
};

export default AddUserModal;
