import { createApi } from "@reduxjs/toolkit/query/react";
import { API_URL } from "config/apiConfig";
import { baseQueryWithAuth } from "config/baseQuery";

export const helpApi = createApi({
  reducerPath: "helpApi",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["Health"],
  endpoints: (builder) => ({
    getHealth: builder.query<string, void>({
      query: () => {
        const baseUrl = API_URL.replace("/api/v1/", "");
        return {
          url: `${baseUrl}/health`,
          method: "GET",
          responseHandler: "text",
        };
      },
      keepUnusedDataFor: 0,
      providesTags: ["Health"],
    }),
  }),
});

export const { useGetHealthQuery } = helpApi;
