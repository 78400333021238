// src/common/components/ServerTable/components/TableEditableInput/index.tsx

import { useState, KeyboardEvent, useRef, useLayoutEffect } from "react";

interface TableEditableInputProps {
  initialValue: string | number;
  onValueChange: (value: string | number) => void;
  type?: "text" | "number" | "currency" | "decimal" | string;
  disabled?: boolean;
}

const TableEditableInput = ({
  initialValue,
  onValueChange,
  type = "text",
  disabled = false,
}: TableEditableInputProps) => {
  const formatDisplayDecimal = (value: number): string => {
    return value.toFixed(2).replace(".", ",");
  };

  const formatCurrency = (value: number): string => {
    const isNegative = value < 0;
    const absoluteValue = Math.abs(value);
    const formatted = absoluteValue.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
    const cleanFormatted = formatted.replace(/R\$\s+/, " ");
    return isNegative ? cleanFormatted.replace(" ", " -") : cleanFormatted;
  };

  const [inputValue, setInputValue] = useState(() => {
    if (type === "currency") {
      return formatCurrency(Number(initialValue));
    }
    if (type === "decimal") {
      return formatDisplayDecimal(Number(initialValue));
    }
    return String(initialValue);
  });

  const [isEditing, setIsEditing] = useState(false);
  const displayRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [width, setWidth] = useState<number>(0);

  useLayoutEffect(() => {
    if (displayRef.current) {
      const displayWidth = displayRef.current.getBoundingClientRect().width;
      setWidth(Math.max(displayWidth, 60));
    }
  }, [inputValue]);

  const parseDisplayDecimal = (value: string): number => {
    const cleanValue = value.replace(/[^\d,.-]/g, "");
    const numberValue = cleanValue.replace(",", ".");
    return Number(parseFloat(numberValue).toFixed(2));
  };

  const parseCurrency = (value: string): number => {
    const isNegative = value.includes("-");
    const numberValue = value.replace(/[^\d,]/g, "").replace(",", ".");
    return isNegative ? -Number(numberValue) : Number(numberValue);
  };

  const formatDateMask = (value: string): string => {
    const digits = value.replace(/\D/g, "");
    let maskedValue = "";
    if (digits.length > 0) maskedValue += digits.substring(0, 2);
    if (digits.length > 2) maskedValue += "/" + digits.substring(2, 4);
    if (digits.length > 4) maskedValue += "/" + digits.substring(4, 8);
    return maskedValue;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    if (type === "decimal" || type === "currency") {
      const isNegative = value.includes("-");
      const numericValue = value.replace(/[^\d]/g, "");

      if (numericValue.length > 0) {
        const paddedValue = numericValue.padStart(3, "0");

        const integerPart = paddedValue.slice(0, -2);
        const decimalPart = paddedValue.slice(-2);

        const formattedInteger = Number(integerPart).toLocaleString("pt-BR", {
          maximumFractionDigits: 0,
          useGrouping: true,
        });

        value = `${formattedInteger},${decimalPart}`;

        if (type === "currency") {
          value = isNegative ? `-${value}` : `${value}`;
        } else if (isNegative) {
          value = `-${value}`;
        }
      } else {
        value = type === "currency" ? "0,00" : "0,00";
        if (isNegative) {
          value = type === "currency" ? "-0,00" : "-0,00";
        }
      }
    } else if (type === "number") {
      value = value.replace(/[^\d.-]/g, "");
    } else if (value.includes("/") || value.length <= 10) {
      value = formatDateMask(value);
    }

    setInputValue(value);
  };

  const validateDateFormat = (value: string): boolean => {
    const dateRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
    return dateRegex.test(value);
  };

  const handleValueUpdate = () => {
    let finalValue: string | number = inputValue;

    if (type === "decimal") {
      const numberValue = parseDisplayDecimal(inputValue);
      finalValue = numberValue;
      setInputValue(formatDisplayDecimal(numberValue));
    } else if (type === "number") {
      finalValue = Number(inputValue) || 0;
    } else if (type === "currency") {
      finalValue = parseCurrency(inputValue);
      setInputValue(formatCurrency(finalValue as number));
    } else if (inputValue.includes("/")) {
      if (!validateDateFormat(inputValue)) {
        finalValue = initialValue;
        setInputValue(String(initialValue));
      }
    }

    onValueChange(finalValue);
    setIsEditing(false);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" || e.key === "Tab") {
      e.preventDefault();

      // Store the current active element and its parent TR
      const currentInput = e.currentTarget;
      const currentRow = currentInput.closest("tr");

      // Find all editable inputs in the table
      const allEditableInputs = Array.from(
        document.querySelectorAll('tr [role="button"], tr input'),
      ).filter((el) => !el.hasAttribute("disabled"));

      // Find the current input's index
      const currentIndex = allEditableInputs.findIndex(
        (el) => el === currentInput || el === currentInput.parentElement,
      );

      // Calculate next index
      const nextIndex = currentIndex + 1;

      // Update the current value
      handleValueUpdate();

      // Use setTimeout to allow the table to re-render
      setTimeout(() => {
        // After re-render, find all editable inputs again
        const updatedEditableInputs = Array.from(
          document.querySelectorAll('tr [role="button"], tr input'),
        ).filter((el) => !el.hasAttribute("disabled"));

        // If we have a next element
        if (nextIndex < updatedEditableInputs.length) {
          const nextElement = updatedEditableInputs[nextIndex] as HTMLElement;

          // If the next element is in a different row, scroll it into view
          const nextRow = nextElement.closest("tr");
          if (nextRow && nextRow !== currentRow) {
            nextRow.scrollIntoView({ block: "nearest" });
          }

          nextElement.click();
          nextElement.focus();
        }
      }, 0);
    } else if (e.key === "Escape") {
      setIsEditing(false);
      if (type === "decimal") {
        setInputValue(formatDisplayDecimal(Number(initialValue)));
      } else if (type === "currency" && typeof initialValue === "number") {
        setInputValue(formatCurrency(initialValue));
      } else {
        setInputValue(String(initialValue));
      }
      e.currentTarget.blur();
    }
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsEditing(true);
    if (type === "decimal") {
      const numValue = parseDisplayDecimal(inputValue);
      setInputValue(String(numValue).replace(".", ","));
    }
    e.target.select();
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (document.hasFocus()) {
      handleValueUpdate();
    } else {
      e.preventDefault();
      e.target.focus();
    }
  };

  return (
    <div
      className="relative inline-block w-full align-middle font-extrabold text-right"
      style={{ minWidth: "60px" }}
    >
      <div
        ref={displayRef}
        className="invisible absolute whitespace-pre px-2"
        aria-hidden="true"
      >
        {inputValue}
      </div>

      {isEditing ? (
        <input
          maxLength={22}
          ref={inputRef}
          type="text"
          className={`w-full bg-white rounded px-2 focus:outline-none focus:ring-1 focus:ring-[--primary] focus:border-transparent ${
            (type === "number" || type === "decimal" || type === "currency") &&
            inputValue.includes("-")
              ? "text-[--error-negative]"
              : ""
          }`}
          value={inputValue}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          disabled={disabled}
          autoFocus
          style={{ width: `${width}px` }}
          tabIndex={0}
        />
      ) : (
        <div
          className={`w-full cursor-text hover:bg-[--primary-hover-light] rounded truncate ${
            (type === "number" || type === "decimal" || type === "currency") &&
            inputValue.includes("-")
              ? "text-error-negative"
              : ""
          }`}
          onClick={() => !disabled && setIsEditing(true)}
          tabIndex={disabled ? -1 : 0}
          role="button"
          style={{
            opacity: disabled ? 0.5 : 1,
            cursor: disabled ? "not-allowed" : "",
          }}
          onKeyDown={(e) => {
            if (!disabled) {
              if (e.key === "Enter" || e.key === " " || e.key === "Tab") {
                e.preventDefault();
                setIsEditing(true);
              }
            }
          }}
          onFocus={() => {
            if (!disabled) {
              setIsEditing(true);
            }
          }}
        >
          {inputValue}
        </div>
      )}
    </div>
  );
};

export default TableEditableInput;
