// src/features/auth/utils/auth.utils.ts
import { AUTH_STORAGE_KEYS } from "../types";

// Helper to find all company-related keys
const findCompanyKeys = (): string[] => {
  const keys: string[] = [];
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key?.startsWith(AUTH_STORAGE_KEYS.COMPANY_CODE_PREFIX)) {
      keys.push(key);
    }
  }
  return keys;
};

// Clear all application storage except rememberMe
export const clearAllAppStorage = (): void => {
  localStorage.removeItem(AUTH_STORAGE_KEYS.TOKEN);
  localStorage.removeItem(AUTH_STORAGE_KEYS.SELECTED_COMPANY);
  localStorage.removeItem(AUTH_STORAGE_KEYS.STORAGE_SYNC);

  // Clear all company-related keys
  findCompanyKeys().forEach((key) => {
    localStorage.removeItem(key);
  });
};

// Enhanced auth token management
export const setAuthToken = (token: string, rememberMe = false): void => {
  localStorage.setItem(AUTH_STORAGE_KEYS.TOKEN, token);

  if (rememberMe) {
    localStorage.setItem(AUTH_STORAGE_KEYS.REMEMBER_ME, "true");
  } else {
    localStorage.removeItem(AUTH_STORAGE_KEYS.REMEMBER_ME);
  }
};

export const getAuthToken = (): string | null => {
  return localStorage.getItem(AUTH_STORAGE_KEYS.TOKEN);
};

export const clearAuthToken = (): void => {
  localStorage.removeItem(AUTH_STORAGE_KEYS.TOKEN);
};

export const isRememberMeSet = (): boolean => {
  return localStorage.getItem(AUTH_STORAGE_KEYS.REMEMBER_ME) === "true";
};

// Setup auth listener
export const setupAuthListener = (): void => {
  window.addEventListener("beforeunload", () => {
    const rememberMe = isRememberMeSet();
    if (!rememberMe) {
      // If rememberMe is not set, clear everything including rememberMe itself
      clearAllAppStorage();
      localStorage.removeItem(AUTH_STORAGE_KEYS.REMEMBER_ME);
    } else {
      // If rememberMe is set, only clear other data
      clearAllAppStorage();
    }
  });
};
