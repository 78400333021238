// src/router/utils/routeUtils.ts
import { lazy } from "react";
import { RouteType } from "router/types";
import { MenuItem } from "features/user/types";

const getComponentPath = (menuPath: string): string => {
  const cleanPath = menuPath.replace(/^\//, "");

  const pathMap: Record<string, string> = {
    "cadastros/empresas/manutencao": "company/components/CompanyMaintenance",

    "cadastros/usuarios/manutencao": "user/components/UserMaintenance",

    "cadastros/usuarios/convites": "invite/components/UserInvite",

    "cadastros/usuarios/perfis": "profile/components/UserProfile",

    "gestao-obrigacoes/rol/manutencao": "bond/components/NORMaintenance",

    "gestao-obrigacoes/contas-pagar/manutencao":
      "accountsPayable/components/AccountsPayableMaintenance",

    "gestao-obrigacoes/conta/lancamentos-manuais":
      "account/components/ManualEntries",

    "gestao-obrigacoes/conta/extrato": "account/components/AccountStatement",

    "gestao-obrigacoes/selic/acompanhamento":
      "selic/components/SelicMonitoring",

    "gestao-obrigacoes/acompanhamento/obrigacoes-pdi":
      "bond/components/NORMonitoring",

    "gestao-obrigacoes/consolidado/obrigacoes-pdi-resumo":
      "bond/components/PDIObligationsSummary",

    "dashboards/acompanhamento": "dashboard/components/CompanyDashboard",

    "gestao-obrigacoes/previsionamento/visualizar-editar":
      "forecasting/components/NORForecast",

    "central-ajuda/suporte": "help/components/HelpSupport",

    "central-ajuda/politica-privacidade": "help/components/PrivacyPolicy",

    "central-ajuda/termos-de-uso": "help/components/TermsOfUse",

    "central-ajuda/manuais-aneel": "help/components/AneelManuals",

    "central-ajuda/auditoria-berkan": "help/components/BerkanAudit",

    "gestao-obrigacoes/transferencia-entre-contas":
      "account/components/TransferBetweenAccounts",

    "cuca/squad": "cuca/components/CucaSquad",

    "administrativo/inativar-empresa": "router/components/NotFound",

    "meu-perfil": "project/components/OnGoingPDIProjects",

    "projetos/em-andamento": "project/components/PDIProject",
  };

  return pathMap[cleanPath] || cleanPath;
};

export const mapMenuToRoutes = (menuItems: MenuItem[] = []): RouteType[] => {
  return menuItems
    .filter((item): item is MenuItem & { caminho_menu: string } =>
      Boolean(item.caminho_menu),
    )
    .map((item) => {
      const componentPath = getComponentPath(item.caminho_menu);

      if (componentPath === "router/components/NotFound") {
        return {
          key: `menu.${item.menu_id}`,
          path: item.caminho_menu,
          component: lazy(() => import("router/components/NotFound")),
          authority: "authenticated",
          meta: {
            layout: "default",
          },
        };
      } else {
        return {
          key: `menu.${item.menu_id}`,
          path: item.caminho_menu,
          component: lazy(() =>
            import(`features/${componentPath}`).catch(() =>
              import(
                "common/components/Modal/common/components/ConfirmModal"
              ).then((module) => ({
                default: () =>
                  module.default({
                    isOpen: true,
                    onClose: () => window.location.replace("/"),
                    onConfirm: () => window.location.replace("/"),
                    title: "Atualização necessária",
                    message:
                      "Uma nova versão do sistema está disponível. Para continuar utilizando a plataforma, é necessário realizar a atualização.",
                    confirmButtonText: "Atualizar agora",
                    size: "sm",
                  }),
              })),
            ),
          ),
          authority: "authenticated",
          meta: {
            layout: "default",
          },
        };
      }
    });
};
