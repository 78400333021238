// src/features/company/components/LogoUpload/index.tsx
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Paperclip } from "lucide-react";
import { CrudComponentProps } from "features/crud/types";
import ErrorAlert from "common/components/ErrorAlert";

const MAX_FILE_SIZE = 5 * 1024 * 1024;
const ACCEPTED_TYPES = ["image/jpeg", "image/png", "image/gif"];

const convertToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};

const validateFile = (file: File): string | null => {
  if (!ACCEPTED_TYPES.includes(file.type)) {
    return "Formato de arquivo inválido. Por favor, use JPG, PNG ou GIF.";
  }
  if (file.size > MAX_FILE_SIZE) {
    return "Arquivo muito grande. O tamanho máximo é 5MB.";
  }
  return null;
};

const LogoUpload = ({
  value,
  onChange,
  error: propError,
  disabled,
  mode,
}: CrudComponentProps<string>) => {
  const { t } = useTranslation();
  const [isDragging, setIsDragging] = useState(false);
  const [fileError, setFileError] = useState<string | null>(null);
  const [preview, setPreview] = useState<string | null>(null);

  const handleFile = async (file: File) => {
    const validationError = validateFile(file);
    if (validationError) {
      setFileError(validationError);
      return;
    }

    try {
      setFileError(null);
      const base64String = await convertToBase64(file);
      setPreview(base64String);
      onChange(base64String);
    } catch (error) {
      setFileError("Erro ao processar o arquivo. Tente novamente.");
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      await handleFile(file);
    }
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = async (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files?.[0];
    if (file) {
      await handleFile(file);
    }
  };

  const handleDismissError = () => {
    setFileError(null);
  };

  const error = fileError || propError;

  return (
    <div className="w-full">
      <label
        htmlFor="logo-upload"
        className="block text-sm font-medium text-primary mb-1"
      >
        {t("Logo da empresa")}
      </label>

      <div
        className={`
          relative rounded-lg border-2 border-dashed
          ${isDragging ? "border-primary bg-primary-light" : "border-primary"}
          hover:border-primary-hover transition-colors
          p-4 flex items-center gap-3 cursor-pointer
        `}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        {preview ? (
          <div className="h-10 w-10 flex items-center justify-center bg-light-bg rounded overflow-hidden">
            <img
              src={preview}
              alt="Logo preview"
              className="h-full w-full object-cover"
            />
          </div>
        ) : (
          <div className="h-10 w-10 flex items-center justify-center bg-light-bg rounded">
            <Paperclip className="w-5 h-5 text-text-secondary" />
          </div>
        )}

        <div className="flex-1">
          <p className="text-sm text-text-secondary">
            {value ? "Arquivo selecionado" : t("Selecione um arquivo")}
          </p>
          <p className="text-xs text-text-secondary mt-1">
            JPG, PNG ou GIF (max. 5MB)
          </p>
        </div>

        <input
          type="file"
          id="logo-upload"
          accept={ACCEPTED_TYPES.join(",")}
          onChange={handleFileChange}
          className={`absolute inset-0 w-full h-full opacity-0 ${disabled || mode === "view" ? "cursor-not-allowed" : "cursor-pointer"}`}
          aria-label={t("Upload Logo")}
          disabled={disabled || mode === "view"}
        />
      </div>
      {error && (
        <ErrorAlert
          message={error}
          onDismiss={fileError ? handleDismissError : undefined}
          className="mt-2"
        />
      )}
    </div>
  );
};

export default LogoUpload;
