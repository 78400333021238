import { CrudConfig, CrudMode, BaseEntity } from "features/crud/types";
import { ExecutingCompany } from "features/project/types";
import CNPJInput from "common/components/Input/common/components/CNPJ";
import UnifiedLocationField, {
  LocationFormData,
} from "features/location/components/UnifiedLocationForm";
import SelectStatus from "common/components/Select/common/components/Status";
import { registerEntityTransformer } from "features/crud/utils/transformer";

// Extended type for form handling
interface ExecutingCompanyForm extends BaseEntity {
  razao_social: string;
  nome_fantasia: string;
  cnpj: string;
  cep: string;
  endereco: string;
  numero: string;
  bairro: string;
  municipio_ibge_id: number;
  telefone: string;
  locationData?: LocationFormData;
  ativa: boolean;
}

registerEntityTransformer("executora", {
  request: (data: Partial<ExecutingCompanyForm>) => {
    const locationData = data.locationData as LocationFormData;

    const stripNonNumeric = (str: string | undefined) =>
      str?.replace(/\D/g, "") || "";

    const apiPayload = {
      razao_social: data.razao_social,
      nome_fantasia: data.nome_fantasia,
      cnpj: stripNonNumeric(data.cnpj),
      endereco: locationData?.endereco || data.endereco,
      numero: locationData?.numero || data.numero,
      bairro: locationData?.bairro || data.bairro,
      municipio_ibge_id: locationData?.municipio_ibge_id || 0,
      cep: stripNonNumeric(locationData?.cep || data.cep),
      telefone:
        stripNonNumeric(locationData?.telefone) ||
        stripNonNumeric(data.telefone),
      ativa: data.ativa,
    };

    return apiPayload;
  },
  response: (data: Record<string, unknown>) => {
    const executingCompany = data as unknown as ExecutingCompany;

    const locationData: LocationFormData = {
      cep: executingCompany.cep,
      endereco: executingCompany.endereco,
      numero: executingCompany.numero,
      bairro: executingCompany.bairro,
      municipio_ibge_id: executingCompany.municipio_ibge_id,
      uf_id: 0,
      telefone: executingCompany.telefone,
    };

    return {
      id: executingCompany.executora_id,
      razao_social: executingCompany.razao_social,
      nome_fantasia: executingCompany.nome_fantasia,
      cnpj: executingCompany.cnpj,
      locationData,
      cep: executingCompany.cep,
      endereco: executingCompany.endereco,
      numero: executingCompany.numero,
      bairro: executingCompany.bairro,
      municipio_ibge_id: executingCompany.municipio_ibge_id,
      telefone: executingCompany.telefone,
      ativa: executingCompany.ativa,
    } as ExecutingCompanyForm;
  },
});

export const getExecutingCompanyCrudConfig = (
  mode: CrudMode,
): CrudConfig<ExecutingCompanyForm> => ({
  entityName: "executora",
  endpoints: {
    list: "executora",
    getById: "executora",
    create: "executora",
    update: "executora",
    delete: "executora",
  },
  fields: [
    {
      name: "cnpj",
      label: "CNPJ",
      type: mode === "view" ? "text" : "component",
      required: true,
      component: mode === "view" ? undefined : CNPJInput,
    },
    {
      name: "ativa",
      label: "Status",
      type: "component",
      component: SelectStatus,
      required: true,
    },
    {
      name: "razao_social",
      label: "Razão Social",
      type: "text",
      required: true,
    },
    {
      name: "nome_fantasia",
      label: "Nome Fantasia",
      type: "text",
      required: true,
    },
    {
      name: "locationData",
      label: "",
      type: "component",
      component: UnifiedLocationField,
      required: true,
      componentProps: {
        mode,
      },
    },
  ],
  layout: {
    groups: [
      {
        fields: ["cnpj", "ativa"],
        columns: { sm: 1, md: 2 },
      },
      {
        fields: ["razao_social", "nome_fantasia"],
        columns: { sm: 1, md: 2 },
      },
      {
        fields: ["locationData"],
        columns: { sm: 1 },
      },
    ],
  },
});
