// src/features/project/components/ProponentsPartners/index.tsx
import { useTranslation } from "react-i18next";
import { useState, useMemo, useEffect, useRef, useCallback } from "react";
import Button from "common/components/Button";
import Select from "common/components/Select";
import Modal from "common/components/Modal";
import Input from "common/components/Input";
import CompanyForm from "features/company/components/CompanyForm";
import { EntitiesFormState } from "features/project/slices/projectFormSlice";
import { Company } from "features/company/types";
import { CrudMode } from "features/crud/types";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { formatCurrencyPtBr } from "common/utils/formatters";
import { Trash2 } from "lucide-react";
import Table from "common/components/ServerTable";
import {
  ColumnDefinition,
  TableAction,
} from "common/components/ServerTable/types";

interface ProponentsPartnersProps {
  formData: EntitiesFormState;
  isViewMode: boolean;
  entityTypeOptions: Array<{ value: string; label: string }>;
  isLoadingEntityType: boolean;
  companyByBusinessGroupOptions: Array<{ value: string; label: string }>;
  isLoadingCompanyByBusinessGroup: boolean;
  refetchCompanyByBusinessGroup: () => void;
  onUpdateProponents: (
    proponents: EntitiesFormState["proponentes_cooperadas"],
  ) => void;
}

interface ProponentPartner extends Record<string, unknown> {
  projeto_entidade_id?: number;
  empresa_id: number;
  empresa_nome: string;
  tipo_entidade_id: number;
  tipo_entidade_descricao: string;
  valor_repasse: number;
  recurso_interno: number;
  participacao: number;
}

const ProponentsPartners = ({
  formData,
  entityTypeOptions,
  isLoadingEntityType,
  companyByBusinessGroupOptions,
  isLoadingCompanyByBusinessGroup,
  isViewMode,
  refetchCompanyByBusinessGroup,
  onUpdateProponents,
}: ProponentsPartnersProps) => {
  const { t } = useTranslation();
  const [isCompanyModalOpen, setIsCompanyModalOpen] = useState(false);
  const [newCompanyName, setNewCompanyName] = useState("");
  const [selectedCompanyId, setSelectedCompanyId] = useState("");
  const [selectedEntityTypeId, setSelectedEntityTypeId] = useState("");
  const [transferValue, setTransferValue] = useState(0);
  const [internalValue, setInternalValue] = useState(0);
  const [total, setTotal] = useState(0);
  const [proponentsPartners, setProponentsPartners] = useState<
    ProponentPartner[]
  >([]);
  const [error, setError] = useState<string | null>(null);
  const companyFormRef = useRef<{ submitForm: () => void } | null>(null);

  const businessGroupId = useSelector(
    (state: RootState) =>
      state.auth.user?.empresa_logada.grupo_empresarial.grupo_empresarial_id,
  );

  const businessGroupName = useSelector(
    (state: RootState) =>
      state.auth.user?.empresa_logada.grupo_empresarial.nome_grupo_empresarial,
  );

  const calculateParticipation = useCallback(
    (repasse: number, recursoInterno: number) => {
      const total = formData.vl_repasse_total + formData.vl_recurso_interno;
      if (total === 0) return 0;

      const entityTotal = repasse + recursoInterno;
      return (entityTotal / total) * 100;
    },
    [formData.vl_repasse_total, formData.vl_recurso_interno],
  );

  useEffect(() => {
    if (formData?.proponentes_cooperadas?.length > 0) {
      const formattedData = formData.proponentes_cooperadas.map((item) => ({
        projeto_entidade_id: item.projeto_entidade_id,
        empresa_id: item.empresa_cooperada.empresa_id,
        empresa_nome: item.empresa_cooperada.nome_fantasia,
        tipo_entidade_id: item.tipo_entidade.tipo_entidade_id,
        tipo_entidade_descricao: item.tipo_entidade.descricao,
        valor_repasse: item.valor_repasse,
        recurso_interno: item.recurso_interno,
        participacao: calculateParticipation(
          item.valor_repasse,
          item.recurso_interno,
        ),
      }));
      setProponentsPartners(formattedData);
    }
  }, [formData, calculateParticipation]);

  const handleCreateOption = (inputValue: string) => {
    setNewCompanyName(inputValue);
    setIsCompanyModalOpen(true);
  };

  const handleCompanyModalClose = () => {
    setIsCompanyModalOpen(false);
    setNewCompanyName("");
  };

  const handleCompanySuccess = (_mode: CrudMode, data: Company) => {
    setIsCompanyModalOpen(false);
    refetchCompanyByBusinessGroup();
    if (data && data.id) {
      setSelectedCompanyId(String(data.id));
    }
  };

  const handleSaveCompanyButtonClick = () => {
    if (companyFormRef.current) {
      companyFormRef.current.submitForm();
    } else {
      console.warn("Company form reference not available for submission");
    }
  };

  const handleTransferValueChange = (value: string) => {
    const numericValue = parseFloat(value.replace(/\D/g, "")) / 100;
    setTransferValue(numericValue);
    setTotal(numericValue + internalValue);
  };

  const handleInternalValueChange = (value: string) => {
    const numericValue = parseFloat(value.replace(/\D/g, "")) / 100;
    setInternalValue(numericValue);
    setTotal(transferValue + numericValue);
  };

  const validateAdd = () => {
    if (!selectedCompanyId || !selectedEntityTypeId || transferValue <= 0) {
      setError("Preencha todos os campos obrigatórios");
      return false;
    }

    const companyExists = proponentsPartners.some(
      (item) => item.empresa_id === parseInt(selectedCompanyId),
    );

    if (companyExists) {
      setError("Esta empresa já foi adicionada");
      return false;
    }

    const currentTotalTransfer = proponentsPartners.reduce(
      (sum, item) => sum + item.valor_repasse,
      0,
    );

    if (currentTotalTransfer + transferValue > formData.vl_repasse_total) {
      setError(
        `O valor de repasse não pode exceder o valor total de repasse do projeto (${formatCurrencyPtBr(formData.vl_repasse_total)})`,
      );
      return false;
    }

    const currentTotalInternal = proponentsPartners.reduce(
      (sum, item) => sum + item.recurso_interno,
      0,
    );

    if (currentTotalInternal + internalValue > formData.vl_recurso_interno) {
      setError(
        `O valor de recurso interno não pode exceder o valor total de recurso interno do projeto (${formatCurrencyPtBr(formData.vl_recurso_interno)})`,
      );
      return false;
    }

    setError(null);
    return true;
  };

  const addEntity = () => {
    if (!validateAdd()) return;

    const selectedCompany = companyByBusinessGroupOptions.find(
      (option) => option.value === selectedCompanyId,
    );

    const selectedEntityType = entityTypeOptions.find(
      (option) => option.value === selectedEntityTypeId,
    );

    const participation = calculateParticipation(transferValue, internalValue);

    const newEntity: ProponentPartner = {
      empresa_id: parseInt(selectedCompanyId),
      empresa_nome: selectedCompany?.label || "",
      tipo_entidade_id: parseInt(selectedEntityTypeId),
      tipo_entidade_descricao: selectedEntityType?.label || "",
      valor_repasse: transferValue,
      recurso_interno: internalValue,
      participacao: participation,
    };

    const updatedProponents = [...proponentsPartners, newEntity];
    setProponentsPartners(updatedProponents);

    const formattedProponents = updatedProponents.map((item) => ({
      projeto_entidade_id: item.projeto_entidade_id,
      empresa_cooperada: {
        empresa_id: item.empresa_id,
        nome_fantasia: item.empresa_nome,
        razao_social: "",
      },
      tipo_entidade: {
        tipo_entidade_id: item.tipo_entidade_id,
        descricao: item.tipo_entidade_descricao,
      },
      valor_repasse: item.valor_repasse,
      recurso_interno: item.recurso_interno,
    }));

    onUpdateProponents(formattedProponents);

    setSelectedCompanyId("");
    setSelectedEntityTypeId("");
    setTransferValue(0);
    setInternalValue(0);
    setTotal(0);
  };

  const handleRemoveEntity = (entity: ProponentPartner) => {
    const updatedProponents = proponentsPartners.filter(
      (item) => item.empresa_id !== entity.empresa_id,
    );
    setProponentsPartners(updatedProponents);

    const formattedProponents = updatedProponents.map((item) => ({
      projeto_entidade_id: item.projeto_entidade_id,
      empresa_cooperada: {
        empresa_id: item.empresa_id,
        nome_fantasia: item.empresa_nome,
        razao_social: "",
      },
      tipo_entidade: {
        tipo_entidade_id: item.tipo_entidade_id,
        descricao: item.tipo_entidade_descricao,
      },
      valor_repasse: item.valor_repasse,
      recurso_interno: item.recurso_interno,
    }));

    onUpdateProponents(formattedProponents);
  };

  const tableColumns: ColumnDefinition<ProponentPartner>[] = useMemo(
    () => [
      {
        key: "empresa_nome",
        title: t("project.entitiesStep.table.headers.company"),
        width: "30%",
      },
      {
        key: "tipo_entidade_descricao",
        title: t("project.entitiesStep.table.headers.type"),
        width: "15%",
      },
      {
        key: "valor_repasse",
        title: t("project.entitiesStep.table.headers.transfer"),
        width: "15%",
        render: (value) => (
          <span className="flex justify-end">
            {formatCurrencyPtBr(value as number)}
          </span>
        ),
      },
      {
        key: "recurso_interno",
        title: t("project.entitiesStep.table.headers.internalResource"),
        width: "15%",
        render: (value) => (
          <span className="flex justify-end">
            {formatCurrencyPtBr(value as number)}
          </span>
        ),
      },
      {
        key: "participacao",
        title: t("project.entitiesStep.table.headers.participation"),
        width: "15%",
        render: (value) => (
          <span className="flex justify-center">
            {`${(value as number).toFixed(2)}%`}
          </span>
        ),
      },
    ],
    [t],
  );

  const tableActions: TableAction<ProponentPartner>[] = useMemo(
    () => [
      {
        label: t("project.entitiesStep.actions.remove"),
        icon: Trash2,
        onClick: (row) => {
          handleRemoveEntity(row);
        },
        variant: "destructive",
        disabled: () => isViewMode,
        show: () => !isViewMode,
      },
    ],
    [t, isViewMode, handleRemoveEntity],
  );

  const renderProponentCard = (entity: ProponentPartner) => (
    <div
      key={entity.empresa_id}
      className="p-3 mb-2 bg-white rounded-md border border-[--table-header-bg] shadow-sm"
    >
      <div className="grid grid-cols-12 gap-3">
        <div className="col-span-4">
          <p className="text-sm text-text-secondary font-medium mb-1">
            {t("project.entitiesStep.table.headers.company")}
          </p>
          <p className="font-semibold text-primary">{entity.empresa_nome}</p>
        </div>
        <div className="col-span-2">
          <p className="text-sm text-text-secondary font-medium mb-1">
            {t("project.entitiesStep.table.headers.type")}
          </p>
          <p className="font-semibold text-primary">
            {entity.tipo_entidade_descricao}
          </p>
        </div>
        <div className="col-span-2">
          <p className="text-sm text-text-secondary font-medium mb-1">
            {t("project.entitiesStep.table.headers.transfer")}
          </p>
          <p className="font-semibold text-primary text-right">
            {formatCurrencyPtBr(entity.valor_repasse)}
          </p>
        </div>
        <div className="col-span-2">
          <p className="text-sm text-text-secondary font-medium mb-1">
            {t("project.entitiesStep.table.headers.internalResource")}
          </p>
          <p className="font-semibold text-primary text-right">
            {formatCurrencyPtBr(entity.recurso_interno)}
          </p>
        </div>
        <div className="col-span-2">
          <p className="text-sm text-text-secondary font-medium mb-1">
            {t("project.entitiesStep.table.headers.participation")}
          </p>
          <p className="font-semibold text-primary text-center">{`${entity.participacao.toFixed(2)}%`}</p>
        </div>
      </div>
    </div>
  );

  return (
    <div className="flex flex-col gap-3 p-2 rounded-md border border-[--table-header-bg] bg-[--breadcrumb-bg] shadow-sm">
      <h4 className="text-sm text-primary-dark font-semibold p-1.5 bg-[--primary-light] rounded-md shadow-sm text-center">
        {t("project.entitiesStep.sections.proponentsPartners")}
      </h4>

      {!isViewMode && (
        <>
          <div className="grid grid-cols-12 gap-2 items-end">
            <div className="col-span-3">
              <Select
                label={t("project.entitiesStep.fields.companyByBusinessGroup")}
                options={companyByBusinessGroupOptions}
                value={selectedCompanyId}
                onChange={(value) => setSelectedCompanyId(value)}
                placeholder={t(
                  "project.entitiesStep.placeholders.companyByBusinessGroup",
                )}
                isLoading={isLoadingCompanyByBusinessGroup}
                required
                disabled={isViewMode}
                searchable
                allowCreate
                onCreateOption={handleCreateOption}
              />
            </div>

            <div className="col-span-3">
              <Select
                label={t("project.entitiesStep.fields.entityType")}
                options={entityTypeOptions}
                value={selectedEntityTypeId}
                onChange={(value) => setSelectedEntityTypeId(value)}
                placeholder={t("project.entitiesStep.placeholders.entityType")}
                isLoading={isLoadingEntityType}
                required
                disabled={isViewMode}
              />
            </div>

            <div className="col-span-2">
              <Input
                label={t("project.entitiesStep.fields.transfer")}
                value={transferValue ? formatCurrencyPtBr(transferValue) : ""}
                onChange={(e) => handleTransferValueChange(e.target.value)}
                maskType="currency"
                required
                disabled={isViewMode}
                className="border-[--table-header-bg] w-full"
                placeholder="0,00"
              />
            </div>

            <div className="col-span-2">
              <Input
                label={t("project.entitiesStep.fields.internalValue")}
                value={internalValue ? formatCurrencyPtBr(internalValue) : ""}
                onChange={(e) => handleInternalValueChange(e.target.value)}
                maskType="currency"
                required
                disabled={isViewMode}
                className="border-[--table-header-bg] w-full"
                placeholder="0,00"
              />
            </div>

            <div className="col-span-2">
              <Input
                label={t("project.entitiesStep.fields.total")}
                value={total ? formatCurrencyPtBr(total) : ""}
                readOnly
                disabled
                className="bg-gray-100 border-[--table-header-bg] w-full"
                placeholder="0,00"
              />
            </div>
          </div>

          {error && <div className="text-red-500 text-sm mt-1">{error}</div>}

          <div className="flex justify-end mt-2">
            <Button
              variant="primary"
              onClick={addEntity}
              disabled={
                isViewMode ||
                !selectedCompanyId ||
                !selectedEntityTypeId ||
                transferValue <= 0 ||
                internalValue <= 0
              }
            >
              {t("project.entitiesStep.buttons.add")}
            </Button>
          </div>
        </>
      )}

      <div className="mt-4">
        {isViewMode ? (
          <div className="flex flex-col">
            {proponentsPartners.length > 0 ? (
              proponentsPartners.map((entity) => renderProponentCard(entity))
            ) : (
              <div className="p-4 text-center text-gray-500">
                {t("project.entitiesStep.table.emptyMessage")}
              </div>
            )}
          </div>
        ) : (
          <Table
            columns={tableColumns}
            data={proponentsPartners}
            keyField="empresa_id"
            emptyMessage={t("project.entitiesStep.table.emptyMessage")}
            actionColumn={{
              position: "end",
              actions: tableActions,
            }}
            pagination={false}
          />
        )}
      </div>

      <Modal
        isOpen={isCompanyModalOpen}
        onClose={handleCompanyModalClose}
        title={t("project.entitiesStep.modal.title")}
        size="xl"
        closeOnBackdropClick={false}
        className="flex flex-col w-[1000px] max-w-[95vw] h-fit max-h-[90vh]"
        footer={
          <div className="flex justify-end gap-2">
            <Button variant="link" onClick={handleCompanyModalClose}>
              {t("button.cancel")}
            </Button>
            <Button variant="primary" onClick={handleSaveCompanyButtonClick}>
              {t("button.save")}
            </Button>
          </div>
        }
      >
        <CompanyForm
          mode="create"
          onSuccess={handleCompanySuccess}
          onCancel={handleCompanyModalClose}
          disabledFields={["grupo_empresarial", "tipo_empresa"]}
          initialData={{
            tipo_empresa: {
              tipo_empresa_id: 4,
              nome: "Cooperada",
            },
            grupo_empresarial: {
              grupo_empresarial_id: businessGroupId || 0,
              nome_grupo_empresarial: businessGroupName || "",
              link_dashboard_powerbi: null,
            },
            nome_fantasia: newCompanyName,
          }}
          showBackButton={false}
          hideButtons={true}
          formRef={companyFormRef}
        />
      </Modal>
    </div>
  );
};

export default ProponentsPartners;
