import { ReactNode } from "react";

interface StepContainerProps {
  children: ReactNode;
}

const StepContainer = ({ children }: StepContainerProps) => (
  <div className="flex flex-col h-full w-full">{children}</div>
);

export default StepContainer;
