export interface ApiError {
  status: number;
  message: string;
  details: Record<string, any>;
}
export const handleError = (error: unknown): ApiError => {
  let apiError: ApiError;

  if (error instanceof Error) {
    apiError = {
      status: 500,
      message: error.message,
      details: {},
    };
  } else {
    const errorData = (error as any)?.data;
    apiError = {
      status: errorData?.status || (error as any)?.status || 500,
      message: errorData?.message || "Ocorreu um erro inesperado",
      details: errorData?.details || {},
    };

    switch (apiError.status) {
      case 400:
        apiError.message =
          "Dados do formulário inválidos. Por favor, verifique seus dados.";
        break;
      case 401:
        apiError.message = "Sessão expirada. Por favor, faça login novamente.";
        break;
      case 403:
        apiError.message = "Você não tem permissão para realizar esta ação.";
        break;
      case 404:
        apiError.message = "O recurso solicitado não foi encontrado.";
        break;
      case 409:
        apiError.message = "Esta ação conflita com dados existentes.";
        break;
      case 422:
        apiError.message = "Os dados fornecidos são inválidos.";
        break;
      case 500:
      default:
        apiError.message =
          "Ocorreu um erro inesperado. Por favor, tente novamente.";
    }
  }

  return apiError;
};
