// src/common/components/Select/common/components/Uf/index.tsx
import { useState, useEffect } from "react";
import Select from "common/components/Select";
import { useGetUfQuery } from "features/location/api";
import { Uf } from "features/location/api/types";
import { useTranslation } from "react-i18next";

interface SelectUFProps {
  onChange?: (uf: Uf | null) => void;
  value?: string;
  className?: string;
  disabled?: boolean;
  required?: boolean;
}

const SelectUF = ({
  onChange,
  value: externalValue,
  className,
  disabled,
  required,
}: SelectUFProps) => {
  const { t } = useTranslation();
  const { data, isLoading, error } = useGetUfQuery();
  const [internalValue, setInternalValue] = useState<string>("");

  // Sync external value with internal state
  useEffect(() => {
    if (externalValue) {
      setInternalValue(externalValue);
    }
  }, [externalValue]);

  const value = externalValue ?? internalValue;

  const options = data?.data
    ? data.data
        .map((uf) => ({
          value: uf.uf_id.toString(),
          label: uf.sigla_uf,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
    : [];

  const handleChange = (selectedValue: string) => {
    const selectedUf =
      data?.data.find((uf) => uf.uf_id.toString() === selectedValue) ?? null;

    if (!externalValue) {
      setInternalValue(selectedValue);
    }

    onChange?.(selectedUf);
  };

  return (
    <Select
      label={t("uf.label")}
      options={options}
      value={value}
      onChange={handleChange}
      className={className}
      isLoading={isLoading}
      error={error ? t("uf.error") : undefined}
      disabled={disabled}
      required={required}
      placeholder={isLoading ? t("uf.loading") : t("uf.select")}
    />
  );
};

export default SelectUF;
