// src/features/company/components/CompanyForm/index.tsx
import { useMemo, useImperativeHandle, forwardRef, useRef } from "react";
import { useGetCompanyByIdQuery } from "features/company/api";
import CrudForm from "features/crud/components/CrudForm";
import { Company } from "features/company/types";
import { CrudMode } from "features/crud/types";
import { ApiError } from "features/crud/types/error";
import { getCompanyCrudConfig } from "./config";
import { getDisabledFields } from "./utils/formUtils";
import Spinner from "common/components/Spinner";
import { entityTransformers } from "features/crud/utils/transformer";
import { ApiErrorResponse } from "common/types";
import { useTranslation } from "react-i18next";

interface CompanyFormProps {
  onSuccess?: (mode: CrudMode, data: Company) => void;
  onError?: (error: ApiError) => void;
  onCancel?: () => void;
  readOnly?: boolean;
  initialId?: number;
  mode?: CrudMode;
  initialData?: Partial<Company>;
  disabledFields?: string[];
  showBackButton?: boolean;
  hideButtons?: boolean;
  formRef?: React.MutableRefObject<{ submitForm: () => void } | null>;
}

const CompanyForm = forwardRef<
  { submitForm: () => void } | null,
  CompanyFormProps
>(
  (
    {
      onSuccess,
      onError,
      onCancel,
      readOnly = false,
      initialId,
      mode = "create",
      initialData,
      disabledFields: customDisabledFields,
      showBackButton = true,
      hideButtons = false,
      formRef,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const formContainerRef = useRef<HTMLDivElement>(null);

    // Handle ref passed directly or through formRef prop
    const actualRef = ref || formRef;

    const {
      data: companyResponse,
      isLoading,
      error,
    } = useGetCompanyByIdQuery(initialId as number, {
      skip: !initialId || mode === "create",
      refetchOnMountOrArgChange: true,
    });

    const formInitialData = useMemo(() => {
      if (initialData) return initialData;

      if (!companyResponse?.data) return undefined;

      const transformedData = entityTransformers.empresa?.response(
        companyResponse.data,
      );
      return transformedData;
    }, [companyResponse, initialData]);

    const defaultDisabledFields = useMemo(
      () => getDisabledFields(mode),
      [mode],
    );

    const mergedDisabledFields = useMemo(() => {
      if (!customDisabledFields) return defaultDisabledFields;
      return Array.from(
        new Set([...defaultDisabledFields, ...customDisabledFields]),
      );
    }, [defaultDisabledFields, customDisabledFields]);

    const enhancedConfig = useMemo(() => {
      const baseConfig = getCompanyCrudConfig(mode);

      return {
        ...baseConfig,
        fields: baseConfig.fields.map((field) => ({
          ...field,
          disabled:
            readOnly || mergedDisabledFields.includes(field.name as string),
        })),
      };
    }, [mode, readOnly, mergedDisabledFields]);

    // Expose submit function via ref
    useImperativeHandle(actualRef, () => ({
      submitForm: () => {
        if (formContainerRef.current) {
          const formElement = formContainerRef.current.querySelector("form");
          if (formElement) {
            try {
              formElement.dispatchEvent(
                new Event("submit", { bubbles: true, cancelable: true }),
              );
            } catch (e) {
              console.error("Error submitting form:", e);
            }
          }
        }
      },
    }));

    const handleSuccess = (mode: CrudMode, data: Company) => {
      onSuccess?.(mode, data);
    };

    if (isLoading) {
      return (
        <div className="flex justify-center items-center min-h-[200px]">
          <Spinner size="lg" />
        </div>
      );
    }

    const initialError = error
      ? ({
          status: (error as ApiErrorResponse).statusCode || 500,
          data: {
            mensagem:
              (error as ApiErrorResponse)?.detalhes?.errorIdentifier ||
              t("companyForm.error.loading"),
          },
        } as ApiError)
      : undefined;

    return (
      <div ref={formContainerRef} className="company-form-container">
        {hideButtons && (
          <style>
            {`
            .company-form-container form > div:last-child {
              display: none;
            }
          `}
          </style>
        )}
        <CrudForm<Company>
          config={{
            ...enhancedConfig,
          }}
          initialData={formInitialData}
          onSuccess={handleSuccess}
          onError={onError}
          onCancel={onCancel}
          readOnly={readOnly}
          initialId={initialId}
          mode={mode}
          initialFormError={initialError}
          headerConfig={{
            title: "",
            showBackButton,
            onBack: onCancel,
          }}
        />
      </div>
    );
  },
);

export default CompanyForm;
