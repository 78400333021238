import { useTranslation } from "react-i18next";
import Select from "common/components/Select";
import { IdentificationFormState } from "features/project/slices/projectFormSlice";

interface ProjectClassificationProps {
  formData: IdentificationFormState;
  handleInputChange: (
    field: keyof IdentificationFormState,
    value: string | number | Date | null,
  ) => void;
  isViewMode: boolean;
  productTypeOptions: Array<{ value: string; label: string }>;
  innovationPhaseOptions: Array<{ value: string; label: string }>;
  sharingTypeOptions: Array<{ value: string; label: string }>;
  isLoadingProductType: boolean;
  isLoadingInnovationPhase: boolean;
  isLoadingSharingType: boolean;
}

const ProjectClassification = ({
  formData,
  handleInputChange,
  isViewMode,
  productTypeOptions,
  innovationPhaseOptions,
  sharingTypeOptions,
  isLoadingProductType,
  isLoadingInnovationPhase,
  isLoadingSharingType,
}: ProjectClassificationProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-3 p-2 rounded-md border border-[--table-header-bg] bg-[--breadcrumb-bg] shadow-sm">
      <h4 className="text-sm text-primary-dark font-semibold p-1.5 bg-[--primary-light] rounded-md shadow-sm text-center">
        {t("project.identificationStep.sections.classification")}
      </h4>

      <div className="flex flex-col gap-4 p-1">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3">
          <Select
            label={t("project.identificationStep.fields.productType")}
            options={productTypeOptions}
            value={formData.tipo_produto_projeto_id}
            onChange={(value) =>
              handleInputChange("tipo_produto_projeto_id", value)
            }
            placeholder={t(
              "project.identificationStep.placeholders.productType",
            )}
            isLoading={isLoadingProductType}
            required
            disabled={isViewMode}
          />
          <Select
            label={t("project.identificationStep.fields.innovationPhase")}
            options={innovationPhaseOptions}
            value={formData.fase_inovacao_projeto_id}
            onChange={(value) =>
              handleInputChange("fase_inovacao_projeto_id", value)
            }
            placeholder={t(
              "project.identificationStep.placeholders.innovationPhase",
            )}
            isLoading={isLoadingInnovationPhase}
            required
            disabled={isViewMode}
          />
          <Select
            label={t("project.identificationStep.fields.sharingType")}
            options={sharingTypeOptions}
            value={formData.tipo_compartilhamento_projeto_id}
            onChange={(value) =>
              handleInputChange("tipo_compartilhamento_projeto_id", value)
            }
            placeholder={t(
              "project.identificationStep.placeholders.sharingType",
            )}
            isLoading={isLoadingSharingType}
            required
            disabled={isViewMode}
          />
        </div>
      </div>
    </div>
  );
};

export default ProjectClassification;
