import { X } from "lucide-react";
import SidebarContent from "common/components/Dashboard/components/Sidebar/components/SidebarContent";

interface SidebarProps {
  isOpen: boolean;
  isDesktop: boolean;
  onClose: () => void;
}

const Sidebar = ({ isOpen, isDesktop, onClose }: SidebarProps) => {
  return (
    <>
      {/* Full-screen backdrop for mobile */}
      {!isDesktop && isOpen && (
        <div
          className="fixed inset-0 bg-black/50 z-50 transition-opacity duration-300"
          onClick={onClose}
        />
      )}

      <aside
        className={`
          ${isDesktop ? "max-lg:mt-16" : "fixed inset-y-0 left-0"}
          h-full bg-light-bg 
          transition-all duration-300 ease-in-out 
          z-50
          ${isOpen ? "max-sm:w-10/12 w-72 border-r-2 border-r-table-header-bg" : "w-0"}
          ${isDesktop ? "lg:translate-x-0 lg:static" : ""}
        `}
      >
        <div
          className={`
            h-full transition-all duration-300 ease-in-out relative
            ${isOpen ? "translate-x-0 opacity-100" : "translate-x-[-280px] opacity-0"}
          `}
        >
          {/* Close button - only visible on mobile */}
          {!isDesktop && (
            <button
              onClick={onClose}
              className="absolute right-0 top-0 p-2 hover:bg-primary-light rounded-full transition-colors duration-200"
              aria-label="Close sidebar"
            >
              <X className="w-6 h-6 text-text" />
            </button>
          )}
          <SidebarContent onClose={onClose} />
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
