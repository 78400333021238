import { StepKey } from "features/project/components/PDIProject/components/Project/components/Stepper/types";
import Identification from "features/project/components/PDIProject/components/Project/components/Stepper/steps/Identification";
import Description from "features/project/components/PDIProject/components/Project/components/Stepper/steps/Description";
import Entities from "features/project/components/PDIProject/components/Project/components/Stepper/steps/Entities";
import Roles from "features/project/components/PDIProject/components/Project/components/Stepper/steps/Roles";
export interface StepComponentProps {
  projectId?: number;
  viewMode?: "view" | "edit" | "create";
  onSaveStep?: (success: boolean, projectId?: number) => void;
  registerSaveHandler?: (handler: () => Promise<boolean>) => void;
}

export const STEP_ORDER: StepKey[] = [
  "identification",
  "description",
  "entities",
  "roles",
];

export const getStepTranslationKey = (stepKey: StepKey): string => {
  return `project.steps.${stepKey}`;
};

export const getNextStepKey = (currentStep: StepKey): StepKey | undefined => {
  const currentIndex = STEP_ORDER.indexOf(currentStep);
  return currentIndex < STEP_ORDER.length - 1
    ? STEP_ORDER[currentIndex + 1]
    : undefined;
};

export const getPrevStepKey = (currentStep: StepKey): StepKey | undefined => {
  const currentIndex = STEP_ORDER.indexOf(currentStep);
  return currentIndex > 0 ? STEP_ORDER[currentIndex - 1] : undefined;
};

export const getStepComponent = (
  stepKey: StepKey,
  props: StepComponentProps,
) => {
  switch (stepKey) {
    case "identification":
      return <Identification {...props} />;
    case "description":
      return <Description {...props} />;
    case "entities":
      return <Entities {...props} />;
    case "roles":
      return <Roles {...props} />;
    default:
      return null;
  }
};
