import { useTranslation } from "react-i18next";
import { IdentificationFormState } from "features/project/slices/projectFormSlice";
import { formatCurrency } from "common/utils/formatters";

interface IdentificationViewProps {
  formData: IdentificationFormState;
  productTypeOptions?: Array<{ value: string; label: string }>;
  innovationPhaseOptions?: Array<{ value: string; label: string }>;
  sharingTypeOptions?: Array<{ value: string; label: string }>;
}

const IdentificationView = ({
  formData,
  productTypeOptions = [],
  innovationPhaseOptions = [],
  sharingTypeOptions = [],
}: IdentificationViewProps) => {
  const { t } = useTranslation();

  const getLabelFromOptions = (
    value: string | number | null,
    options: Array<{ value: string; label: string }>,
  ) => {
    if (!value) return "-";
    const option = options.find((opt) => opt.value === value);
    return option ? option.label : value;
  };

  const renderSummaryField = (
    label: string,
    value: string | number | null,
    skipFormatting = false,
  ) => {
    const processedValue = (() => {
      if (value === null || value === undefined || value === "") return "-";

      if (typeof value === "number" && !skipFormatting) {
        return formatCurrency(value);
      }

      return String(value);
    })();

    return (
      <div className="flex justify-between items-center p-1 border rounded-md bg-white">
        <p className="text-sm text-text-secondary font-medium">{label}:</p>
        <p className="font-semibold">{processedValue}</p>
      </div>
    );
  };

  const formatDate = (dateString: string | null) => {
    if (!dateString) return "-";

    try {
      const date = new Date(dateString);
      return date.toLocaleDateString("pt-BR");
    } catch (e) {
      return "-";
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-1 p-1 border border-table-header-bg rounded-md">
        <h3 className="text-primary font-semibold text-center p-1 bg-[--primary-light] rounded-md shadow-sm">
          {t("project.identificationStep.sections.basicInformation")}
        </h3>

        <div className="flex flex-col gap-1 p-1 rounded-md border border-[--table-header-bg] bg-[--breadcrumb-bg] shadow-sm">
          <div className="grid grid-cols-1 gap-1 p-1">
            {renderSummaryField(
              t("project.identificationStep.fields.projectType"),
              formData.projectTypeDisplay,
              true,
            )}

            {renderSummaryField(
              t("project.identificationStep.fields.projectName"),
              formData.nome,
              true,
            )}

            {renderSummaryField(
              t("project.identificationStep.fields.projectTitle"),
              formData.titulo,
              true,
            )}

            {renderSummaryField(
              t("project.identificationStep.fields.shortTitle"),
              formData.titulo_resumido,
              true,
            )}
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-1 p-1 border border-table-header-bg rounded-md">
        <h3 className="text-primary font-semibold text-center p-1 bg-[--primary-light] rounded-md shadow-sm">
          {t("project.identificationStep.sections.codesAndDates")}
        </h3>

        <div className="flex flex-col gap-1 p-1 rounded-md border border-[--table-header-bg] bg-[--breadcrumb-bg] shadow-sm">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-1 p-1">
            <div className="flex flex-col gap-1">
              {renderSummaryField(
                t("project.identificationStep.fields.odsCode"),
                formData.codigo_ods,
                true,
              )}
              {renderSummaryField(
                t("project.identificationStep.fields.proposalCode"),
                formData.codigo_proposta,
                true,
              )}
              {renderSummaryField(
                t("project.identificationStep.fields.aneelCode"),
                formData.codigo_aneel,
                true,
              )}
            </div>

            <div className="flex flex-col gap-1">
              {renderSummaryField(
                t("project.identificationStep.fields.startDate"),
                formatDate(formData.data_inicio_prevista),
                true,
              )}
              {renderSummaryField(
                t("project.identificationStep.fields.duration"),
                formData.duracao,
                true,
              )}

              <div className="grid grid-cols-2 gap-1">
                <div className="flex justify-between items-center p-1 border rounded-md bg-white">
                  <p className="text-sm text-text-secondary font-medium">
                    {t("project.identificationStep.fields.initialTrl")}:
                  </p>
                  <p className="font-semibold">{formData.trl_inicial || "-"}</p>
                </div>
                <div className="flex justify-between items-center p-1 border rounded-md bg-white">
                  <p className="text-sm text-text-secondary font-medium">
                    {t("project.identificationStep.fields.finalTrl")}:
                  </p>
                  <p className="font-semibold">{formData.trl_final || "-"}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-1 p-1 border border-table-header-bg rounded-md">
        <h3 className="text-primary font-semibold text-center p-1 bg-[--primary-light] rounded-md shadow-sm">
          {t("project.identificationStep.sections.financialInformation")}
        </h3>

        <div className="flex flex-col gap-1 p-1 rounded-md border border-[--table-header-bg] bg-[--breadcrumb-bg] shadow-sm">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-1 p-1">
            <div className="grid grid-cols-1 gap-1">
              {renderSummaryField(
                t("project.identificationStep.fields.totalValue"),
                formData.vl_projeto_total,
                false,
              )}
              {renderSummaryField(
                t("project.identificationStep.fields.transferValue"),
                formData.vl_repasse_total,
                false,
              )}
            </div>
            <div className="grid grid-cols-1 gap-1">
              {renderSummaryField(
                t("project.identificationStep.fields.counterpartValue"),
                formData.vl_contrapartida,
                false,
              )}
              {renderSummaryField(
                t("project.identificationStep.fields.internalValue"),
                formData.vl_recurso_interno,
                false,
              )}
            </div>
          </div>
          <div className="p-1">
            {renderSummaryField(
              t("project.identificationStep.fields.capitation"),
              formData.captacao,
              true,
            )}
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-1 p-1 border border-table-header-bg rounded-md">
        <h3 className="text-primary font-semibold text-center p-1 bg-[--primary-light] rounded-md shadow-sm">
          {t("project.identificationStep.sections.classification")}
        </h3>

        <div className="flex flex-col gap-1 p-1 rounded-md border border-[--table-header-bg] bg-[--breadcrumb-bg] shadow-sm">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-1 p-1">
            {renderSummaryField(
              t("project.identificationStep.fields.productType"),
              getLabelFromOptions(
                formData.tipo_produto_projeto_id,
                productTypeOptions,
              ),
              true,
            )}
            {renderSummaryField(
              t("project.identificationStep.fields.innovationPhase"),
              getLabelFromOptions(
                formData.fase_inovacao_projeto_id,
                innovationPhaseOptions,
              ),
              true,
            )}
            {renderSummaryField(
              t("project.identificationStep.fields.sharingType"),
              getLabelFromOptions(
                formData.tipo_compartilhamento_projeto_id,
                sharingTypeOptions,
              ),
              true,
            )}
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-1 p-1 border border-table-header-bg rounded-md">
        <h3 className="text-primary font-semibold text-center p-1 bg-[--primary-light] rounded-md shadow-sm">
          {t("project.identificationStep.sections.themeInformation")}
        </h3>

        <div className="flex flex-col gap-1 p-1 rounded-md border border-[--table-header-bg] bg-[--breadcrumb-bg] shadow-sm">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-1 p-1">
            {renderSummaryField(
              t("project.identificationStep.fields.themeCode"),
              formData.codigo_tema,
              true,
            )}
            {renderSummaryField(
              t("project.identificationStep.fields.otherTheme"),
              formData.outro_tema,
              true,
            )}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-1 p-1">
            {renderSummaryField(
              t("project.identificationStep.fields.subthemeCode"),
              formData.codigo_subtema,
              true,
            )}
            {renderSummaryField(
              t("project.identificationStep.fields.otherSubtheme"),
              formData.outro_subtema,
              true,
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IdentificationView;
