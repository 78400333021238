// src/common/components/Dashboard/hooks/useSidebarState.ts
import { useEffect, useCallback, useState } from "react";

const DESKTOP_SIDEBAR_STATE_KEY = "dashboard_desktop_sidebar_state";

export const useSidebarState = (initialDesktopState = true) => {
  const [isDesktopSidebarOpen, setIsDesktopSidebarOpen] = useState(() => {
    const savedState = localStorage.getItem(DESKTOP_SIDEBAR_STATE_KEY);
    return savedState ? JSON.parse(savedState) : initialDesktopState;
  });

  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);

  useEffect(() => {
    localStorage.setItem(
      DESKTOP_SIDEBAR_STATE_KEY,
      JSON.stringify(isDesktopSidebarOpen),
    );
  }, [isDesktopSidebarOpen]);

  useEffect(() => {
    const handleResize = () => {
      const isDesktopView = window.innerWidth >= 1024;
      setIsDesktop(isDesktopView);

      if (isDesktopView) {
        setIsMobileSidebarOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSidebarOpen = useCallback(() => {
    if (isDesktop) {
      setIsDesktopSidebarOpen((prev: boolean) => !prev);
    } else {
      setIsMobileSidebarOpen((prev: boolean) => !prev);
    }
  }, [isDesktop]);

  const handleCloseMobileSidebar = useCallback(() => {
    if (!isDesktop) {
      setIsMobileSidebarOpen(false);
    }
  }, [isDesktop]);

  return {
    isDesktopSidebarOpen,
    isMobileSidebarOpen,
    isDesktop,
    handleSidebarOpen,
    handleCloseMobileSidebar,
  };
};
