import { useState } from "react";
import Select from "common/components/Select";
import { useGetMunicipalityQuery } from "features/location/api";
import { Municipality } from "features/company/types";
import { useTranslation } from "react-i18next";

interface SelectMunicipalityProps {
  ufId?: number;
  onChange?: (municipality: Municipality | null) => void;
  value?: string;
  className?: string;
  error?: string;
  disabled?: boolean;
  required?: boolean;
}

const SelectMunicipality = ({
  ufId,
  onChange,
  value: externalValue,
  className,
  error,
  disabled,
  required,
}: SelectMunicipalityProps) => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetMunicipalityQuery(ufId?.toString() ?? "", {
    skip: !ufId,
  });
  const [internalValue, setInternalValue] = useState("");

  const value = externalValue ?? internalValue;

  const options =
    data?.data.map((municipality) => ({
      value: municipality.municipio_id.toString(),
      label: municipality.nome_municipio,
    })) ?? [];

  const handleChange = (selectedValue: string) => {
    const selectedMunicipality =
      data?.data.find(
        (municipality) =>
          municipality.municipio_id.toString() === selectedValue,
      ) ?? null;

    if (!externalValue) {
      setInternalValue(selectedValue);
    }

    onChange?.(selectedMunicipality as Municipality);
  };

  return (
    <Select
      label="Município"
      options={options}
      value={value}
      onChange={handleChange}
      className={className}
      isLoading={isLoading}
      disabled={isLoading || disabled || options.length === 0}
      placeholder={
        isLoading ? "Carregando municípios..." : "Selecione um município"
      }
      error={error ? t("municipality.error") : undefined}
      required={required}
      searchable={true}
    />
  );
};

export default SelectMunicipality;
