import RoleColumn from "features/project/components/PDIProject/components/Project/components/Stepper/steps/Roles/components/RoleColumn";

interface RoleUser {
  id: number;
  name: string;
  status?: "pending" | "active";
}

interface RoleColumn {
  id: number;
  title: string;
  users: RoleUser[];
  allowsInvite: boolean;
}

interface RolesViewProps {
  roleColumns: RoleColumn[];
}

const RolesView = ({ roleColumns }: RolesViewProps) => {
  return (
    <div className="p-4">
      <h2 className="text-xl font-bold mb-4">Papéis do Projeto</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4">
        {roleColumns.map((column) => (
          <RoleColumn
            key={column.id}
            title={column.title}
            users={column.users}
            allowsInvite={column.allowsInvite}
            isViewMode={true}
            roleId={column.id}
          />
        ))}
      </div>
    </div>
  );
};

export default RolesView;
