import { MoreHorizontal, Slash } from "lucide-react";
import type { TableAction } from "common/components/ServerTable/types";

interface ActionButtonsProps<T> {
  actions: TableAction<T>[];
  row: T;
}

const ActionButtons = <T,>({ actions, row }: ActionButtonsProps<T>) => {
  const visibleActions = actions.filter((action) =>
    action.show ? action.show(row) : true,
  );

  if (visibleActions.length === 0) {
    return null;
  }

  return (
    <div className="relative flex items-center gap-1 justify-start">
      {visibleActions.map((action, index) => {
        const Icon = action.icon;
        const isDisabled = action.disabled?.(row);

        return (
          <button
            key={index}
            onClick={() => !isDisabled && action.onClick(row)}
            className={`inline-flex items-center justify-center w-7 h-7 rounded-md transition-colors duration-200
              ${
                isDisabled
                  ? "opacity-50 cursor-not-allowed pointer-events-none"
                  : action.variant === "destructive"
                    ? "text-[--error] hover:bg-[--error-bg]"
                    : "text-[--text] hover:bg-[--primary-hover-light]"
              }`}
            title={action.label}
          >
            {Icon && (
              <div className="relative">
                <Icon
                  className={`w-3.5 h-3.5 ${
                    isDisabled
                      ? "text-gray-400"
                      : action.variant === "destructive"
                        ? "text-[--error]"
                        : "text-[--primary]"
                  }`}
                />
                {action.showSlash && (
                  <Slash
                    className={`absolute -top-1 -left-0.5 w-5 h-5 ${
                      action.variant === "destructive"
                        ? "text-[--error]"
                        : "text-[--primary]"
                    }`}
                  />
                )}
              </div>
            )}
            {!Icon && <MoreHorizontal className="w-4 h-4 text-[--primary]" />}
          </button>
        );
      })}
    </div>
  );
};

export default ActionButtons;
