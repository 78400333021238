import { CrudMode } from "features/crud/types";

export const getDisabledFields = (mode: CrudMode): string[] => {
  const disabledFields: string[] = [];

  if (mode === "edit") {
    // Fields that should be disabled in edit mode
    disabledFields.push("cnpj");
  }

  return disabledFields;
};
