import React from "react";
import { useTranslation } from "react-i18next";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  perPage: number;
  onPerPageChange: (perPage: number) => void;
  totalRecords: number;
}

const Pagination = ({
  currentPage = 1,
  totalPages = 1,
  onPageChange,
  perPage,
  totalRecords,
}: PaginationProps) => {
  const { t } = useTranslation();
  const handlePageChange = (page: number) => {
    if (page < 1 || page > totalPages) return;
    onPageChange(page);
  };

  const renderPageNumbers = () => {
    const pages: (number | string)[] = [];
    if (window.innerWidth < 640) {
      if (currentPage > 1) pages.push(currentPage - 1);
      pages.push(currentPage);
      if (currentPage < totalPages) pages.push(currentPage + 1);
      return pages;
    }

    pages.push(1);

    const pagesBeforeCollapse = 5;
    const startPage = Math.max(
      2,
      currentPage - Math.floor(pagesBeforeCollapse / 2),
    );
    const endPage = Math.min(
      totalPages - 1,
      currentPage + Math.floor(pagesBeforeCollapse / 2),
    );

    if (startPage > 2) pages.push("...");

    for (let i = startPage; i <= endPage; i++) {
      if (i > 1 && i < totalPages) {
        pages.push(i);
      }
    }

    if (endPage < totalPages - 1) pages.push("...");
    if (totalPages > 1) pages.push(totalPages);

    return pages;
  };

  const startRange = Math.min(
    totalRecords,
    Math.max(1, (currentPage - 1) * perPage + 1),
  );
  const endRange = Math.min(currentPage * perPage, totalRecords);

  return (
    <div className="flex flex-col gap-4 sm:flex-row sm:items-center sm:justify-between px-4 pb-4 border-t border-table-header-bg pt-4">
      <div className="text-sm text-[--text] text-center sm:text-left">
        <span className="hidden sm:inline">
          {t("serverTable.pagination.showing")}{" "}
          <span className="font-medium">{startRange}</span>{" "}
          {t("serverTable.pagination.to")}{" "}
          <span className="font-medium">{endRange}</span>{" "}
          {t("serverTable.pagination.of")}{" "}
          <span className="font-medium">{totalRecords}</span>
        </span>
        <span className="hidden sm:inline">
          {" "}
          {t("serverTable.pagination.results")}
        </span>
      </div>

      <div className="flex items-center justify-center gap-2 sm:justify-end">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className={`px-2 sm:px-3 py-1 rounded-md text-sm min-w-[70px] sm:min-w-[80px] transition-colors duration-200
            ${
              currentPage === 1
                ? "bg-[--light-bg] text-[--text-secondary] cursor-not-allowed"
                : "bg-white text-[--text] hover:bg-[--primary-light] border border-[--text-secondary] border-opacity-20"
            }`}
        >
          {t("serverTable.pagination.previous")}
        </button>

        <div className="flex items-center gap-1 sm:gap-2 overflow-x-auto">
          {renderPageNumbers().map((page, index) => (
            <React.Fragment key={index}>
              {typeof page === "number" ? (
                <button
                  onClick={() => handlePageChange(page)}
                  className={`px-2 sm:px-3 py-1 rounded-md text-sm min-w-[32px] transition-colors duration-200
                    ${
                      currentPage === page
                        ? "bg-[--primary] text-white"
                        : "bg-white text-[--text] hover:bg-[--primary-light] border border-[--text-secondary] border-opacity-20"
                    }`}
                >
                  {page}
                </button>
              ) : (
                <span className="text-[--text-secondary]">...</span>
              )}
            </React.Fragment>
          ))}
        </div>

        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className={`px-2 sm:px-3 py-1 rounded-md text-sm min-w-[70px] sm:min-w-[80px] transition-colors duration-200
            ${
              currentPage === totalPages
                ? "bg-[--light-bg] text-[--text-secondary] cursor-not-allowed"
                : "bg-white text-[--text] hover:bg-[--primary-light] border border-[--text-secondary] border-opacity-20"
            }`}
        >
          {t("serverTable.pagination.next")}
        </button>
      </div>
    </div>
  );
};

export default Pagination;
