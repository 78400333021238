import { useTranslation } from "react-i18next";
import { X } from "lucide-react";
import { TagItem } from "features/project/types";
import Input from "common/components/Input";

interface TagInputProps {
  label: string;
  isViewMode: boolean;
  inputValue: string;
  tags: TagItem[];
  isLoading: boolean;
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onRemoveTag: (tagId: number) => void;
}

const TagInput = ({
  label,
  isViewMode,
  inputValue,
  tags,
  isLoading,
  onInputChange,
  onKeyPress,
  onRemoveTag,
}: TagInputProps) => {
  const { t } = useTranslation();

  return (
    <div className="space-y-2">
      {label && (
        <label className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      )}

      {/* Display tags */}
      <div className="flex flex-wrap gap-2 mb-2">
        {tags.map((tag) => (
          <div
            key={tag.tag_id}
            className={`
              inline-flex items-center rounded-lg px-3 py-1.5 text-sm 
              ${
                isViewMode
                  ? "bg-gray-100 text-gray-800"
                  : "bg-blue-100 text-blue-800"
              }
            `}
          >
            <span>{tag.descricao}</span>
            {!isViewMode && (
              <button
                type="button"
                onClick={() => onRemoveTag(tag.tag_id)}
                className="ml-1 rounded-full p-0.5 hover:bg-blue-200 focus:outline-none"
                aria-label={t("common.remove")}
              >
                <X size={14} />
              </button>
            )}
          </div>
        ))}

        {tags.length === 0 && (
          <div className="text-gray-500 text-sm italic flex items-center">
            {t("project.descriptionStep.noTagsAdded")}
          </div>
        )}
      </div>

      {/* Input for adding new tags (only in edit/create mode) */}
      {!isViewMode && (
        <div>
          <Input
            value={inputValue}
            onChange={onInputChange}
            onKeyDown={onKeyPress}
            placeholder={t("project.descriptionStep.addTagPlaceholder")}
            className="w-full"
            disabled={isLoading}
          />
        </div>
      )}
    </div>
  );
};

export default TagInput;
