import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "config/baseQuery";

export interface Member {
  id: number;
  name: string;
}

// Temporary interface to simulate backend responses
interface ApiResponse<T> {
  data: T;
  message: string;
}

export const cucaApi = createApi({
  reducerPath: "cucaApi",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["Members"],
  endpoints: (builder) => ({
    getMembers: builder.query<Member[], void>({
      // Temporary implementation using localStorage
      queryFn: () => {
        const members = localStorage.getItem("cucaMembers");
        return { data: members ? JSON.parse(members) : [] };
      },
      providesTags: ["Members"],
    }),

    addMember: builder.mutation<ApiResponse<Member>, Omit<Member, "id">>({
      // Temporary implementation
      queryFn: (newMember) => {
        const members = JSON.parse(localStorage.getItem("cucaMembers") || "[]");
        const id = members.length
          ? Math.max(...members.map((m: Member) => m.id)) + 1
          : 1;
        const member = { ...newMember, id };
        localStorage.setItem(
          "cucaMembers",
          JSON.stringify([...members, member]),
        );
        return { data: { data: member, message: "Member added successfully" } };
      },
      invalidatesTags: ["Members"],
    }),

    updateMember: builder.mutation<ApiResponse<Member>, Member>({
      // Temporary implementation
      queryFn: (updatedMember) => {
        const members = JSON.parse(localStorage.getItem("cucaMembers") || "[]");
        const newMembers = members.map((m: Member) =>
          m.id === updatedMember.id ? updatedMember : m,
        );
        localStorage.setItem("cucaMembers", JSON.stringify(newMembers));
        return {
          data: { data: updatedMember, message: "Member updated successfully" },
        };
      },
      invalidatesTags: ["Members"],
    }),

    deleteMember: builder.mutation<ApiResponse<void>, number>({
      // Temporary implementation
      queryFn: (id) => {
        const members = JSON.parse(localStorage.getItem("cucaMembers") || "[]");
        const newMembers = members.filter((m: Member) => m.id !== id);
        localStorage.setItem("cucaMembers", JSON.stringify(newMembers));
        return {
          data: { data: undefined, message: "Member deleted successfully" },
        };
      },
      invalidatesTags: ["Members"],
    }),
  }),
});

export const {
  useGetMembersQuery,
  useAddMemberMutation,
  useUpdateMemberMutation,
  useDeleteMemberMutation,
} = cucaApi;
