// src/features/auth/types/index.ts
import { LoggedUserData } from "features/user/types";

// Login
export interface LoginRequest {
  email: string;
  password: string;
  newPassword?: string;
}

export interface LoginData {
  email: string;
  token: string;
  password: null;
}

export interface LoginResponse {
  mensagem: string;
  data: LoginData;
}

// Error Identifiers
export enum ErrorIdentifier {
  NEW_PASSWORD_REQUIRED = "NEW_PASSWORD_REQUIRED",
  EMAIL_NAO_CADASTRADO = "EMAIL_NAO_CADASTRADO",
  INCORRECT_USERNAME_PASSWORD = "INCORRECT_USERNAME_PASSWORD",
}

// Password Management
export interface ForgotPasswordRequest {
  email: string;
}

export interface ResetPasswordRequest {
  email: string;
  token: string;
  password: string;
}

export interface ForgotPasswordResponse {
  mensagem: string;
  data: boolean;
}

export interface ResetPasswordResponse {
  mensagem: string;
  data: boolean;
}

export interface PasswordValidation {
  hasMinLength: boolean;
  hasNumber: boolean;
  hasSpecialChar: boolean;
  hasUpperCase: boolean;
  hasLowerCase: boolean;
  passwordsMatch: boolean;
}

// Form States
export interface LoginFormState {
  email: string;
  password: string;
  rememberMe: boolean;
}

export interface ResetPasswordFormState {
  email: string;
  verificationCode: string;
  newPassword: string;
  confirmPassword: string;
  currentPassword?: string;
  isFromForgotPassword: boolean;
}

export interface FirstTimePasswordFormState {
  temporaryPassword: string;
  newPassword: string;
  confirmPassword: string;
}

// Auth State
export interface AuthState {
  isAuthenticated: boolean;
  loading: boolean;
  loginError: string | null;
  passwordChangeError: string | null;
  user: LoggedUserData | null;
  isOpen: boolean;
}

// Storage Keys
export const AUTH_STORAGE_KEYS = {
  TOKEN: "accessToken",
  REMEMBER_ME: "rememberMe",
  SELECTED_COMPANY: "selectedCompany",
  STORAGE_SYNC: "app_sync",
  COMPANY_CODE_PREFIX: "codigo-empresa-",
} as const;
