import Select from "common/components/Select";
import { useTranslation } from "react-i18next";
import { CrudComponentProps } from "features/crud/types";

const SelectStatus = ({
  required,
  value,
  onChange,
  disabled,
}: CrudComponentProps<boolean>) => {
  const { t } = useTranslation();

  const handleOnChange = (e: React.ReactNode) => {
    onChange(e === "true");
  };

  const statusOptions = [
    { label: t("status.options.active"), value: "true" },
    { label: t("status.options.inactive"), value: "false" },
  ];

  const stringValue = value?.toString() ?? "";

  return (
    <div>
      <Select
        label={t("status.label")}
        options={statusOptions}
        value={stringValue}
        onChange={handleOnChange}
        placeholder={t("status.placeholder")}
        required={required}
        disabled={disabled}
      />
    </div>
  );
};

export default SelectStatus;
