import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  StepKey,
  StepStatus,
} from "features/project/components/PDIProject/components/Project/components/Stepper/types";
import { STEP_ORDER } from "features/project/components/PDIProject/components/Project/components/Stepper/config";

interface StepperState {
  currentStep: StepKey;
  stepStatuses: Record<StepKey, StepStatus>;
  currentProjectId?: number;
  isSaving: boolean;
}

const initialState: StepperState = {
  currentStep: "identification",
  stepStatuses: {
    identification: "in-progress",
    description: "pending",
    entities: "pending",
    roles: "pending",
  },
  currentProjectId: undefined,
  isSaving: false,
};

export const stepperSlice = createSlice({
  name: "stepper",
  initialState,
  reducers: {
    setCurrentStep: (state, action: PayloadAction<StepKey>) => {
      state.currentStep = action.payload;

      if (state.stepStatuses[action.payload] === "pending") {
        state.stepStatuses[action.payload] = "in-progress";
      }
    },

    setStepStatus: (
      state,
      action: PayloadAction<{ stepKey: StepKey; status: StepStatus }>,
    ) => {
      const { stepKey, status } = action.payload;
      state.stepStatuses[stepKey] = status;
    },

    completeStep: (
      state,
      action: PayloadAction<{
        stepKey: StepKey;
        success: boolean;
        projectId?: number;
      }>,
    ) => {
      const { stepKey, success, projectId } = action.payload;

      state.stepStatuses[stepKey] = success ? "completed" : "error";

      if (projectId && !state.currentProjectId) {
        state.currentProjectId = projectId;
      }

      if (success) {
        const currentIndex = STEP_ORDER.indexOf(stepKey);
        const nextStepKey = STEP_ORDER[currentIndex + 1];

        if (nextStepKey) {
          state.currentStep = nextStepKey;
          if (state.stepStatuses[nextStepKey] === "pending") {
            state.stepStatuses[nextStepKey] = "in-progress";
          }
        }
      }
    },

    setProjectId: (state, action: PayloadAction<number>) => {
      state.currentProjectId = action.payload;
    },

    setSaving: (state, action: PayloadAction<boolean>) => {
      state.isSaving = action.payload;
    },

    resetStepper: (
      state,
      action: PayloadAction<{ viewMode: "view" | "edit" | "create" }>,
    ) => {
      const { viewMode } = action.payload;
      const currentId = state.currentProjectId;

      if (viewMode === "create") {
        return initialState;
      } else if (viewMode === "view" && currentId) {
        return {
          ...initialState,
          currentProjectId: currentId,
          stepStatuses: {
            identification: "completed",
            description: "completed",
            entities: "completed",
            roles: "completed",
          },
        };
      } else if (viewMode === "edit" && currentId) {
        return {
          ...state,
          currentProjectId: currentId,
          currentStep: "identification",
          stepStatuses: {
            identification: "completed",
            description: "pending",
            entities: "pending",
            roles: "pending",
          },
        };
      }
      return state;
    },
  },
});

export const {
  setCurrentStep,
  setStepStatus,
  completeStep,
  setProjectId,
  setSaving,
  resetStepper,
} = stepperSlice.actions;

export default stepperSlice.reducer;
