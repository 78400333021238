import { useTranslation } from "react-i18next";
import { DescriptionFormState } from "features/project/slices/projectFormSlice";
import { TagItem } from "features/project/types";

interface DescriptionViewProps {
  formData: DescriptionFormState;
}

const DescriptionView = ({ formData }: DescriptionViewProps) => {
  const { t } = useTranslation();

  const renderSectionHeader = (title: string) => (
    <h3 className="text-primary font-semibold text-center p-1 bg-[--primary-light] rounded-md shadow-sm">
      {title}
    </h3>
  );

  const renderTextSection = (
    sectionKey: keyof DescriptionFormState,
    title: string,
  ) => {
    const value = formData[sectionKey] as string;
    const processedValue = value || t("project.descriptionStep.notProvided");

    return (
      <div className="flex flex-col gap-1 p-1 border border-table-header-bg rounded-md">
        {renderSectionHeader(title)}

        <div className="flex flex-col gap-1 p-1 rounded-md border border-[--table-header-bg] bg-[--breadcrumb-bg] shadow-sm h">
          <div className="p-2 bg-white rounded-md">
            <p className="whitespace-pre-line font-medium">{processedValue}</p>
          </div>
        </div>
      </div>
    );
  };

  const renderKeywordsSection = () => {
    return (
      <div className="flex flex-col gap-1 p-1 border border-table-header-bg rounded-md">
        {renderSectionHeader(t("project.descriptionStep.sections.keywords"))}

        <div className="flex flex-col gap-1 p-1 rounded-md border border-[--table-header-bg] bg-[--breadcrumb-bg] shadow-sm">
          <div className="flex flex-wrap gap-2 p-1">
            {formData.palavras_chave && formData.palavras_chave.length > 0 ? (
              formData.palavras_chave.map((tag: TagItem) => (
                <div
                  key={tag.tag_id}
                  className="inline-flex items-center rounded-lg px-3 py-1.5 text-sm bg-blue-100 text-blue-800"
                >
                  <span>{tag.descricao}</span>
                </div>
              ))
            ) : (
              <div className="p-3 text-center text-text-secondary italic">
                {t("project.descriptionStep.noTagsAdded")}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="col-span-1 md:col-span-2">{renderKeywordsSection()}</div>

      <div className="col-span-1 md:col-span-2">
        {renderTextSection(
          "descricao",
          t("project.descriptionStep.sections.description"),
        )}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          {renderTextSection(
            "motivacao",
            t("project.descriptionStep.sections.motivation"),
          )}
        </div>

        <div>
          {renderTextSection(
            "originalidade",
            t("project.descriptionStep.sections.originality"),
          )}
        </div>

        <div>
          {renderTextSection(
            "aplicabilidade",
            t("project.descriptionStep.sections.applicability"),
          )}
        </div>

        <div>
          {renderTextSection(
            "relevancia",
            t("project.descriptionStep.sections.relevance"),
          )}
        </div>

        <div>
          {renderTextSection(
            "razoabilidade_custos",
            t("project.descriptionStep.sections.costReasonability"),
          )}
        </div>

        <div>
          {renderTextSection(
            "pesquisa_correlata",
            t("project.descriptionStep.sections.relatedResearch"),
          )}
        </div>
      </div>
    </div>
  );
};

export default DescriptionView;
