import { EntityTransformer, EntityTransformers } from "features/crud/types";

export const entityTransformers: EntityTransformers = {};

export const registerEntityTransformer = (
  entityType: string,
  transformer: EntityTransformer,
) => {
  entityTransformers[entityType] = transformer;
};
