// src/common/components/DatePicker/index.tsx
import { useState, useEffect } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MaskedInput from "react-text-mask";
import { ptBR } from "date-fns/locale";
import { getYear, getMonth } from "date-fns";
import "./styles.css";

type VariantType = "default" | "light";
type HeaderType = "default" | "dropdown" | "year";

interface DatePickerProps {
  label?: string;
  selectedDate?: Date | null;
  onChange: (date: Date | null) => void;
  onBlur?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => void;
  format?: "dd/MM/yyyy" | "MM/yyyy" | "yyyy";
  placeholder?: string;
  disabled?: boolean;
  error?: string;
  required?: boolean;
  variant?: VariantType;
  showMonthYearPicker?: boolean;
  className?: string;
  minDate?: Date;
  maxDate?: Date;
  popperPlacement?: "bottom-start" | "bottom-end" | "top-start" | "top-end";
  headerType?: HeaderType;
  popperOffset?: [number, number];
}

const variants: Record<VariantType, string> = {
  default: "border-primary border-2 text-primary",
  light: "border-primary border text-text-secondary",
};

const range = (start: number, end: number, step = 1): number[] => {
  const result: number[] = [];
  for (let i = start; i <= end; i += step) {
    result.push(i);
  }
  return result;
};

const months = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

const DatePicker = ({
  label,
  selectedDate,
  onChange,
  onBlur,
  onKeyDown,
  format = "dd/MM/yyyy",
  placeholder = "Selecione uma data",
  disabled = false,
  error,
  required = false,
  variant = "default",
  showMonthYearPicker = false,
  className = "",
  minDate,
  maxDate,
  popperPlacement = "bottom-start",
  headerType = "default",
  popperOffset = [0, 8],
}: DatePickerProps) => {
  const [date, setDate] = useState<Date | null>(selectedDate ?? null);
  const years = range(1950, getYear(new Date()) + 10);

  useEffect(() => {
    setDate(selectedDate ?? null);
  }, [selectedDate]);

  const handleChange = (date: Date | null) => {
    setDate(date);
    onChange(date);
  };

  const mask =
    format === "dd/MM/yyyy"
      ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]
      : format === "MM/yyyy"
        ? [/\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]
        : [/\d/, /\d/, /\d/, /\d/];

  const defaultCustomHeader = ({
    date,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }: {
    date: Date;
    decreaseMonth: () => void;
    increaseMonth: () => void;
    prevMonthButtonDisabled: boolean;
    nextMonthButtonDisabled: boolean;
  }) => (
    <div className="flex justify-between items-center px-2 py-1 bg-gray-100 rounded-md">
      <button
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
        className="px-2 py-1 rounded bg-gray-300 hover:bg-gray-400 disabled:opacity-50"
      >
        {"<"}
      </button>
      <span className="text-sm font-semibold">
        {new Intl.DateTimeFormat("pt-BR", {
          month: "long",
          year: "numeric",
        }).format(date)}
      </span>
      <button
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
        className="px-2 py-1 rounded bg-gray-300 hover:bg-gray-400 disabled:opacity-50"
      >
        {">"}
      </button>
    </div>
  );

  const dropdownCustomHeader = ({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }: {
    date: Date;
    changeYear: (year: number) => void;
    changeMonth: (month: number) => void;
    decreaseMonth: () => void;
    increaseMonth: () => void;
    prevMonthButtonDisabled: boolean;
    nextMonthButtonDisabled: boolean;
  }) => (
    <div className="flex justify-between items-center px-2 py-1 bg-gray-100 rounded-md">
      <button
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
        className="px-2 py-1 rounded bg-gray-300 hover:bg-gray-400 disabled:opacity-50"
      >
        {"<"}
      </button>
      <div className="flex items-center space-x-2">
        <select
          value={getYear(date)}
          onChange={({ target: { value } }) => changeYear(Number(value))}
          className="px-2 py-1 rounded border border-gray-300"
        >
          {years.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        {format !== "yyyy" && (
          <select
            value={months[getMonth(date)]}
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
            className="px-2 py-1 rounded border border-gray-300"
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        )}
      </div>
      <button
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
        className="px-2 py-1 rounded bg-gray-300 hover:bg-gray-400 disabled:opacity-50"
      >
        {">"}
      </button>
    </div>
  );

  const yearOnlyCustomHeader = ({
    date,
    decreaseYear,
    increaseYear,
    prevYearButtonDisabled,
    nextYearButtonDisabled,
  }: {
    date: Date;
    decreaseYear: () => void;
    increaseYear: () => void;
    prevYearButtonDisabled: boolean;
    nextYearButtonDisabled: boolean;
  }) => (
    <div className="flex justify-between items-center px-2 py-1 bg-gray-100 rounded-md">
      <button
        onClick={decreaseYear}
        disabled={prevYearButtonDisabled}
        className="px-2 py-1 rounded bg-gray-300 hover:bg-gray-400 disabled:opacity-50"
      >
        {"<"}
      </button>
      <span className="text-sm font-semibold">{getYear(date)}</span>
      <button
        onClick={increaseYear}
        disabled={nextYearButtonDisabled}
        className="px-2 py-1 rounded bg-gray-300 hover:bg-gray-400 disabled:opacity-50"
      >
        {">"}
      </button>
    </div>
  );

  const getCustomHeader = () => {
    if (headerType === "dropdown") return dropdownCustomHeader;
    if (headerType === "year") return yearOnlyCustomHeader;
    if (format === "yyyy" && headerType === "default")
      return yearOnlyCustomHeader;
    return defaultCustomHeader;
  };

  return (
    <div className="w-full flex flex-col">
      {label && (
        <label className="block text-sm font-medium text-primary">
          {label}
          {required && <span className="ml-1 text-primary">*</span>}
        </label>
      )}
      <ReactDatePicker
        minDate={minDate}
        maxDate={maxDate}
        selected={date}
        onChange={handleChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        showMonthYearPicker={
          showMonthYearPicker || format === "yyyy" || format === "MM/yyyy"
        }
        showYearPicker={format === "yyyy"}
        dateFormat={format}
        placeholderText={placeholder}
        disabled={disabled}
        locale={ptBR}
        popperPlacement={popperPlacement}
        renderCustomHeader={getCustomHeader()}
        className={`w-full p-1.5 rounded-md text-text shadow-sm focus:ring-primary focus:border-primary text-sm ${variants[variant]} ${className}`}
        customInput={
          <MaskedInput
            mask={mask}
            guide={false}
            placeholderChar={"\u2000"}
            keepCharPositions={true}
          />
        }
        popperModifiers={[
          {
            name: "offset",
            options: {
              offset: popperOffset,
            },
            fn: (args) => {
              return {
                ...args,
                x: args.x + popperOffset[0],
                y: args.y + popperOffset[1],
              };
            },
          },
          {
            name: "preventOverflow",
            options: {
              padding: 8,
            },
            fn: (args) => {
              return args;
            },
          },
        ]}
        calendarClassName="year-picker-calendar"
      />
      {error && <span className="mt-1 text-xs text-error">{error}</span>}
    </div>
  );
};

export default DatePicker;
