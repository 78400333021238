// src/features/selic/api/index.ts
import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "config/baseQuery";
import {
  SelicMonitoringResponse,
  SelicMonitoringParams,
  SelicTaxaMesReferenciaParams,
  SelicTaxaMesReferenciaResponse,
} from "features/selic/types";

export const selicApi = createApi({
  reducerPath: "selicApi",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["Selic"],
  endpoints: (builder) => ({
    // getDadosSelicAnualizado {{url_api}}/selic/resumoanualizado?empresa_id=3&conta_id=1&ano=2024
    getAnnualizedSelicData: builder.query<
      SelicMonitoringResponse,
      SelicMonitoringParams
    >({
      query: (params) => ({
        url: "selic/resumoanualizado",
        method: "GET",
        params,
      }),
      keepUnusedDataFor: 0,
      providesTags: ["Selic"],
    }),
    // getTaxaSelicMesReferencia {{url_api}}/selic?mes={{mes}}&ano={{ano}} GET
    getSelicTaxaMesReferencia: builder.query<
      SelicTaxaMesReferenciaResponse,
      SelicTaxaMesReferenciaParams
    >({
      query: (params) => ({
        url: "selic",
        method: "GET",
        params,
      }),
      keepUnusedDataFor: 0,
      providesTags: ["Selic"],
    }),
  }),
});

export const {
  useGetAnnualizedSelicDataQuery,
  useGetSelicTaxaMesReferenciaQuery,
} = selicApi;
