// src/common/components/Dashboard/index.tsx
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetUserQuery } from "features/user/api";
import { RootState } from "store";
import { ApiErrorResponse } from "common/types";
import { setUser } from "features/auth/slices/authSlice";
import Sidebar from "common/components/Dashboard/components/Sidebar";
import Header from "common/components/Dashboard/components/Header";
import UserCompanyAccessModal from "features/auth/components/Login/components/UserCompanyAccessModal";
import { setIsCompanyModalOpen } from "features/user/slices/selectedCompanySlice";
import LogoutModal from "features/auth/components/Login/components/LogoutModal";
import { setIsLogoutModalOpen } from "features/auth/slices/authSlice";
import { useTranslation } from "react-i18next";
import LoadingScreen from "common/components/LoadingScreen";
import ErrorAlert from "common/components/ErrorAlert";
import { useSidebarState } from "common/components/Dashboard/hooks/useSidebarState";
import { useLocation } from "react-router-dom";
import { clearCurrentView } from "common/components/Breadcrumb/slices/breadcrumbSlice";

const Dashboard = ({ children }: React.PropsWithChildren) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    isDesktopSidebarOpen,
    isMobileSidebarOpen,
    isDesktop,
    handleSidebarOpen,
    handleCloseMobileSidebar,
  } = useSidebarState();

  const { isCompanyModalOpen } = useSelector(
    (state: RootState) => state.selectedCompany,
  );
  const { isLogoutModalOpen } = useSelector((state: RootState) => state.auth);
  const { isChangingCompany } = useSelector(
    (state: RootState) => state.selectedCompany,
  );

  const {
    data: userData,
    isLoading: isLoadingUser,
    isError: isErrorUser = true,
    error: errorUser,
  } = useGetUserQuery(undefined);

  useEffect(() => {
    if (userData?.data) {
      dispatch(
        setUser({
          ...userData.data,
          perfil: userData.data.perfil,
        }),
      );
    }
  }, [userData, dispatch]);

  useEffect(() => {
    dispatch(clearCurrentView());
  }, [location.pathname, dispatch]);

  const handleCloseCompanyModal = useCallback(() => {
    dispatch(setIsCompanyModalOpen(false));
  }, [dispatch]);

  const handleCloseLogoutModal = useCallback(() => {
    dispatch(setIsLogoutModalOpen(false));
  }, [dispatch]);

  if (isLoadingUser || isChangingCompany) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-light-bg">
        <LoadingScreen />
      </div>
    );
  }

  if (isErrorUser) {
    const errorMessage =
      errorUser instanceof Error
        ? errorUser.message
        : ((errorUser as ApiErrorResponse)?.mensagem ??
          t("dashboard.errors.fetchUser"));

    return (
      <div className="flex items-center justify-center min-h-screen bg-light-bg">
        <ErrorAlert className="text-center" message={errorMessage} />
      </div>
    );
  }

  return (
    <>
      <div className="min-h-screen">
        <div className="flex h-screen overflow-hidden bg-light-bg">
          <Sidebar
            isOpen={isDesktop ? isDesktopSidebarOpen : isMobileSidebarOpen}
            isDesktop={isDesktop}
            onClose={handleCloseMobileSidebar}
          />

          <div className="flex-1 flex flex-col min-w-0">
            <Header onOpen={handleSidebarOpen} />

            <main className="flex flex-col flex-1 overflow-y-auto px-2 pb-2">
              {children}
            </main>
          </div>

          <UserCompanyAccessModal
            isOpen={isCompanyModalOpen}
            onClose={handleCloseCompanyModal}
            isLoadingUser={isLoadingUser}
          />
          <LogoutModal
            isOpen={isLogoutModalOpen}
            onClose={handleCloseLogoutModal}
          />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
