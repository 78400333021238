// src/features/auth/components/Login/components/UserCompanyAccessModal/utils/userCompanyAccessUtil.ts
import { CompanySelectionItem } from "../types";

export const SELECTED_COMPANY_KEY = "selectedCompany";
export const COMPANY_CODE_HEADER_KEY = "x-codigo-empresa";
export const REMEMBER_ME_KEY = "rememberMe";

export const setSelectedCompanyStorage = (company: CompanySelectionItem) => {
  localStorage.setItem(SELECTED_COMPANY_KEY, JSON.stringify(company));
  localStorage.setItem(COMPANY_CODE_HEADER_KEY, company.empresa_id.toString());
};

export const getSelectedCompanyStorage = (): CompanySelectionItem | null => {
  const companyJson = localStorage.getItem(SELECTED_COMPANY_KEY);
  try {
    return companyJson ? JSON.parse(companyJson) : null;
  } catch (error) {
    console.error("Error parsing stored company:", error);
    return null;
  }
};

export const clearSelectedCompanyStorage = () => {
  localStorage.removeItem(SELECTED_COMPANY_KEY);
  localStorage.removeItem(COMPANY_CODE_HEADER_KEY);
};

export const setupCompanyStorageListener = () => {
  window.addEventListener("beforeunload", () => {
    const rememberMe = localStorage.getItem(REMEMBER_ME_KEY);
    if (!rememberMe) {
      clearSelectedCompanyStorage();
    }
  });
};
