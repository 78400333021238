// src/common/components/ErrorAlert/index.tsx
import { X } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useEffect, useRef } from "react";

interface ErrorAlertProps {
  message: string;
  onDismiss?: () => void;
  className?: string;
}

const ErrorAlert = ({
  message,
  onDismiss,
  className = "",
}: ErrorAlertProps) => {
  const { t } = useTranslation();
  const alertRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (alertRef.current) {
      alertRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      alertRef.current.focus();
    }
  }, [message]);

  return (
    <div
      ref={alertRef}
      role="alert"
      tabIndex={-1}
      className={`flex justify-between items-center relative rounded-md bg-error-bg p-4 text-error outline-none ${className}`}
    >
      {message}
      {onDismiss && (
        <button
          type="button"
          onClick={onDismiss}
          className="hover:text-error-hover"
          aria-label={t("common.error.close")}
        >
          <X size={16} />
        </button>
      )}
    </div>
  );
};

export default ErrorAlert;
