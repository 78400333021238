// src/features/user/slices/selectedCompanySlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CompanySelectionItem } from "features/auth/components/Login/components/UserCompanyAccessModal/types";

interface SelectedCompanyState {
  selectedCompany: CompanySelectionItem | null;
  isCompanyModalOpen: boolean;
  isChangingCompany: boolean;
}

const getInitialCompany = (): CompanySelectionItem | null => {
  try {
    const storedCompany = localStorage.getItem("selectedCompany");
    return storedCompany ? JSON.parse(storedCompany) : null;
  } catch (error) {
    console.error("Error parsing stored company:", error);
    return null;
  }
};

const initialState: SelectedCompanyState = {
  selectedCompany: getInitialCompany(),
  isCompanyModalOpen: false,
  isChangingCompany: false,
};

const selectedCompanySlice = createSlice({
  name: "selectedCompany",
  initialState,
  reducers: {
    setSelectedCompany: (
      state,
      action: PayloadAction<CompanySelectionItem | null>,
    ) => {
      state.selectedCompany = action.payload;
      if (action.payload) {
        localStorage.setItem("selectedCompany", JSON.stringify(action.payload));
      }
    },
    setIsCompanyModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isCompanyModalOpen = action.payload;
    },
    clearSelectedCompany: (state) => {
      state.selectedCompany = null;
      state.isCompanyModalOpen = false;
      localStorage.removeItem("selectedCompany");
    },
    startCompanyChange: (state) => {
      state.isChangingCompany = true;
    },
    finishCompanyChange: (state) => {
      state.isChangingCompany = false;
    },
  },
});

export const {
  setSelectedCompany,
  setIsCompanyModalOpen,
  clearSelectedCompany,
  startCompanyChange,
  finishCompanyChange,
} = selectedCompanySlice.actions;
export default selectedCompanySlice.reducer;
