// src/router/routes/protected.routes.ts
import { lazy } from "react";
import { RouteType } from "router/types";

const protectedRoutes: Array<RouteType> = [
  {
    key: "convite",
    path: "/convite/aceitar/:uuid",
    component: lazy(() => import("features/invite/components/InvitePage")),
    authority: "public",
    meta: { layout: "blank", pageContainerType: "gutterless" },
  },
  {
    key: "cuca-squad",
    path: "/cuca/squad",
    component: lazy(() => import("features/cuca/components/CucaSquad")),
    authority: "public",
    meta: { layout: "blank", pageContainerType: "gutterless" },
  },
  {
    key: "draft-projects",
    path: "/projetos/em-elaboracao",
    component: lazy(
      () =>
        import(
          "features/project/components/PDIProject/components/DraftProjects"
        ),
    ),
    authority: "authenticated",
    meta: {
      layout: "default",
    },
  },
  {
    key: "completed-projects",
    path: "/projetos/finalizados",
    component: lazy(
      () =>
        import(
          "features/project/components/PDIProject/components/CompletedProjects"
        ),
    ),
    authority: "authenticated",
    meta: {
      layout: "default",
    },
  },
  {
    key: "user-profile",
    path: "/meu-perfil",
    component: lazy(() => import("features/user/components/UserProfile")),
    authority: "authenticated",
    meta: {
      layout: "default",
    },
  },
];

export default protectedRoutes;
