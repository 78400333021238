import { useTranslation } from "react-i18next";
import { ChevronUp, ChevronDown, X } from "lucide-react";
import Button from "common/components/Button";
import {
  useSelect,
  SelectOption,
} from "common/components/Select/hooks/useSelect";

export type VariantType = "default" | "light" | "none";

interface SelectVariant {
  border: string;
  label: string;
  bg: string;
}

const variants: Record<VariantType, SelectVariant> = {
  default: {
    border: "border-primary border-2",
    label: "text-primary",
    bg: "bg-white",
  },
  light: {
    border: "border-primary border",
    label: "text-text-secondary",
    bg: "bg-white",
  },
  none: {
    border: "transparent",
    label: "",
    bg: "transparent",
  },
};

interface KeyboardShortcut {
  key: string;
  value: string;
}

export interface SelectProps {
  label?: string;
  options: SelectOption[];
  value?: string;
  onChange: (value: string) => void;
  className?: string;
  placeholder?: string;
  isLoading?: boolean;
  disabled?: boolean;
  required?: boolean;
  searchable?: boolean;
  allowCreate?: boolean;
  onCreateOption?: (inputValue: string) => void;
  error?: string;
  variant?: VariantType;
  isOpen?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  forceDropdownOpen?: boolean;
  allowClear?: boolean;
  showNoneOption?: boolean;
  noneOptionLabel?: string;
  keyboardShortcuts?: KeyboardShortcut[];
  onEnterPress?: () => void;
}

const Select = ({
  required,
  label,
  options,
  value = "",
  onChange,
  className = "",
  placeholder,
  disabled = false,
  isLoading = false,
  searchable = false,
  allowCreate = false,
  onCreateOption,
  error,
  variant = "default",
  isOpen: controlledIsOpen,
  onOpen,
  onClose,
  forceDropdownOpen = false,
  allowClear = false,
  showNoneOption = false,
  noneOptionLabel,
  keyboardShortcuts,
  onEnterPress,
}: SelectProps) => {
  const { t } = useTranslation();
  const currentVariant = variants[variant];

  const allOptions = showNoneOption
    ? [
        { value: "", label: noneOptionLabel || t("select.noneOption") },
        ...options,
      ]
    : options;

  const {
    isOpen: uncontrolledIsOpen,
    setIsOpen: setUncontrolledIsOpen,
    searchTerm,
    setSearchTerm,
    selectRef,
    filteredOptions,
    showCreateOption,
    selectedOption,
    handleSelect,
    handleCreateOption,
  } = useSelect({
    value,
    options: allOptions,
    onChange,
    onCreateOption: allowCreate ? onCreateOption : undefined,
    isOpen: controlledIsOpen,
    onClose,
  });

  const isOpen = controlledIsOpen ?? uncontrolledIsOpen;
  const setIsOpen = (open: boolean) => {
    if (controlledIsOpen !== undefined) {
      open ? onOpen?.() : onClose?.();
    } else {
      setUncontrolledIsOpen(open);
    }
  };

  const isDisabled = disabled || !!error;
  const isEmpty = !value || (typeof value === "string" && value.trim() === "");
  const showError = error || (required && isEmpty);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (isDisabled) return;

    if (keyboardShortcuts) {
      const shortcut = keyboardShortcuts.find(
        (s) => s.key.toLowerCase() === e.key.toLowerCase(),
      );

      if (shortcut) {
        e.preventDefault();
        onChange(shortcut.value);
        setIsOpen(false);
        return;
      }
    }

    if (e.key === "Enter" && onEnterPress && !isOpen) {
      e.preventDefault();
      onEnterPress();
    }
  };

  return (
    <div ref={selectRef} className={`w-full ${className}`}>
      {label && (
        <label
          htmlFor={`dropdown-${label}`}
          className={`block text-sm font-medium ${currentVariant.label}`}
        >
          {label}
          {required && <span className="ml-1 text-primary">*</span>}
        </label>
      )}
      <div className="relative">
        <input
          type="text"
          id={`hidden-${label}`}
          value={value}
          onChange={(e) => setSearchTerm(e.target.value)}
          required={required}
          className="sr-only"
          tabIndex={-1}
          aria-hidden="true"
        />

        <div className="relative">
          {searchable && isOpen ? (
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className={`w-full rounded-lg p-1 ${currentVariant.border} ${
                currentVariant.bg
              } ${isDisabled ? "opacity-50 cursor-not-allowed" : ""}`}
              placeholder={t("select.search.placeholder")}
              aria-label={t("select.search.placeholder")}
              disabled={isDisabled}
              autoFocus
              aria-required={required}
              aria-invalid={showError ? "true" : "false"}
              onKeyDown={handleKeyDown}
            />
          ) : (
            <div className="relative">
              <button
                id={`dropdown-${label}`}
                type="button"
                onClick={() => !isDisabled && setIsOpen(!isOpen)}
                disabled={isDisabled}
                className={`flex w-full items-center justify-between rounded-lg p-1.5 text-left ${
                  currentVariant.border
                } ${currentVariant.bg} ${
                  isDisabled ? "opacity-50 cursor-not-allowed" : ""
                }`}
                aria-haspopup="listbox"
                aria-expanded={isOpen}
                aria-label={t("select.aria.toggle")}
                aria-invalid={showError ? "true" : "false"}
                aria-required={required}
                onKeyDown={handleKeyDown}
              >
                <span
                  className={`block text-sm truncate max-w-[calc(100%-2rem)] ${
                    !selectedOption?.label ? "text-text-secondary" : "text-text"
                  }`}
                >
                  {selectedOption?.label ||
                    placeholder ||
                    t("select.defaultPlaceholder")}
                </span>
                <div className="flex items-center gap-2">
                  {allowClear && value && !isDisabled && (
                    <div
                      role="button"
                      tabIndex={0}
                      onClick={(e) => {
                        e.stopPropagation();
                        onChange("");
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                          e.preventDefault();
                          e.stopPropagation();
                          onChange("");
                        }
                      }}
                      className="hover:bg-gray-100 rounded-full cursor-pointer"
                      aria-label={t("select.clear")}
                    >
                      <X className="w-4 h-4 text-gray-400" />
                    </div>
                  )}
                  <div className="flex items-center justify-center w-4 h-4 rounded-full bg-primary text-white">
                    {isOpen ? (
                      <ChevronUp className="w-3 h-3" />
                    ) : (
                      <ChevronDown className="w-3 h-3" />
                    )}
                  </div>
                </div>
              </button>
            </div>
          )}
        </div>

        {(isOpen || forceDropdownOpen) && !isDisabled && !controlledIsOpen && (
          <ul
            className="absolute z-10 w-full mt-1 bg-white border rounded-lg shadow-lg max-h-60 overflow-auto focus:outline-none"
            role="listbox"
            aria-label={t("select.aria.select")}
          >
            {isLoading ? (
              <li className="p-1.5 text-sm text-text">{t("select.loading")}</li>
            ) : (
              <>
                {filteredOptions.length === 0 ? (
                  <li className="p-1.5 text-sm text-text">
                    {searchTerm.length < 3
                      ? t("select.search.minLength")
                      : t("select.noOptions")}
                  </li>
                ) : (
                  filteredOptions.map((option) => (
                    <li
                      key={option.value}
                      className={`p-1.5 text-sm cursor-pointer hover:bg-primary/10 truncate ${
                        value === option.value
                          ? "bg-primary/10 text-primary"
                          : "text-text"
                      }`}
                      onClick={() => handleSelect(option.value)}
                      role="option"
                      aria-selected={value === option.value}
                    >
                      {option.label}
                    </li>
                  ))
                )}
                {showCreateOption && (
                  <li className="p-1.5 border-t">
                    <Button
                      onClick={handleCreateOption}
                      className="w-fit flex items-center justify-center gap-2 text-sm"
                      aria-label={t("select.aria.createOption")}
                    >
                      {t("select.actions.add")}
                    </Button>
                  </li>
                )}
              </>
            )}
          </ul>
        )}
      </div>
      {error && <span className="mt-1 text-xs text-error">{error}</span>}
    </div>
  );
};

export default Select;
