import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { MenuItem } from "features/user/types";

export interface NavItem {
  name: string;
  path: string;
  key: string;
  parentKey?: string;
  subItems?: NavItem[];
  isNavigable: boolean;
  icon?: string;
  menuId?: number;
}

interface UseSidebarNavigationReturn {
  navItems: NavItem[];
  openDropdowns: Record<string, boolean>;
  toggleDropdown: (item: NavItem) => void;
  getNavLinkClass: (props: { isActive: boolean }) => string;
  isDropdownOpen: (key: string) => boolean;
}

export const useSidebarNavigation = (): UseSidebarNavigationReturn => {
  const [openDropdowns, setOpenDropdowns] = useState<Record<string, boolean>>(
    {},
  );

  const menuProfile = useSelector(
    (state: RootState) => state.auth.user?.perfil.perfil_menu,
  );

  const buildNavItems = (menuItems: MenuItem[] = []): NavItem[] => {
    const menuMap = new Map<number, MenuItem>();
    menuItems.forEach((item) => menuMap.set(item.menu_id, item));

    const buildNavItemRecursive = (item: MenuItem): NavItem => {
      const subItems = menuItems
        .filter((subItem) => subItem.menu_superior_id === item.menu_id)
        .map((subItem) => buildNavItemRecursive(subItem));

      const navItem: NavItem = {
        name: item.nome_menu,
        path: item.caminho_menu || "#",
        key: `menu-${item.menu_id}`,
        parentKey: item.menu_superior_id
          ? `menu-${item.menu_superior_id}`
          : undefined,
        isNavigable: !!item.caminho_menu,
        icon: item.icone_menu || undefined,
        menuId: item.menu_id,
      };

      if (subItems.length > 0) {
        navItem.subItems = subItems;
      }

      return navItem;
    };

    return menuItems
      .filter((item) => !item.menu_superior_id)
      .map((item) => buildNavItemRecursive(item));
  };

  const toggleDropdown = (item: NavItem): void => {
    setOpenDropdowns((prev) => {
      const navItems = buildNavItems(menuProfile);

      // Helper function to get all descendant keys of a given item
      const getDescendantKeys = (items: NavItem[]): string[] => {
        let keys: string[] = [];
        items.forEach((item) => {
          keys.push(item.key);
          if (item.subItems) {
            keys = [...keys, ...getDescendantKeys(item.subItems)];
          }
        });
        return keys;
      };

      // Helper function to get all sibling keys at the same level recursively
      const getAllSiblingKeys = (
        items: NavItem[],
        targetKey: string,
        parentKey?: string,
      ): string[] => {
        let siblingKeys: string[] = [];

        // Get direct siblings at this level
        const siblings = items.filter(
          (item) => item.key !== targetKey && item.parentKey === parentKey,
        );
        siblingKeys = siblings.map((item) => item.key);

        // Recursively get siblings from subItems
        items.forEach((item) => {
          if (item.subItems) {
            siblingKeys = [
              ...siblingKeys,
              ...getAllSiblingKeys(item.subItems, targetKey, item.key),
            ];
          }
        });

        return siblingKeys;
      };

      // Find the clicked item and its ancestors
      const findItemAndAncestors = (
        items: NavItem[],
        targetKey: string,
        parentKeys: string[] = [],
      ): { item: NavItem | null; ancestorKeys: string[] } => {
        for (const curr of items) {
          if (curr.key === targetKey) {
            return { item: curr, ancestorKeys: parentKeys };
          }
          if (curr.subItems) {
            const result = findItemAndAncestors(curr.subItems, targetKey, [
              ...parentKeys,
              curr.key,
            ]);
            if (result.item) {
              return result;
            }
          }
        }
        return { item: null, ancestorKeys: [] };
      };

      const { item: clickedItem, ancestorKeys } = findItemAndAncestors(
        navItems,
        item.key,
      );

      // Create new state starting with previous state
      const newState = { ...prev };

      if (clickedItem) {
        // Toggle the clicked item
        const newValue = !prev[clickedItem.key];
        newState[clickedItem.key] = newValue;

        // If opening the item, close all siblings at all levels and their descendants
        if (newValue) {
          const siblingKeys = getAllSiblingKeys(
            navItems,
            clickedItem.key,
            clickedItem.parentKey,
          );

          siblingKeys.forEach((siblingKey) => {
            // Find the sibling item to get its descendants
            const sibling = findItemAndAncestors(navItems, siblingKey).item;
            if (sibling) {
              // Close the sibling
              newState[siblingKey] = false;
              // Close all descendants of the sibling
              if (sibling.subItems) {
                const descendantKeys = getDescendantKeys(sibling.subItems);
                descendantKeys.forEach((descendantKey) => {
                  newState[descendantKey] = false;
                });
              }
            }
          });
        }

        // If we're closing an item, also close all its descendants
        if (!newValue && clickedItem.subItems) {
          const descendantKeys = getDescendantKeys(clickedItem.subItems);
          descendantKeys.forEach((descendantKey) => {
            newState[descendantKey] = false;
          });
        }

        // Keep ancestors open
        ancestorKeys.forEach((ancestorKey) => {
          newState[ancestorKey] = true;
        });
      }

      return newState;
    });
  };

  const getNavLinkClass = ({ isActive }: { isActive: boolean }): string => {
    return `flex items-center py-2 px-4 text-base ${
      isActive ? "text-primary font-medium" : "text-text hover:text-primary"
    } transition-colors`;
  };

  const isDropdownOpen = (key: string): boolean => {
    return !!openDropdowns[key];
  };

  const navItems = buildNavItems(menuProfile);

  return {
    navItems,
    openDropdowns,
    toggleDropdown,
    getNavLinkClass,
    isDropdownOpen,
  };
};
