import { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "common/components/Modal";
import Button from "common/components/Button";
import Input from "common/components/Input";

interface InviteUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  onInvite: (data: { name: string; email: string }) => void;
  projectName: string;
  roleName: string;
  isSubmitting: boolean;
}

const InviteUserModal = ({
  isOpen,
  onClose,
  onInvite,
  projectName,
  roleName,
  isSubmitting,
}: InviteUserModalProps) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState<{ name?: string; email?: string }>({});

  const validateForm = () => {
    const newErrors: { name?: string; email?: string } = {};
    let isValid = true;

    if (!name.trim()) {
      newErrors.name = "Nome é obrigatório";
      isValid = false;
    }

    if (!email.trim()) {
      newErrors.email = "Email é obrigatório";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Email inválido";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      onInvite({ name, email });
    }
  };

  const handleClose = () => {
    setName("");
    setEmail("");
    setErrors({});
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title={t("invite.title")}
      closeOnBackdropClick={false}
      size="md"
      footer={
        <div className="flex justify-end gap-4">
          <Button variant="link" onClick={handleClose} disabled={isSubmitting}>
            {t("button.cancel")}
          </Button>
          <Button onClick={handleSubmit} loading={isSubmitting}>
            {t("button.send")}
          </Button>
        </div>
      }
    >
      <div className="p-4">
        <h2 className="text-lg font-semibold text-primary mb-4">
          {t("invite.title")}
        </h2>

        <div className="mb-4">
          <Input
            label={t("input.name.label")}
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder={t("invite.namePlaceholder")}
            disabled={isSubmitting}
            error={errors.name}
            required
          />
        </div>

        <div className="mb-4">
          <Input
            label={t("email.label")}
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={t("email.placeholder")}
            disabled={isSubmitting}
            error={errors.email}
            required
          />
        </div>

        <div className="mb-4">
          <Input
            label={t("project.label")}
            value={projectName}
            readOnly
            variant="light"
          />
        </div>

        <div className="mb-4">
          <Input
            label={t("role.label")}
            value={roleName}
            readOnly
            variant="light"
          />
        </div>
      </div>
    </Modal>
  );
};

export default InviteUserModal;
