// src/features/auth/slices/authSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoggedUserData } from "features/user/types";

interface AuthState {
  isAuthenticated: boolean;
  loading: boolean;
  loginError: string | null;
  passwordChangeError: string | null;
  resetPasswordError: string | null;
  user: LoggedUserData | null;
  isLogoutModalOpen: boolean;
}

const initialState: AuthState = {
  isAuthenticated: !!localStorage.getItem("accessToken"),
  loading: false,
  loginError: null,
  passwordChangeError: null,
  resetPasswordError: null,
  user: null,
  isLogoutModalOpen: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    logout: () => {
      // Clear localStorage
      localStorage.removeItem("accessToken");
      localStorage.removeItem("selectedCompany");
      localStorage.removeItem("x-codigo-empresa");

      // Reset all state properties
      return {
        ...initialState,
        isAuthenticated: false, // Explicitly set to false
        user: null,
        isLogoutModalOpen: false,
      };
    },
    setLoginError: (state, action: PayloadAction<string | null>) => {
      state.loginError = action.payload;
    },
    setPasswordChangeError: (state, action: PayloadAction<string | null>) => {
      state.passwordChangeError = action.payload;
    },
    setResetPasswordError: (state, action: PayloadAction<string | null>) => {
      state.resetPasswordError = action.payload;
    },
    setUser: (state, action: PayloadAction<LoggedUserData | null>) => {
      state.user = action.payload;
    },
    setIsLogoutModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isLogoutModalOpen = action.payload;
    },
  },
});

export const {
  setAuthenticated,
  logout,
  setLoginError,
  setPasswordChangeError,
  setResetPasswordError,
  setUser,
  setIsLogoutModalOpen,
} = authSlice.actions;

export default authSlice.reducer;
