// src/features/company/components/CompanyForm/utils/formUtils.ts
import { CrudMode } from "features/crud/types";

export const getDisabledFields = (mode: CrudMode): string[] => {
  const alwaysDisabled = [""];

  switch (mode) {
    case "edit":
      return [...alwaysDisabled, "grupo_empresarial", "cnpj", "segmento"];
    case "view":
      return [
        ...alwaysDisabled,
        "tipo_empresa_id",
        "segmento_id",
        "cnpj",
        "inscricao_estadual",
        "razao_social",
        "nome_fantasia",
        "cep",
        "endereco",
        "numero",
        "bairro",
        "municipio_ibge_id",
        "telefone_empresa",
        "pessoa_contato",
        "dominios_empresa",
      ];
    case "create":
    default:
      return alwaysDisabled;
  }
};
