// src/features/auth/components/Login/components/LogoutModal/index.tsx
import Modal from "common/components/Modal";
import { logout, setIsLogoutModalOpen } from "features/auth/slices/authSlice";
import { clearSelectedCompany } from "features/user/slices/selectedCompanySlice";
import { clearUserData } from "features/user/slices/userSlice";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "common/components/Button";

interface LogoutModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const LogoutModal = ({ isOpen, onClose }: LogoutModalProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = useCallback(() => {
    // Clear auth state and token
    dispatch(logout());

    // Clear selected company state and storage
    dispatch(clearSelectedCompany());

    // Clear user data
    dispatch(clearUserData());

    // Close modal
    dispatch(setIsLogoutModalOpen(false));

    // Navigate to login
    navigate("/");
  }, [navigate, dispatch]);

  const modalFooter = (
    <div className="flex items-center justify-center gap-2">
      <Button onClick={onClose} variant="link">
        {t("auth.logout.cancel")}
      </Button>
      <Button onClick={handleLogout} variant="primary">
        {t("auth.logout.confirm")}
      </Button>
    </div>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t("auth.logout.title")}
      size="fit"
      footer={modalFooter}
      closeOnBackdropClick={false}
    >
      <p className="text-center text-text-secondary">
        {t("auth.logout.message")}
      </p>
    </Modal>
  );
};

export default LogoutModal;
