import { useState, useCallback, useEffect, useMemo } from "react";
import {
  TableQueryParams,
  TableState,
} from "common/components/ServerTable/types/index";
import { DEFAULT_TABLE_STATE } from "common/components/ServerTable/constants/table/index";
import { useDebounce } from "common/components/ServerTable/hooks/useDebounce";

const MINIMUM_SEARCH_CHARACTERS = 3;
const DEBOUNCE_DELAY = 300;

export const useTable = (
  initialState: Partial<TableState> = {},
  onQueryChange: (params: TableQueryParams) => void,
) => {
  const [state, setState] = useState<TableState>(() => ({
    ...DEFAULT_TABLE_STATE,
    ...initialState,
  }));

  const debouncedOnQueryChange = useDebounce(onQueryChange, DEBOUNCE_DELAY);

  const handleSort = useCallback((column: string) => {
    setState((prev) => {
      const newDirection =
        prev.sortBy === column && prev.sortDirection === "asc" ? "desc" : "asc";

      return {
        ...prev,
        sortBy: column,
        sortDirection: newDirection,
        currentPage: 1,
      };
    });
  }, []);

  const handlePageChange = useCallback((page: number) => {
    setState((prev) => ({
      ...prev,
      currentPage: page,
    }));
  }, []);

  const handlePerPageChange = useCallback((perPage: number) => {
    setState((prev) => ({
      ...prev,
      perPage,
      currentPage: 1,
    }));
  }, []);

  const handleSearch = useCallback((query: string) => {
    setState((prev) => ({
      ...prev,
      searchQuery: query,
      currentPage: 1,
    }));
  }, []);

  const isValidSearch = useMemo(() => {
    if (!state.searchQuery) return true;
    return state.searchQuery.length >= MINIMUM_SEARCH_CHARACTERS;
  }, [state.searchQuery]);

  useEffect(() => {
    const queryParams: TableQueryParams = {
      page: state.currentPage,
      perPage: state.perPage,
      sortBy: state.sortBy,
      sortDirection: state.sortDirection,
      filtro_generico:
        state.searchQuery &&
        state.searchQuery.length >= MINIMUM_SEARCH_CHARACTERS
          ? state.searchQuery
          : "",
    };

    if (
      !state.searchQuery ||
      state.searchQuery.length >= MINIMUM_SEARCH_CHARACTERS
    ) {
      debouncedOnQueryChange(queryParams);
    }
  }, [state, debouncedOnQueryChange]);

  return {
    state,
    handleSort,
    handlePageChange,
    handlePerPageChange,
    handleSearch,
    isValidSearch,
  };
};
