// src/features/accountsPayable/api/index.ts
import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "config/baseQuery";
import {
  GetAccountsPayableResponse,
  GetAccountsPayableStatusResponse,
  GetAccountsPayableParams,
  GetAccountPayableByIdResponse,
  UpdateAccountPayableResponse,
  UpdateAccountPayableRequest,
  CancelAccountPayableResponse,
  CancelAccountPayableRequest,
  CancelPaymentAccountPayableResponse,
  CreateAccountPayableResponse,
  CreateAccountPayableRequest,
  UploadAccountPayableAttachmentResponse,
  UploadAccountPayableAttachmentRequest,
  GetAccountPayableAttachmentsResponse,
  DeleteAccountPayableAttachmentResponse,
} from "features/accountsPayable/types";

export const accountsPayableApi = createApi({
  reducerPath: "accountsPayableApi",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["AccountsPayable", "AccountsPayableStatus"],
  endpoints: (builder) => ({
    getAccountsPayableStatus: builder.query<
      GetAccountsPayableStatusResponse,
      void
    >({
      query: () => ({
        url: "contapagar/status",
        method: "GET",
      }),
      providesTags: ["AccountsPayableStatus"],
    }),

    getAccountsPayable: builder.query<
      GetAccountsPayableResponse,
      GetAccountsPayableParams
    >({
      query: (params) => ({
        url: "contapagar",
        method: "GET",
        params,
      }),
      keepUnusedDataFor: 0, // 0 to disable caching
      providesTags: (result) =>
        result
          ? [
              ...result.data.contas_pagar.map(({ contas_pagar_id }) => ({
                type: "AccountsPayable" as const,
                id: contas_pagar_id,
              })),
              { type: "AccountsPayable", id: "LIST" },
            ]
          : [{ type: "AccountsPayable", id: "LIST" }],
    }),

    getAccountPayableById: builder.query<GetAccountPayableByIdResponse, number>(
      {
        query: (id) => ({
          url: `contapagar/${id}`,
          method: "GET",
        }),
      },
    ),

    // edit
    updateAccountPayable: builder.mutation<
      UpdateAccountPayableResponse,
      UpdateAccountPayableRequest
    >({
      query: ({ contas_pagar_id, ...body }) => ({
        url: `contapagar/${contas_pagar_id}`,
        method: "PUT",
        body,
      }),
      // invalidatesTags: (_result, _error, { contas_pagar_id }) => [
      //   { type: "AccountsPayable", id: contas_pagar_id },
      //   { type: "AccountsPayable", id: "LIST" },
      // ],
    }),

    // pay
    payAccountPayable: builder.mutation<
      UpdateAccountPayableResponse,
      { contas_pagar_id: number; datahora_baixa_boleto: string }
    >({
      query: ({ contas_pagar_id, datahora_baixa_boleto }) => ({
        url: `contapagar/${contas_pagar_id}/baixar`,
        method: "POST",
        body: { datahora_baixa_boleto },
      }),
      invalidatesTags: (_result, error, { contas_pagar_id }) => {
        if (error) return [];
        return [
          { type: "AccountsPayable", id: contas_pagar_id },
          { type: "AccountsPayable", id: "LIST" },
        ];
      },
    }),

    // cancel bill
    cancelAccountPayable: builder.mutation<
      CancelAccountPayableResponse,
      CancelAccountPayableRequest
    >({
      query: ({ contas_pagar_id, ...body }) => ({
        url: `contapagar/${contas_pagar_id}/cancelar`,
        method: "POST",
        body,
      }),
      invalidatesTags: (_result, error, { contas_pagar_id }) => {
        if (error) return [];
        return [
          { type: "AccountsPayable", id: contas_pagar_id },
          { type: "AccountsPayable", id: "LIST" },
        ];
      },
    }),

    // cancel payment
    cancelPaymentAccountPayable: builder.mutation<
      CancelPaymentAccountPayableResponse,
      number
    >({
      query: (contas_pagar_id) => ({
        url: `contapagar/${contas_pagar_id}/cancelarbaixa`,
        method: "POST",
      }),
      invalidatesTags: (_result, _error, contas_pagar_id) => [
        { type: "AccountsPayable", id: contas_pagar_id },
        { type: "AccountsPayable", id: "LIST" },
      ],
    }),

    // create payable
    createAccountPayable: builder.mutation<
      CreateAccountPayableResponse,
      CreateAccountPayableRequest
    >({
      query: (body) => ({
        url: `contapagar`,
        method: "POST",
        body,
      }),
      invalidatesTags: (_result, _error, { conta_id }) => [
        { type: "AccountsPayable", id: conta_id },
        { type: "AccountsPayable", id: "LIST" },
      ],
    }),

    // uploadAnexoContasPagar
    uploadAccountPayableAttachment: builder.mutation<
      UploadAccountPayableAttachmentResponse,
      UploadAccountPayableAttachmentRequest
    >({
      query: ({ contas_pagar_id, ...body }) => ({
        url: `contapagar/${contas_pagar_id}/anexo`,
        method: "POST",
        body,
      }),
    }),

    // getAnexosContasPagar
    getAccountPayableAttachments: builder.query<
      GetAccountPayableAttachmentsResponse,
      number
    >({
      query: (contas_pagar_id) => ({
        url: `contapagar/${contas_pagar_id}/anexo`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
      providesTags: (result) =>
        result
          ? [
              ...result.data.anexos.map(({ conta_pagar_anexo_id }) => ({
                type: "AccountsPayable" as const,
                id: conta_pagar_anexo_id,
              })),
              { type: "AccountsPayable", id: "LIST" },
            ]
          : [{ type: "AccountsPayable", id: "LIST" }],
    }),

    // deleteAnexoContasPagar
    deleteAccountPayableAttachment: builder.mutation<
      DeleteAccountPayableAttachmentResponse,
      number
    >({
      query: (conta_pagar_anexo_id) => ({
        url: `contapagar/anexo/${conta_pagar_anexo_id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetAccountsPayableStatusQuery,
  useGetAccountsPayableQuery,
  useGetAccountPayableByIdQuery,
  useUpdateAccountPayableMutation,
  usePayAccountPayableMutation,
  useCancelAccountPayableMutation,
  useCancelPaymentAccountPayableMutation,
  useCreateAccountPayableMutation,
  useUploadAccountPayableAttachmentMutation,
  useGetAccountPayableAttachmentsQuery,
  useDeleteAccountPayableAttachmentMutation,
} = accountsPayableApi;
