// src/features/project/api/index.ts

import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "config/baseQuery";
import {
  ProjectParams,
  GetProjectsResponse,
  GetIdentificationResponse,
  GetProjectProductTypeResponse,
  GetProjectInnovationPhaseResponse,
  GetProjectSharingTypeResponse,
  GetProjectTrlResponse,
  SaveIdentificationResponse,
  GetProjectDescriptionResponse,
  SaveDescriptionResponse,
  AddTagResponse,
  DeleteTagResponse,
  TagItemResponse,
  GetEntitiesResponse,
  SaveEntitiesResponse,
  GetProjectEntityTypeResponse,
  GetExecutingCompaniesParams,
  GetExecutingCompaniesResponse,
  SaveExecutingCompanyResponse,
  SaveExecutingCompanyRequest,
  GetExecutingCompanyByIdResponse,
  GetProjectEntityTypeParams,
  GetProjectRolesListResponse,
  GetProjectRolesResponse,
  RemoveUserFromProjectResponse,
  AddUserToProjectResponse,
  AddUserToProjectRequest,
  InviteUserToProjectRequest,
  InviteUserToProjectResponse,
} from "features/project/types";
import {
  IdentificationFormState,
  DescriptionFormState,
  EntitiesFormState,
} from "features/project/slices/projectFormSlice";

export const projectApi = createApi({
  reducerPath: "projectApi",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["Project", "Tag"],
  endpoints: (builder) => ({
    // Listar Projeto
    getProjects: builder.query<GetProjectsResponse, ProjectParams>({
      query: (params) => ({
        url: "/projeto",
        params,
      }),
      providesTags: ["Project"],
      keepUnusedDataFor: 0,
    }),

    // Get project identification
    getProjectIdentification: builder.query<GetIdentificationResponse, number>({
      query: (projectId) => ({
        url: `/projeto/${projectId}/identificacao`,
      }),
      providesTags: (_result, _error, id) => [{ type: "Project", id }],
    }),

    // Get Tipo Produto options
    getProjectProductType: builder.query<GetProjectProductTypeResponse, void>({
      query: () => ({
        url: "/projeto/tipoproduto",
      }),
    }),

    // Get Fase Inovação options
    getProjectInnovationPhase: builder.query<
      GetProjectInnovationPhaseResponse,
      void
    >({
      query: () => ({
        url: "/projeto/faseinovacao",
      }),
    }),

    // Get Tipo Compartilhamento options
    getProjectSharingType: builder.query<GetProjectSharingTypeResponse, void>({
      query: () => ({
        url: "/projeto/tipocompartilhamento",
      }),
    }),

    // Get TRL options
    getProjectTrl: builder.query<GetProjectTrlResponse, void>({
      query: () => ({
        url: "/projeto/trl",
      }),
    }),

    // GetTipoEntidade {{url_api}}/projeto/tipoentidade
    /*
    RESPONSE SAMPLE:
    {
    "mensagem": "Lista de Tipos de entidade: ",
    "data": [
        {
            "tipo_entidade_projeto_id": "p",
            "descricao": "Proponente"
        },
        {
            "tipo_entidade_projeto_id": "c",
            "descricao": "cooperada"
        }
    ]
}
    */
    getProjectEntityType: builder.query<
      GetProjectEntityTypeResponse,
      GetProjectEntityTypeParams
    >({
      query: (params) => ({
        url: "/projeto/tipoentidade",
        params,
      }),
      providesTags: ["Project"],
    }),

    // Save project identification
    saveIdentificationStep: builder.mutation<
      SaveIdentificationResponse,
      { projectId?: number; data: IdentificationFormState }
    >({
      query: ({ projectId, data }) => {
        const body = {
          nome: data.nome,
          tipo_projeto_id: parseInt(data.tipo_projeto_id) || 0,
          titulo: data.titulo,
          titulo_resumido: data.titulo_resumido,
          codigo_ods: data.codigo_ods,
          codigo_proposta: data.codigo_proposta,
          codigo_aneel: data.codigo_aneel,
          data_inicio_prevista: data.data_inicio_prevista
            ? data.data_inicio_prevista.split("T")[0]
            : null,
          duracao: parseInt(data.duracao) || 0,
          trl_inicial: parseInt(data.trl_inicial) || 0,
          trl_final: parseInt(data.trl_final) || 0,
          vl_projeto_total: data.vl_projeto_total,
          vl_repasse_total: data.vl_repasse_total,
          vl_contrapartida: data.vl_contrapartida,
          vl_recurso_interno: data.vl_recurso_interno,
          captacao: data.captacao,
          tipo_produto_projeto_id: data.tipo_produto_projeto_id,
          fase_inovacao_projeto_id: data.fase_inovacao_projeto_id,
          tipo_compartilhamento_projeto_id:
            data.tipo_compartilhamento_projeto_id,
          codigo_tema: data.codigo_tema,
          outro_tema: data.outro_tema,
          codigo_subtema: data.codigo_subtema,
          outro_subtema: data.outro_subtema,
        };

        return {
          url: projectId
            ? `/projeto/${projectId}/identificacao`
            : `/projeto/identificacao`,
          method: projectId ? "PUT" : "POST",
          body,
        };
      },
      invalidatesTags: ["Project"],
    }),

    // Get project description data
    getProjectDescription: builder.query<GetProjectDescriptionResponse, number>(
      {
        query: (projectId) => ({
          url: `/projeto/${projectId}/descricao`,
        }),
        providesTags: (_result, _error, id) => [{ type: "Project", id }],
        transformResponse: (response: GetProjectDescriptionResponse) => {
          if (response.data?.palavras_chave) {
            const keywords = response.data
              .palavras_chave as unknown as TagItemResponse[];
            response.data.palavras_chave = keywords.map((tagItem) => ({
              tag_id: tagItem.tag.tag_id,
              descricao: tagItem.tag.descricao,
            }));
          }
          return response;
        },
      },
    ),

    // Save the description step data
    saveDescriptionStep: builder.mutation<
      SaveDescriptionResponse,
      { projectId: number; data: DescriptionFormState }
    >({
      query: ({ projectId, data }) => {
        const keywords = data.palavras_chave.map((tag) => ({
          tag_id: tag.tag_id,
        }));

        return {
          url: `/projeto/${projectId}/descricao`,
          method: "PUT",
          body: {
            descricao: data.descricao,
            motivacao: data.motivacao,
            originalidade: data.originalidade,
            aplicabilidade: data.aplicabilidade,
            relevancia: data.relevancia,
            razoabilidade_custos: data.razoabilidade_custos,
            pesquisa_correlata: data.pesquisa_correlata,
            palavras_chave: keywords,
          },
        };
      },
      invalidatesTags: (_result, _error, { projectId }) => [
        { type: "Project", id: projectId },
      ],
    }),

    // Get project entities data
    getProjectEntities: builder.query<GetEntitiesResponse, number>({
      query: (projectId) => ({
        url: `/projeto/${projectId}/entidades`,
      }),
      providesTags: (_result, _error, id) => [{ type: "Project", id }],
    }),

    // Save project entities step data
    saveEntitiesStep: builder.mutation<
      SaveEntitiesResponse,
      { projectId: number; data: EntitiesFormState }
    >({
      query: ({ projectId, data }) => {
        // Format the payload according to the API's expected structure
        const payload = {
          proponentes_cooperadas: data.proponentes_cooperadas.map((item) => {
            const result: any = {
              empresa: {
                empresa_id: item.empresa_cooperada.empresa_id,
                nome_fantasia: item.empresa_cooperada.nome_fantasia,
                razao_social: item.empresa_cooperada.razao_social || "",
              },
              tipo_entidade: {
                tipo_entidade_id: item.tipo_entidade.tipo_entidade_id,
                descricao: item.tipo_entidade.descricao,
              },
              valor_repasse: item.valor_repasse,
              recurso_interno: item.recurso_interno,
            };

            if (item.projeto_entidade_id) {
              result.projeto_entidade_id = item.projeto_entidade_id;
            }

            return result;
          }),
          executoras: data.executoras
            ? data.executoras.map((item) => {
                const result: any = {
                  empresa_executora: {
                    empresa_id: item.empresa_executora.empresa_id,
                    nome_fantasia: item.empresa_executora.nome_fantasia,
                    razao_social: item.empresa_executora.razao_social || "",
                  },
                  tipo_entidade: {
                    tipo_entidade_id: item.tipo_entidade.tipo_entidade_id,
                    tipo_entidade: item.tipo_entidade.descricao,
                  },
                  valor_repasse: item.valor_repasse,
                  contrapartida: item.contrapartida,
                };

                if (item.projeto_entidade_id) {
                  result.projeto_entidade_id = item.projeto_entidade_id;
                }

                return result;
              })
            : [],
        };

        return {
          url: `/projeto/${projectId}/entidades`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: (_result, _error, { projectId }) => [
        { type: "Project", id: projectId },
      ],
    }),

    // Get project roles data {{url_api}}/projeto/37/papeis
    getProjectRolesList: builder.query<GetProjectRolesListResponse, number>({
      query: (projectId) => ({
        url: `/projeto/${projectId}/papeis`,
      }),
      providesTags: (_result, _error, id) => [{ type: "Project", id }],
    }),

    // Get Papeis {{url_api}}/projeto/papeis
    getProjectRoles: builder.query<GetProjectRolesResponse, void>({
      query: () => ({
        url: "/projeto/papeis",
        method: "GET",
      }),
      providesTags: ["Project"],
    }),

    // Delete/Remover Usuario {{url_api}}/projeto/37/papeis/5
    removeUserFromProject: builder.mutation<
      RemoveUserFromProjectResponse,
      { projectId: number; projeto_usuario_papel_id: number }
    >({
      query: ({ projectId, projeto_usuario_papel_id }) => ({
        url: `/projeto/${projectId}/papeis/${projeto_usuario_papel_id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Project"],
    }),

    //POST - Step 4 - Adicionar Usuario {{url_api}}/projeto/37/papeis/adicionar
    addUserToProject: builder.mutation<
      AddUserToProjectResponse,
      { projectId: number; data: AddUserToProjectRequest }
    >({
      query: ({ projectId, data }) => ({
        url: `/projeto/${projectId}/papeis/adicionar`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Project"],
    }),

    // POST - Step 4 - Convidar Usuario {{url_api}}/projeto/37/papeis/convidar
    inviteUserToProject: builder.mutation<
      InviteUserToProjectResponse,
      { projectId: number; data: InviteUserToProjectRequest }
    >({
      query: ({ projectId, data }) => ({
        url: `/projeto/${projectId}/papeis/convidar`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Project"],
    }),

    // Listar executoras {{url_api}}/executora?page=1&perPage=20&sortDirection=desc&sortBy=nome_fantasia
    getExecutingCompanies: builder.query<
      GetExecutingCompaniesResponse,
      GetExecutingCompaniesParams
    >({
      query: (params) => ({
        url: "/executora",
        method: "GET",
        params,
      }),
      providesTags: ["Project"],
    }),

    // Inserir Executora {{url_api}}/executora
    saveExecutingCompany: builder.mutation<
      SaveExecutingCompanyResponse,
      SaveExecutingCompanyRequest
    >({
      query: (body) => ({
        url: "/executora",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),

    // Alterar Executora {{url_api}}/executora/8
    updateExecutingCompany: builder.mutation<
      SaveExecutingCompanyResponse,
      { executora_id: number; data: SaveExecutingCompanyRequest }
    >({
      query: ({ executora_id, data }) => ({
        url: `/executora/${executora_id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Project"],
    }),

    // getExecutoraById {{url_api}}/executora/8
    getExecutingCompanyById: builder.query<
      GetExecutingCompanyByIdResponse,
      number
    >({
      query: (executora_id) => ({
        url: `/executora/${executora_id}`,
      }),
      providesTags: (_result, _error, id) => [{ type: "Project", id }],
    }),

    // Add a new tag
    addTag: builder.mutation<
      AddTagResponse,
      { descricao: string; categoria: string }
    >({
      query: (tagData) => ({
        url: "/tag",
        method: "POST",
        body: tagData,
      }),
      invalidatesTags: ["Tag"],
      transformResponse: (response: AddTagResponse) => {
        // Ensure the response data is correctly formatted
        return {
          ...response,
          data: {
            ...response.data,
            // Ensure the tag has all required fields
            tag_id: response.data.tag_id,
            descricao: response.data.descricao || "",
          },
        };
      },
    }),

    // Delete a tag
    deleteTag: builder.mutation<DeleteTagResponse, number>({
      query: (tagId) => ({
        url: `/tag/${tagId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Tag"],
    }),
  }),
});

export const {
  useGetProjectsQuery,
  useGetProjectIdentificationQuery,
  useGetProjectProductTypeQuery,
  useGetProjectInnovationPhaseQuery,
  useGetProjectSharingTypeQuery,
  useGetProjectTrlQuery,
  useSaveIdentificationStepMutation,
  useSaveDescriptionStepMutation,
  useGetProjectDescriptionQuery,
  useAddTagMutation,
  useDeleteTagMutation,
  useSaveEntitiesStepMutation,
  useGetProjectEntitiesQuery,
  useGetProjectEntityTypeQuery,
  useGetExecutingCompaniesQuery,
  useSaveExecutingCompanyMutation,
  useUpdateExecutingCompanyMutation,
  useGetExecutingCompanyByIdQuery,
  useGetProjectRolesListQuery,
  useGetProjectRolesQuery,
  useRemoveUserFromProjectMutation,
  useAddUserToProjectMutation,
  useInviteUserToProjectMutation,
} = projectApi;
