// src/config/baseQuery.ts
import {
  fetchBaseQuery,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { API_URL } from "config/apiConfig";
import { COMPANY_CODE_HEADER_KEY } from "features/auth/components/Login/components/UserCompanyAccessModal/utils/userCompanyAccessUtil";
import { clearAllAppStorage } from "features/auth/utils/authUtils";
import { logout } from "features/auth/slices/authSlice";

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }

    const selectedCompanyId = localStorage.getItem(COMPANY_CODE_HEADER_KEY);
    if (selectedCompanyId) {
      headers.set("x-codigo-empresa", selectedCompanyId);
    }

    return headers;
  },
});

export const baseQueryWithAuth: BaseQueryFn<
  FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    clearAllAppStorage();
    api.dispatch(logout());
    window.location.href = "/login";
  }

  return result;
};
