import { useState } from "react";
import Input from "common/components/Input";
import { useTranslation } from "react-i18next";

interface CNPJInputProps {
  onChange?: (value: string) => void;
  value?: string;
  className?: string;
  error?: string;
  required?: boolean;
  disabled?: boolean;
}

const CNPJInput = ({
  onChange,
  value: externalValue,
  className,
  error,
  required,
  disabled,
}: CNPJInputProps) => {
  const { t } = useTranslation();
  const [internalValue, setInternalValue] = useState("");

  const value = externalValue ?? internalValue;

  const maskCNPJ = (value: string): string => {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1/$2")
      .replace(/(\d{4})(\d)/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const maskedValue = maskCNPJ(e.target.value);

    if (!externalValue) {
      setInternalValue(maskedValue);
    }

    onChange?.(maskedValue);
  };

  return (
    <Input
      type="text"
      label={t("cnpj.label")}
      value={value}
      onChange={handleChange}
      className={className}
      error={error}
      placeholder="00.000.000/0000-00"
      required={required}
      disabled={disabled}
    />
  );
};

export default CNPJInput;
