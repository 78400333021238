import { useState, useRef, useEffect } from "react";

export interface SelectOption {
  value: string;
  label: string;
}

interface UseSelectProps {
  value: string;
  options: SelectOption[];
  onChange: (value: string) => void;
  onCreateOption?: (value: string) => void;
  isOpen?: boolean;
  onClose?: () => void;
}

export const useSelect = ({
  value,
  options,
  onChange,
  onCreateOption,
  isOpen: controlledIsOpen,
  onClose,
}: UseSelectProps) => {
  const [uncontrolledIsOpen, setUncontrolledIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const selectRef = useRef<HTMLDivElement>(null);

  const isOpen = controlledIsOpen ?? uncontrolledIsOpen;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        if (controlledIsOpen !== undefined) {
          onClose?.();
        } else {
          setUncontrolledIsOpen(false);
        }
        setSearchTerm("");
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, controlledIsOpen, onClose]);

  const handleSelect = (optionValue: string) => {
    onChange(optionValue);
    if (controlledIsOpen !== undefined) {
      onClose?.();
    } else {
      setUncontrolledIsOpen(false);
    }
    setSearchTerm("");
  };

  const handleCreateOption = () => {
    if (onCreateOption && searchTerm.trim()) {
      onCreateOption(searchTerm.trim());
      if (controlledIsOpen !== undefined) {
        onClose?.();
      } else {
        setUncontrolledIsOpen(false);
      }
      setSearchTerm("");
    }
  };

  const filteredOptions =
    searchTerm.length >= 3
      ? options.filter((option) =>
          option.label.toLowerCase().includes(searchTerm.toLowerCase()),
        )
      : options;

  const selectedOption = options.find((option) => option.value === value);
  const showCreateOption =
    onCreateOption &&
    searchTerm.length >= 3 &&
    !filteredOptions.some(
      (option) => option.label.toLowerCase() === searchTerm.toLowerCase(),
    );

  return {
    isOpen: uncontrolledIsOpen,
    setIsOpen: setUncontrolledIsOpen,
    searchTerm,
    setSearchTerm,
    selectRef,
    filteredOptions,
    showCreateOption,
    selectedOption,
    handleSelect,
    handleCreateOption,
  };
};
