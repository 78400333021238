import Textarea from "common/components/Textarea";
import { useTranslation } from "react-i18next";
import { DescriptionFormState } from "features/project/slices/projectFormSlice";

interface TextareaSectionProps {
  name: keyof DescriptionFormState;
  value: string;
  isViewMode: boolean;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  hideLabel?: boolean;
}

const TextareaSection = ({
  name,
  value,
  isViewMode,
  onChange,
  hideLabel = false,
}: TextareaSectionProps) => {
  const { t } = useTranslation();

  return (
    <Textarea
      label={hideLabel ? "" : t(`project.descriptionStep.fields.${name}`)}
      name={name}
      value={value}
      onChange={onChange}
      placeholder={t(`project.descriptionStep.placeholders.${name}`)}
      rows={6}
      readOnly={isViewMode}
      disabled={isViewMode}
      className={isViewMode ? "bg-gray-100 resize-none" : "resize-none"}
    />
  );
};

export default TextareaSection;
