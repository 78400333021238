import type { ColumnDefinition } from "common/components/ServerTable/types";
import { ChevronDown, ChevronUp, ChevronsUpDown } from "lucide-react";
import { useMemo } from "react";

interface TableHeaderProps<T> {
  columns: ColumnDefinition<T>[];
  sortBy?: string;
  sortDirection?: "asc" | "desc";
  onSort?: (column: string) => void;
  actionColumn?: {
    position?: "start" | "end";
    width?: string;
  };
  fixedColumnKey?: keyof T;
  showHeader?: boolean;
}

const TableHeader = <T extends Record<string, unknown>>({
  columns,
  sortBy,
  sortDirection,
  onSort,
  actionColumn,
  fixedColumnKey,
  showHeader = true,
}: TableHeaderProps<T>) => {
  if (!showHeader) return null;

  const renderSortIcon = (column: ColumnDefinition<T>) => {
    if (!column.sortable) return null;

    if (sortBy === column.key) {
      return sortDirection === "asc" ? (
        <ChevronUp className="w-4 h-4 text-[--primary]" />
      ) : (
        <ChevronDown className="w-4 h-4 text-[--primary]" />
      );
    }

    return <ChevronsUpDown className="w-4 h-4 text-[--text-secondary]" />;
  };

  const commonHeaderClasses = useMemo(() => {
    return "px-2 py-1 text-left font-normal text-base tracking-wider relative border-r-2 border-white last:border-r-0";
  }, []);

  const columnGroups = useMemo(() => {
    const groups: Record<string, ColumnDefinition<T>[]> = {};
    const noGroupColumnsBefore: ColumnDefinition<T>[] = [];
    const noGroupColumnsAfter: ColumnDefinition<T>[] = [];
    let foundGroups = false;

    columns.forEach((column) => {
      if (column.group === "_NO_GROUP_") {
        if (foundGroups) {
          noGroupColumnsAfter.push(column);
        } else {
          noGroupColumnsBefore.push(column);
        }
      } else if (column.group) {
        foundGroups = true;
        if (!groups[column.group]) {
          groups[column.group] = [];
        }
        groups[column.group].push(column);
      }
    });

    return { groups, noGroupColumnsBefore, noGroupColumnsAfter };
  }, [columns]);

  const hasGroups = Object.keys(columnGroups.groups).length > 0;

  const renderColumnTitle = (column: ColumnDefinition<T>) => {
    const isCurrencyColumn = column.editConfig?.type === "currency";

    if (isCurrencyColumn) {
      const value = Number(
        column.title.replace(/[^-0-9,]/g, "").replace(",", "."),
      );
      return (
        <span
          className={`truncate ${column.className || ""} ${value < 0 ? "text-[--error-negative]" : ""}`}
          title={String(column.title)}
        >
          {column.title}
        </span>
      );
    }
    return column.title;
  };

  const getFixedColumnClasses = (columnKey: string) => {
    return fixedColumnKey && columnKey === fixedColumnKey
      ? "sticky left-0 z-20 bg-[--table-header-bg] shadow-[2px_0_5px_-2px_rgba(0,0,0,0.1)]"
      : "";
  };

  return (
    <thead className="bg-[--table-header-bg] text-table-header-text">
      {hasGroups && (
        <tr className="border-b-2 border-white">
          {actionColumn?.position === "start" && (
            <th
              rowSpan={2}
              className={commonHeaderClasses}
              style={{ width: actionColumn.width || "auto" }}
            ></th>
          )}

          {/* Render ungrouped columns that come before groups */}
          {columnGroups.noGroupColumnsBefore.map((column) => (
            <th
              key={String(column.key)}
              rowSpan={2}
              className={`${commonHeaderClasses} ${column.className || ""} ${getFixedColumnClasses(String(column.key))}`}
            >
              {column.title}
            </th>
          ))}

          {/* Render group headers */}
          {Object.entries(columnGroups.groups).map(
            ([groupName, groupColumns]) => (
              <th
                key={groupName}
                colSpan={groupColumns.length}
                className={`${commonHeaderClasses} text-center`}
              >
                {groupName}
              </th>
            ),
          )}

          {/* Render ungrouped columns that come after groups */}
          {columnGroups.noGroupColumnsAfter.map((column) => (
            <th
              key={String(column.key)}
              rowSpan={2}
              className={`${commonHeaderClasses} ${column.className || ""} ${getFixedColumnClasses(String(column.key))}`}
            >
              {column.title}
            </th>
          ))}

          {actionColumn?.position === "end" && (
            <th
              rowSpan={2}
              className={commonHeaderClasses}
              style={{ width: actionColumn.width || "auto" }}
            >
              Ações
            </th>
          )}
        </tr>
      )}

      <tr className="border-b-2 border-white">
        {!hasGroups && actionColumn?.position === "start" && (
          <th
            className={commonHeaderClasses}
            style={{ width: actionColumn.width || "auto" }}
          >
            Ações
          </th>
        )}

        {/* Only render grouped columns in second row */}
        {hasGroups
          ? Object.values(columnGroups.groups)
              .flat()
              .map((column) => (
                <th
                  key={String(column.key)}
                  className={`${commonHeaderClasses} ${column.headerClassName || ""} ${
                    column.sortable
                      ? "hover:text-[--primary-hover] transition-colors duration-200"
                      : ""
                  } ${getFixedColumnClasses(String(column.key))}`}
                  onClick={() =>
                    column.sortable && onSort?.(String(column.key))
                  }
                  style={{
                    width: column.width || "auto",
                    minWidth: column.minWidth || "auto",
                    maxWidth: column.maxWidth || "none",
                  }}
                >
                  <div className="flex items-center gap-1">
                    {renderColumnTitle(column)}
                    {renderSortIcon(column)}
                  </div>
                </th>
              ))
          : columns.map((column) => (
              <th
                key={String(column.key)}
                className={`${commonHeaderClasses} ${column.headerClassName || ""} ${
                  column.sortable
                    ? "hover:text-[--primary-hover] transition-colors duration-200"
                    : ""
                } ${getFixedColumnClasses(String(column.key))}`}
                onClick={() => column.sortable && onSort?.(String(column.key))}
                style={{
                  width: column.width || "auto",
                  minWidth: column.minWidth || "auto",
                  maxWidth: column.maxWidth || "none",
                }}
              >
                <div
                  className={`flex items-center gap-1 ${column.className || ""}`}
                >
                  {renderColumnTitle(column)}
                  {renderSortIcon(column)}
                </div>
              </th>
            ))}

        {!hasGroups && actionColumn?.position === "end" && (
          <th
            className={commonHeaderClasses}
            style={{ width: actionColumn.width || "auto" }}
          >
            Ações
          </th>
        )}
      </tr>
    </thead>
  );
};

export default TableHeader;
