import { useNavigate } from "react-router-dom";
import Button from "common/components/Button";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center min-h-[60vh] p-4">
      <h1 className="text-3xl font-semibold text-primary mb-4">
        {t("notFound.title")}
      </h1>
      <p className="text-text-secondary mb-8 text-center max-w-md">
        {t("notFound.description")}
      </p>
      <div className="flex gap-4">
        <Button onClick={() => navigate(-1)}>{t("notFound.backButton")}</Button>
        <Button variant="primary" onClick={() => navigate("/home")}>
          {t("notFound.homeButton")}
        </Button>
      </div>
    </div>
  );
};

export default NotFound;
