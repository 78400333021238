import { useState, useEffect } from "react";
import Input from "common/components/Input";
import { CrudComponentProps } from "features/crud/types";
import { useTranslation } from "react-i18next";

const IeInput = ({
  value,
  onChange,
  error,
  disabled,
  className,
  required,
}: CrudComponentProps<string>) => {
  const { t } = useTranslation();
  const [internalValue, setInternalValue] = useState("");

  // Use external value if provided, otherwise use internal state
  const inputValue = value ?? internalValue;

  useEffect(() => {
    if (value && value !== internalValue) {
      setInternalValue(value);
    }
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value;

    // Limit the input length to 20 characters
    if (rawValue.length <= 100) {
      setInternalValue(rawValue);
      onChange?.(rawValue);
    }
  };

  return (
    <Input
      label={t("ie.label")}
      type="text"
      value={inputValue}
      onChange={handleChange}
      className={className}
      error={error}
      disabled={disabled}
      required={required}
      placeholder={t("ie.placeholder")}
    />
  );
};

export default IeInput;
