// src/features/location/api/index.ts
import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "config/baseQuery";
import { MunicipalityResponse, UfResponse } from "features/location/api/types";

export const locationSelectApi = createApi({
  reducerPath: "locationSelectApi",
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    getUf: builder.query<UfResponse, void>({
      query: () => ({
        url: "municipiouf/getListaUF",
        method: "GET",
      }),
    }),
    getMunicipality: builder.query<MunicipalityResponse, string>({
      query: (uf_id) => ({
        url: `municipiouf/getListaMunicipiosByUF/${uf_id}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetUfQuery, useGetMunicipalityQuery } = locationSelectApi;
