import { useTranslation } from "react-i18next";
import { EntitiesFormState } from "features/project/slices/projectFormSlice";
import { formatCurrencyPtBr } from "common/utils/formatters";

interface EntitiesViewProps {
  formData: EntitiesFormState;
}

const EntitiesView = ({ formData }: EntitiesViewProps) => {
  const { t } = useTranslation();

  const renderSectionHeader = (title: string) => (
    <h3 className="text-primary font-semibold text-center p-1 bg-[--primary-light] rounded-md shadow-sm">
      {title}
    </h3>
  );

  const renderFinancialInformation = () => {
    const renderInfoField = (label: string, value: string | number | null) => {
      const formattedValue =
        typeof value === "number" ? formatCurrencyPtBr(value) : value || "-";

      return (
        <div className="flex justify-between items-center p-1.5 border rounded-md bg-white shadow-sm">
          <p className="text-sm text-text-secondary font-medium">{label}:</p>
          <p className="font-semibold text-primary">{formattedValue}</p>
        </div>
      );
    };

    return (
      <div className="flex flex-col gap-1 p-1 border border-table-header-bg rounded-md">
        {renderSectionHeader(
          t("project.identificationStep.sections.financialInformation"),
        )}

        <div className="flex flex-col gap-1 p-1 rounded-md border border-[--table-header-bg] bg-[--breadcrumb-bg] shadow-sm">
          <div className="grid grid-cols-1 sm:grid-cols-4 gap-3 p-1">
            {renderInfoField(
              t("project.entitiesStep.fields.totalValue"),
              formData.vl_projeto_total,
            )}
            {renderInfoField(
              t("project.entitiesStep.fields.transferValue"),
              formData.vl_repasse_total,
            )}
            {renderInfoField(
              t("project.entitiesStep.fields.internalValue"),
              formData.vl_recurso_interno,
            )}
            {renderInfoField(
              t("project.entitiesStep.fields.counterpartValue"),
              formData.vl_contrapartida,
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderProponentsPartners = () => {
    const calculateParticipation = (
      repasse: number,
      recursoInterno: number,
    ) => {
      const total = formData.vl_repasse_total + formData.vl_recurso_interno;
      if (total === 0) return 0;
      return Number((((repasse + recursoInterno) / total) * 100).toFixed(2));
    };

    return (
      <div className="flex flex-col gap-1 p-1 border border-table-header-bg rounded-md">
        {renderSectionHeader(
          t("project.entitiesStep.sections.proponentsPartners"),
        )}

        <div className="flex flex-col gap-1 p-1 rounded-md border border-[--table-header-bg] bg-[--breadcrumb-bg] shadow-sm">
          {formData.proponentes_cooperadas &&
          formData.proponentes_cooperadas.length > 0 ? (
            <div className="grid grid-cols-1 gap-3 p-1">
              {formData.proponentes_cooperadas.map((entity) => (
                <div
                  key={entity.empresa_cooperada.empresa_id}
                  className="p-3 bg-white rounded-md shadow-sm border"
                >
                  <div className="flex flex-col gap-1">
                    <div className="flex justify-between items-center">
                      <h4 className="font-semibold text-primary">
                        {entity.empresa_cooperada.nome_fantasia}
                      </h4>
                      <span className="text-sm px-2 py-0.5 bg-primary-light text-primary rounded-full">
                        {entity.tipo_entidade.descricao}
                      </span>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-2 mt-2">
                      <div className="flex flex-col">
                        <span className="text-xs text-text-secondary">
                          {t("project.entitiesStep.fields.transferValue")}
                        </span>
                        <span className="font-medium">
                          {formatCurrencyPtBr(entity.valor_repasse)}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-xs text-text-secondary">
                          {t("project.entitiesStep.fields.internalValue")}
                        </span>
                        <span className="font-medium">
                          {formatCurrencyPtBr(entity.recurso_interno)}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-xs text-text-secondary">
                          {t("project.entitiesStep.fields.participation")}
                        </span>
                        <span className="font-medium">
                          {calculateParticipation(
                            entity.valor_repasse,
                            entity.recurso_interno,
                          )}
                          %
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="p-3 text-center text-text-secondary italic">
              {t("project.entitiesStep.noProponentsAdded")}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderExecutingCompanies = () => {
    const calculateParticipation = (repasse: number, contrapartida: number) => {
      const total = formData.vl_repasse_total + formData.vl_contrapartida;
      if (total === 0) return 0;
      return Number((((repasse + contrapartida) / total) * 100).toFixed(2));
    };

    return (
      <div className="flex flex-col gap-1 p-1 border border-table-header-bg rounded-md">
        {renderSectionHeader(t("project.entitiesStep.sections.executing"))}

        <div className="flex flex-col gap-1 p-1 rounded-md border border-[--table-header-bg] bg-[--breadcrumb-bg] shadow-sm">
          {formData.executoras && formData.executoras.length > 0 ? (
            <div className="grid grid-cols-1 gap-3 p-1">
              {formData.executoras.map((entity) => (
                <div
                  key={entity.empresa_executora.empresa_id}
                  className="p-3 bg-white rounded-md shadow-sm border"
                >
                  <div className="flex flex-col gap-1">
                    <h4 className="font-semibold text-primary">
                      {entity.empresa_executora.nome_fantasia}
                    </h4>
                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-2 mt-2">
                      <div className="flex flex-col">
                        <span className="text-xs text-text-secondary">
                          {t("project.entitiesStep.fields.transferValue")}
                        </span>
                        <span className="font-medium">
                          {formatCurrencyPtBr(entity.valor_repasse)}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-xs text-text-secondary">
                          {t("project.entitiesStep.fields.counterpartValue")}
                        </span>
                        <span className="font-medium">
                          {formatCurrencyPtBr(entity.contrapartida)}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-xs text-text-secondary">
                          {t("project.entitiesStep.fields.participation")}
                        </span>
                        <span className="font-medium">
                          {calculateParticipation(
                            entity.valor_repasse,
                            entity.contrapartida,
                          )}
                          %
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="p-3 text-center text-text-secondary italic">
              {t("project.entitiesStep.noExecutingCompaniesAdded")}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-4">
      {renderFinancialInformation()}
      {renderProponentsPartners()}
      {renderExecutingCompanies()}
    </div>
  );
};

export default EntitiesView;
