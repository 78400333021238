import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { ChevronDown, ArrowRightLeft, User } from "lucide-react";
import * as LucideIcons from "lucide-react";
import { useSidebarNavigation, NavItem } from "./hooks/useSidebarNavigation";
import { useCallback, useState, useEffect } from "react";
import { useRegisterMenuAccessMutation } from "features/user/api";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store";
import { setIsLogoutModalOpen } from "features/auth/slices/authSlice";
import Dropdown, { DropdownOption } from "common/components/Dropdown";
import { useTranslation } from "react-i18next";
import { setIsCompanyModalOpen } from "features/user/slices/selectedCompanySlice";
import { useGetUserCompaniesQuery } from "features/user/api";
import { useUpdateCompanyLogoMutation } from "features/company/api";
import { Upload } from "lucide-react";
import { clearCurrentView } from "common/components/Breadcrumb/slices/breadcrumbSlice";
import Avatar from "common/components/Dashboard/components/Sidebar/components/Avatar";

interface SidebarContentProps {
  onClose: () => void;
}

const MAX_FILE_SIZE = 5 * 1024 * 1024;
const ACCEPTED_TYPES = ["image/jpeg", "image/jpg", "image/png"];

const convertToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};

const validateFile = (file: File): string | null => {
  if (!ACCEPTED_TYPES.includes(file.type)) {
    return "Formato de arquivo inválido. Use JPG, JPEG ou PNG.";
  }
  if (file.size > MAX_FILE_SIZE) {
    return "Arquivo muito grande. O tamanho máximo é 5MB.";
  }
  return null;
};

const SidebarContent = ({ onClose }: SidebarContentProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { navItems, toggleDropdown, isDropdownOpen } = useSidebarNavigation();
  const [registerMenuAccess] = useRegisterMenuAccessMutation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth);
  const { data: companiesData } = useGetUserCompaniesQuery({
    filtro_generico: "",
    page: 1,
    perPage: 10,
    sortDirection: "asc",
    sortBy: "empresa_id",
  });
  const hasSingleCompany = companiesData?.data?.result?.length === 1;
  const [hasImageError, setHasImageError] = useState(false);
  const [preview, setPreview] = useState<string | null>(null);
  const [fileError, setFileError] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [updateCompanyLogo] = useUpdateCompanyLogoMutation();

  useEffect(() => {
    setHasImageError(false);
    setPreview(user?.empresa_logada?.logo_empresa || null);
  }, [user?.empresa_logada?.logo_empresa]);

  const handleImageError = () => {
    setHasImageError(true);
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const validationError = validateFile(file);
    if (validationError) {
      setFileError(validationError);
      return;
    }

    setIsUploading(true);
    setFileError(null);

    try {
      const base64String = await convertToBase64(file);
      setPreview(base64String);

      if (user?.empresa_logada?.empresa_id) {
        await updateCompanyLogo({
          companyId: user.empresa_logada.empresa_id,
          logo_empresa_base64: base64String,
        }).unwrap();
      }
    } catch (error) {
      setFileError("Erro ao fazer upload. Tente novamente.");
    } finally {
      setIsUploading(false);
    }
  };

  const handleNavigation = useCallback(
    (path: string, menuId?: number) => {
      dispatch(clearCurrentView());

      if (menuId) {
        registerMenuAccess(menuId).unwrap();
      }
      navigate(path);
      onClose();
    },
    [navigate, onClose, registerMenuAccess, dispatch],
  );

  const getItemClasses = (isActive: boolean, depth: number): string => {
    const baseClasses =
      "group relative flex items-center w-full transition-colors duration-200";
    const activeClasses = isActive
      ? "text-primary font-semibold"
      : "text-text hover:text-primary";
    const depthClasses = depth > 0 ? "text-sm" : "text-base";
    return `${baseClasses} ${activeClasses} ${depthClasses}`;
  };

  const getIndentation = (depth: number): number => {
    const baseIndent = 16;
    const indentIncrement = 12;
    const iconSpace = 36;
    return depth === 1
      ? baseIndent + iconSpace
      : depth > 1
        ? baseIndent + iconSpace + (depth - 1) * indentIncrement
        : baseIndent;
  };

  const renderIcon = (iconName: string | undefined) => {
    if (!iconName) return null;

    const Icon = (
      LucideIcons as unknown as Record<string, React.FC<{ className?: string }>>
    )[
      iconName
        .split("-")
        .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
        .join("")
    ];

    return Icon ? <Icon className="w-5 h-5 mr-2 flex-shrink-0" /> : null;
  };

  const renderNavItem = (item: NavItem, depth = 0) => {
    const hasSubItems =
      Array.isArray(item.subItems) && item.subItems.length > 0;
    const isItemActive = (item: NavItem): boolean => {
      if (location.pathname.startsWith(item.path)) return true;
      return item.subItems?.some(isItemActive) || false;
    };
    const isActive = isItemActive(item);
    const isOpen = isDropdownOpen(item.key);
    const paddingLeft = getIndentation(depth);
    const itemClasses = getItemClasses(isActive, depth);

    const renderVerticalLine = depth > 0 && (
      <div
        className="absolute w-px bg-light-border/30"
        style={{
          left: `${paddingLeft - 8}px`,
          top: 0,
          bottom: hasSubItems && isOpen ? "0" : "100%",
        }}
      />
    );

    const renderDotIndicator = null;
    // const renderDotIndicator = depth > 0 && (
    //   <div className="w-1.5 h-1.5 rounded-full bg-current opacity-40" />
    // );

    if (hasSubItems) {
      return (
        <div key={item.key} className="relative">
          {renderVerticalLine}
          <div className="flex w-full">
            {item.isNavigable ? (
              <button
                type="button"
                className={`${itemClasses} py-2 pr-2 flex-1`}
                style={{ paddingLeft: `${paddingLeft}px` }}
                onClick={() => {
                  toggleDropdown(item);
                  handleNavigation(item.path, item.menuId);
                }}
              >
                <div className="flex items-center gap-2 min-w-0">
                  {depth === 0 && renderIcon(item.icon)}
                  {renderDotIndicator}
                  <span className="truncate flex-1">{item.name}</span>
                </div>
              </button>
            ) : (
              <button
                type="button"
                className={`${itemClasses} py-2 pr-2 flex-1`}
                style={{ paddingLeft: `${paddingLeft}px` }}
                onClick={() => toggleDropdown(item)}
              >
                <div className="flex items-center gap-2 min-w-0">
                  {depth === 0 && renderIcon(item.icon)}
                  {renderDotIndicator}
                  <span className="truncate flex-1">{item.name}</span>
                </div>
              </button>
            )}
            <button
              type="button"
              className="py-2 px-2 text-text hover:text-primary transition-colors duration-200"
              onClick={(e) => {
                e.stopPropagation();
                toggleDropdown(item);
              }}
              aria-expanded={isOpen}
            >
              <ChevronDown
                className={`w-4 h-4 flex-shrink-0 transition-transform duration-200 ${
                  isOpen ? "rotate-180" : ""
                }`}
              />
            </button>
          </div>
          <div
            className={`overflow-hidden transition-all duration-200 ease-in-out ${
              isOpen ? "max-h-screen opacity-100" : "max-h-0 opacity-0"
            }`}
          >
            {item.subItems?.map((subItem) => renderNavItem(subItem, depth + 1))}
          </div>
        </div>
      );
    }

    if (!item.isNavigable) {
      return null;
    }

    return (
      <div key={item.key} className="relative">
        {renderVerticalLine}
        <NavLink
          to={item.path}
          onClick={() => {
            if (item.menuId) {
              registerMenuAccess(item.menuId)
                .unwrap()
                .catch(() => {
                  // Ignore errors, as we don't need to handle them
                });
            }
            onClose();
          }}
          className={({ isActive: linkActive }) => {
            return `${getItemClasses(linkActive, depth)} py-2 pr-4`;
          }}
          style={{ paddingLeft: `${paddingLeft}px` }}
          end
        >
          <div className="flex items-center gap-2 min-w-0">
            {depth === 0 && renderIcon(item.icon)}
            {renderDotIndicator}
            <span className="truncate">{item.name}</span>
          </div>
        </NavLink>
      </div>
    );
  };

  const userMenuOptions: DropdownOption[] = [
    {
      value: "profile",
      label: t("dashboard.header.userMenu.profile"),
    },
    {
      value: "logout",
      label: t("dashboard.header.userMenu.logout"),
    },
  ];

  const handleUserMenuChange = (value: string) => {
    switch (value) {
      case "profile":
        navigate("/meu-perfil");
        onClose();
        break;
      case "logout":
        dispatch(setIsLogoutModalOpen(true));
        break;
    }
  };

  const UserButton = () => {
    const hasValidAvatar =
      user?.avatar &&
      Object.values(user.avatar).some((value) => value !== null);

    return (
      <div className="flex items-center gap-2 py-1">
        <div>
          {hasValidAvatar && user?.avatar ? (
            <Avatar
              config={user.avatar}
              email={user.email_usuario}
              size="48px"
              className="rounded-full"
            />
          ) : (
            <div className="w-12 h-12 bg-light-bg rounded-full flex items-center justify-center flex-shrink-0">
              <User className="w-8 h-8 text-primary" />
            </div>
          )}
        </div>
        <div className="flex flex-col items-start">
          <span className="text-sm font-semibold text-text truncate whitespace-nowrap max-w-[150px]">
            {user?.nome_usuario}
          </span>
          <span className="text-sm text-text truncate whitespace-nowrap max-w-[160px]">
            {user?.email_usuario}
          </span>
          <span className="text-xs text-text text-center mt-1">
            {user?.perfil.nome_perfil}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className="h-full flex flex-col">
      <div className="p-1">
        <div className="space-y-4">
          <div className="w-full flex justify-center">
            {preview && !hasImageError ? (
              <div className="w-64 h-16 overflow-hidden flex items-center justify-center">
                <img
                  src={preview}
                  alt={user?.empresa_logada?.nome_fantasia || "Company Logo"}
                  className="w-full h-full object-contain"
                  onError={handleImageError}
                />
              </div>
            ) : (
              <label
                htmlFor="logo-upload"
                className="w-24 h-24 border-2 border-dashed border-gray-300 flex flex-col items-center justify-center gap-2 hover:border-primary transition-colors cursor-pointer rounded-lg bg-light-bg"
              >
                <Upload className="w-6 h-6 text-gray-400" />
                <span className="text-sm text-gray-500">
                  {t("dashboard.userCompany.uploadLogo")}
                </span>
                <input
                  type="file"
                  id="logo-upload"
                  accept={ACCEPTED_TYPES.join(",")}
                  onChange={handleFileChange}
                  className="hidden"
                  disabled={isUploading}
                />
              </label>
            )}
          </div>

          {fileError && (
            <p className="text-red-500 text-sm text-center">{fileError}</p>
          )}
          {isUploading && (
            <p className="text-sm text-gray-500 text-center">
              {t("dashboard.userCompany.uploading")}
            </p>
          )}
        </div>
      </div>

      <nav
        className="flex-1 overflow-y-auto pt-2 space-y-1 overflow-x-hidden"
        role="navigation"
        aria-label="Main navigation"
      >
        {navItems.map((item) => renderNavItem(item))}
      </nav>

      <div className="border-t-2 border-table-header-bg mt-auto p-2 space-y-2">
        {!hasSingleCompany && (
          <div className="flex items-center justify-between">
            <p className="text-sm font-bold text-text truncate">
              {user?.empresa_logada?.razao_social}
            </p>
            <button
              onClick={() => dispatch(setIsCompanyModalOpen(true))}
              className="p-2 text-text hover:text-primary bg-light-bg hover:bg-primary-light rounded-lg transition-colors duration-200"
              title={t("dashboard.userCompany.changeCompany")}
            >
              <ArrowRightLeft className="w-5 h-5" />
            </button>
          </div>
        )}

        <Dropdown
          options={userMenuOptions}
          onChange={handleUserMenuChange}
          renderOption={(option) => (
            <span className="text-sm">{option.label}</span>
          )}
          className="w-full bg-primary-light rounded-lg"
          variant="light"
          placement="top"
          placeholder={<UserButton />}
          menuClassName="mb-2"
        />
      </div>
    </div>
  );
};

export default SidebarContent;
