// src/features/entry/api/index.ts

import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "config/baseQuery";
import {
  GetEntriesResponse,
  CreateManualEntryRequest,
  CreateManualEntryResponse,
  AccountStatementResponse,
  AccountStatementQueryParams,
  TransferBetweenAccountsResponse,
  TransferBetweenAccountsRequest,
} from "features/entry/types";

export const entriesApi = createApi({
  reducerPath: "entriesApi",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["AccountStatement"],
  endpoints: (builder) => ({
    getAdjustmentOperations: builder.query<GetEntriesResponse, boolean>({
      query: (params) => ({
        url: "lancamento/operacoes",
        method: "GET",
        params: {
          apenas_ajuste: params,
        },
      }),
    }),

    createManualEntry: builder.mutation<
      CreateManualEntryResponse,
      CreateManualEntryRequest
    >({
      query: (data) => ({
        url: "lancamento/manual",
        method: "POST",
        body: data,
      }),
    }),

    getAccountStatement: builder.query<
      AccountStatementResponse,
      AccountStatementQueryParams
    >({
      query: (data) => ({
        url: "lancamento/extrato",
        method: "GET",
        params: data,
      }),
      providesTags: ["AccountStatement"],
    }),
    // Transferência entre contas
    transferBetweenAccounts: builder.mutation<
      TransferBetweenAccountsResponse,
      TransferBetweenAccountsRequest
    >({
      query: (body) => ({
        url: "lancamento/transferencia",
        method: "POST",
        body,
      }),
    }),

    // excluirLancamentoManual
    deleteManualEntry: builder.mutation<void, number>({
      query: (id) => ({
        url: `/lancamento/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["AccountStatement"],
    }),
  }),
});

export const {
  useGetAdjustmentOperationsQuery,
  useCreateManualEntryMutation,
  useGetAccountStatementQuery,
  useTransferBetweenAccountsMutation,
  useDeleteManualEntryMutation,
} = entriesApi;
