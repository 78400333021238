// src/features/company/components/CompanyForm/components/LogoPreview/index.tsx
import { CrudComponentProps } from "features/crud/types";

const LogoPreview = ({ value }: CrudComponentProps<string>) => {
  if (!value) {
    return (
      <div className="text-sm text-text-secondary">Nenhuma logo cadastrada</div>
    );
  }

  return (
    <div className="w-full">
      <div className="h-40 w-40 flex items-center justify-center bg-light-bg rounded overflow-hidden border border-[--text-secondary] border-opacity-20">
        <img
          src={value}
          alt="Logo da empresa"
          className="h-full w-full object-contain"
        />
      </div>
    </div>
  );
};

export default LogoPreview;
