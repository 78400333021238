import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { RootState } from "store";
import {
  updateDescriptionField,
  type DescriptionFormState,
} from "features/project/slices/projectFormSlice";
import {
  useSaveDescriptionStepMutation,
  useGetProjectDescriptionQuery,
  useAddTagMutation,
} from "features/project/api";
import { TagItem } from "features/project/types";

interface UseDescriptionProps {
  initialProjectId?: number;
  viewMode?: "view" | "edit" | "create";
  onSaveStep?: (success: boolean, projectId?: number) => void;
}

export const useDescription = ({
  initialProjectId,
  viewMode = "edit",
  onSaveStep,
}: UseDescriptionProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isViewMode = viewMode === "view";
  const isCreateMode = viewMode === "create";

  // ===========================================
  // GLOBAL STATE (REDUX)
  // ===========================================

  // Form state from Redux store (persists across navigation)
  const formData = useSelector(
    (state: RootState) => state.projectForm.description,
  );

  // ===========================================
  // LOCAL STATE (COMPONENT-SPECIFIC)
  // ===========================================

  // UI and operation state (transient, only relevant to this component)
  const [currentProjectId, setCurrentProjectId] = useState<number | undefined>(
    initialProjectId,
  );
  const [error, setError] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [inputValue, setInputValue] = useState("");

  // ===========================================
  // API QUERIES AND MUTATIONS (RTK QUERY)
  // ===========================================

  // Data fetching through RTK Query (handles caching and loading states)
  const [saveDescriptionStep] = useSaveDescriptionStepMutation();
  const [addTag, { isLoading: isAddingTag }] = useAddTagMutation();

  const { data: descriptionData, isFetching: isLoadingDescription } =
    useGetProjectDescriptionQuery(currentProjectId as number, {
      skip: !currentProjectId || isCreateMode,
    });

  // ===========================================
  // SIDE EFFECTS
  // ===========================================

  // Initialize project ID from props
  useEffect(() => {
    if (initialProjectId) {
      setCurrentProjectId(initialProjectId);
    }
  }, [initialProjectId]);

  // Update Redux store when project data is loaded from API
  useEffect(() => {
    if (descriptionData?.data && (isViewMode || !isCreateMode)) {
      const data = descriptionData.data;

      const keywords = data.palavras_chave || [];

      dispatch(
        updateDescriptionField({
          field: "descricao",
          value: data.descricao || "",
        }),
      );
      dispatch(
        updateDescriptionField({
          field: "motivacao",
          value: data.motivacao || "",
        }),
      );
      dispatch(
        updateDescriptionField({
          field: "originalidade",
          value: data.originalidade || "",
        }),
      );
      dispatch(
        updateDescriptionField({
          field: "aplicabilidade",
          value: data.aplicabilidade || "",
        }),
      );
      dispatch(
        updateDescriptionField({
          field: "relevancia",
          value: data.relevancia || "",
        }),
      );
      dispatch(
        updateDescriptionField({
          field: "razoabilidade_custos",
          value: data.razoabilidade_custos || "",
        }),
      );
      dispatch(
        updateDescriptionField({
          field: "pesquisa_correlata",
          value: data.pesquisa_correlata || "",
        }),
      );
      dispatch(
        updateDescriptionField({
          field: "palavras_chave",
          value: keywords,
        }),
      );
    }
  }, [descriptionData, isViewMode, isCreateMode, dispatch]);

  // ===========================================
  // EVENT HANDLERS
  // ===========================================

  // Handle text area changes (updates Redux store)
  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (isViewMode) return;

    const { name, value } = e.target;
    dispatch(
      updateDescriptionField({
        field: name as keyof DescriptionFormState,
        value,
      }),
    );
  };

  // Handle keywords management
  const handleKeywordsChange = (
    keywords: { tag_item_id?: number; tag_id: number; descricao: string }[],
  ) => {
    if (isViewMode) return;

    // Ensure all keywords have the required fields according to TagItem interface
    const formattedKeywords = keywords.map((tag) => ({
      tag_id: tag.tag_id,
      descricao: tag.descricao || "",
    }));

    dispatch(
      updateDescriptionField({
        field: "palavras_chave",
        value: formattedKeywords,
      }),
    );
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleKeyPress = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (isViewMode) return;

    if (e.key === "Enter" && inputValue.trim()) {
      e.preventDefault();

      try {
        setError(null);
        const result = await addTag({
          descricao: inputValue.trim(),
          categoria: "palavras_chave",
        }).unwrap();

        const newTag: TagItem = {
          tag_id: result.data.tag_id,
          descricao: result.data.descricao,
        };

        const tagAlreadyExists = (formData.palavras_chave || []).some(
          (tag) =>
            tag.tag_id === newTag.tag_id ||
            tag.descricao.toLowerCase() === newTag.descricao.toLowerCase(),
        );

        if (!tagAlreadyExists) {
          const updatedTags = [...(formData.palavras_chave || []), newTag];
          handleKeywordsChange(updatedTags);
        }

        setInputValue("");
      } catch (err: any) {
        setError(
          err?.data?.mensagem || t("project.descriptionStep.errorAddingTag"),
        );
      }
    }
  };

  const handleRemoveTag = (tagId: number) => {
    if (isViewMode) return;

    try {
      setError(null);
      const updatedTags = (formData.palavras_chave || []).filter(
        (tag) => tag.tag_id !== tagId,
      );
      handleKeywordsChange(updatedTags);
    } catch (err: any) {
      setError(
        err?.data?.mensagem || t("project.descriptionStep.errorRemovingTag"),
      );
    }
  };

  // Save form data to API
  const handleSave = useCallback(async () => {
    if (isViewMode || !currentProjectId) return false;

    setError(null);
    setIsSaving(true);

    try {
      const response = await saveDescriptionStep({
        projectId: currentProjectId,
        data: formData,
      }).unwrap();

      if (onSaveStep) {
        onSaveStep(true, response.data.projeto_id);
      }

      return true;
    } catch (err: any) {
      const errorMessage =
        err?.data?.mensagem || t("project.descriptionStep.errorSaving");

      setError(errorMessage);

      if (onSaveStep) {
        onSaveStep(false);
      }

      return false;
    } finally {
      setIsSaving(false);
    }
  }, [
    currentProjectId,
    formData,
    isViewMode,
    onSaveStep,
    saveDescriptionStep,
    t,
  ]);

  return {
    formData,
    inputValue,
    error,
    isLoadingDescription,
    isSaving,
    isAddingTag,
    handleTextareaChange,
    handleKeywordsChange,
    handleInputChange,
    handleKeyPress,
    handleRemoveTag,
    handleSave,
    setError,
  };
};
