// src/features/company/components/CompanyForm/config/index.ts
import { CrudConfig, CrudMode } from "features/crud/types";
import { Company } from "features/company/types";
import CNPJInput from "common/components/Input/common/components/CNPJ";
import IEInput from "common/components/Input/common/components/IE";
import UnifiedLocationField, {
  LocationFormData,
} from "features/location/components/UnifiedLocationForm";
import CompanyType from "common/components/Select/common/components/CompanyType";
import CompanySegment from "common/components/Select/common/components/CompanySegment";
import BusinessGroup from "common/components/Select/common/components/BusinessGroup";
import LogoUpload from "features/company/components/CompanyForm/components/LogoUpload";
import { registerEntityTransformer } from "features/crud/utils/transformer";
import LogoPreview from "features/company/components/CompanyForm/components/LogoPreview";
import CodigoDutoInput from "common/components/Input/common/components/CodigoDuto";

registerEntityTransformer("empresa", {
  request: (data: Partial<Company>) => {
    const locationData = data.locationData as LocationFormData;

    const stripNonNumeric = (str: string | undefined) =>
      str?.replace(/\D/g, "") || "";

    let grupo_empresarial_id = 0;
    if (typeof data.grupo_empresarial === "number") {
      grupo_empresarial_id = data.grupo_empresarial;
    } else if (
      typeof data.grupo_empresarial === "object" &&
      data.grupo_empresarial
    ) {
      grupo_empresarial_id = data.grupo_empresarial.grupo_empresarial_id || 0;
    }

    let tipo_empresa_id = 0;
    if (typeof data.tipo_empresa === "number") {
      tipo_empresa_id = data.tipo_empresa;
    } else if (typeof data.tipo_empresa === "object" && data.tipo_empresa) {
      tipo_empresa_id = data.tipo_empresa.tipo_empresa_id || 0;
    }

    let segmento_id = 0;
    if (typeof data.segmento === "number") {
      segmento_id = data.segmento;
    } else if (typeof data.segmento === "object" && data.segmento) {
      segmento_id = data.segmento.segmento_id || 0;
    }

    const apiPayload = {
      grupo_empresarial_id,
      tipo_empresa_id,
      segmento_id,
      razao_social: data.razao_social,
      nome_fantasia: data.nome_fantasia,
      cnpj: stripNonNumeric(data.cnpj),
      inscricao_estadual:
        data.inscricao_estadual === "ISENTO"
          ? "ISENTO"
          : stripNonNumeric(data.inscricao_estadual),
      endereco: locationData?.endereco || data.endereco,
      numero: locationData?.numero || data.numero,
      bairro: locationData?.bairro || data.bairro,
      municipio_ibge_id: locationData?.municipio_ibge_id || 0,
      uf_id: locationData?.uf_id || 0,
      cep: stripNonNumeric(locationData?.cep || data.cep),
      telefone:
        stripNonNumeric(locationData?.telefone) ||
        stripNonNumeric(data.telefone),
      dominios_empresa: data.dominios_empresa || "",
      codigo_duto: data.codigo_duto || "",
      pessoa_contato: data.pessoa_contato || "",
      logo_empresa_base64: data.logo_empresa_base64 || "",
      ativa: typeof data.ativa === "boolean" ? data.ativa : true,
    };

    return apiPayload;
  },
  response: (data: Record<string, unknown>) => {
    const companyData = data as unknown as Company;

    const locationData: LocationFormData = {
      cep: companyData.cep,
      endereco: companyData.endereco,
      numero: companyData.numero,
      bairro: companyData.bairro,
      municipio_ibge_id: companyData.municipio_ibge?.municipio_id,
      uf_id: companyData.municipio_ibge?.uf_ibge?.uf_id,
      telefone: companyData.telefone,
    };

    return {
      id: companyData.empresa_id,
      grupo_empresarial: companyData.grupo_empresarial,
      tipo_empresa: companyData.tipo_empresa,
      segmento: companyData.segmento,
      razao_social: companyData.razao_social,
      nome_fantasia: companyData.nome_fantasia,
      cnpj: companyData.cnpj,
      inscricao_estadual: companyData.inscricao_estadual,
      locationData,
      dominios_empresa: companyData.dominios_empresa || "",
      codigo_duto: companyData.codigo_duto || "",
      pessoa_contato: companyData.pessoa_contato || "",
      logo_empresa: companyData.logo_empresa || "",
      logo_empresa_base64: companyData.logo_empresa_base64 || "",
      ativa: companyData.ativa ?? true,
    };
  },
});

export const getCompanyCrudConfig = (mode: CrudMode): CrudConfig<Company> => ({
  entityName: "empresa",
  endpoints: {
    list: "empresa",
    getById: "empresa",
    create: "empresa",
    update: "empresa",
    delete: "empresa",
  },
  fields: [
    {
      name: "grupo_empresarial",
      label: "Grupo Empresarial",
      type: mode === "view" || mode === "edit" ? "text" : "component",
      required: true,
      component: mode === "view" || mode === "edit" ? undefined : BusinessGroup,
      transformValue: {
        input: (value) => {
          if (value === null || value === undefined) return 0;
          if (typeof value === "number") return value;
          if (typeof value === "object" && "grupo_empresarial_id" in value) {
            return (value as any).grupo_empresarial_id;
          }
          return 0;
        },
        output: (value) => {
          if (!value) return null;
          const numValue = Number(value);
          return numValue || 0;
        },
        display: (value) => {
          if (!value) return "";
          if (typeof value === "object" && "nome_grupo_empresarial" in value) {
            return (value as any).nome_grupo_empresarial;
          }
          return value.toString();
        },
      },
    },
    {
      name: "tipo_empresa",
      label: "Tipo de Empresa",
      type: mode === "view" ? "text" : "component",
      required: true,
      component: mode === "view" ? undefined : CompanyType,
      transformValue: {
        input: (value) => {
          if (value === null || value === undefined) return 0;
          if (typeof value === "number") return value;
          if (typeof value === "object" && "tipo_empresa_id" in value) {
            return (value as any).tipo_empresa_id;
          }
          return 0;
        },
        output: (value) => {
          if (!value) return null;
          const numValue = Number(value);
          return numValue || 0;
        },
        display: (value) => {
          if (!value) return "";
          if (typeof value === "object" && "nome" in value) {
            return (value as any).nome;
          }
          return value.toString();
        },
      },
    },
    {
      name: "segmento",
      label: "Segmento",
      type: mode === "view" || mode === "edit" ? "text" : "component",
      required: true,
      component:
        mode === "view" || mode === "edit" ? undefined : CompanySegment,
      transformValue: {
        input: (value) => {
          if (value === null || value === undefined) return 0;
          if (typeof value === "number") return value;
          if (typeof value === "object" && "segmento_id" in value) {
            return (value as any).segmento_id;
          }
          return 0;
        },
        output: (value) => {
          if (!value) return null;
          const numValue = Number(value);
          return numValue || 0;
        },
        display: (value) => {
          if (!value) return "";
          if (typeof value === "object" && "nome_segmento" in value) {
            return (value as any).nome_segmento;
          }
          return value.toString();
        },
      },
    },
    {
      name: "cnpj",
      label: "CNPJ",
      type: mode === "view" || mode === "edit" ? "text" : "component",
      required: true,
      component: mode === "view" || mode === "edit" ? undefined : CNPJInput,
    },
    {
      name: "inscricao_estadual",
      label: "Inscrição Estadual",
      type: "component",
      component: IEInput,
      placeholder: "ISENTO",
    },
    {
      name: "razao_social",
      label: "Razão Social",
      type: "text",
      required: true,
    },
    {
      name: "nome_fantasia",
      label: "Nome Fantasia",
      type: "text",
      required: true,
    },
    {
      name: "locationData",
      label: "",
      type: "component",
      component: UnifiedLocationField,
      required: true,
      componentProps: {
        mode,
      },
      transformValue: {
        input: (value: unknown): LocationFormData => {
          if (typeof value === "object" && value !== null) {
            const data = value as Partial<LocationFormData>;
            return {
              cep: data.cep || "",
              endereco: data.endereco || "",
              numero: data.numero || "",
              bairro: data.bairro || "",
              municipio_ibge_id: data.municipio_ibge_id || 0,
              uf_id: data.uf_id || 0,
              telefone: data.telefone || "",
            };
          }
          return {
            cep: "",
            endereco: "",
            numero: "",
            bairro: "",
            municipio_ibge_id: 0,
            uf_id: 0,
            telefone: "",
          };
        },
        output: (value: unknown) => value as LocationFormData,
      },
    },
    {
      name: "dominios_empresa",
      label: "Domínios",
      type: "text",
      placeholder: "@minhaempresa.com;@empresa.com.br",
    },
    {
      name: "codigo_duto",
      label: "Código Duto",
      type: "component",
      component: CodigoDutoInput,
      placeholder: "1a2b3c4d5e",
    },
    {
      name: "pessoa_contato",
      label: "Pessoa de contato",
      type: "text",
    },
    {
      name: mode === "view" ? "logo_empresa" : "logo_empresa_base64",
      label: "Logo da empresa",
      type: "component",
      component: mode === "view" ? LogoPreview : LogoUpload,
      transformValue: {
        input: (value: unknown): string => (value as string) || "",
        output: (value: unknown): string => (value as string) || "",
        display: (value: unknown, data?: Partial<Company>): string => {
          return data?.logo_empresa || (value as string) || "";
        },
      },
    },
  ],
  layout: {
    groups: [
      {
        fields: ["grupo_empresarial"],
        columns: { sm: 1 },
        fieldLayouts: {
          grupo_empresarial: {
            colSpan: { sm: 12 },
          },
        },
      },
      {
        fields: ["tipo_empresa", "segmento", "cnpj", "inscricao_estadual"],
        columns: { sm: 1, md: 2 },
      },
      {
        fields: ["razao_social", "nome_fantasia"],
        columns: { sm: 1, md: 2 },
      },
      {
        fields: ["locationData"],
        columns: { sm: 1 },
      },
      {
        fields: ["pessoa_contato", "dominios_empresa", "codigo_duto"],
        columns: { sm: 1, md: 12 },
        fieldLayouts: {
          pessoa_contato: {
            colSpan: { sm: 12, md: 3 },
          },
          dominios_empresa: {
            colSpan: { sm: 12, md: 6 },
          },
          codigo_duto: {
            colSpan: { sm: 12, md: 3 },
          },
        },
      },
      {
        fields: [mode === "view" ? "logo_empresa" : "logo_empresa_base64"],
        columns: { sm: 1 },
      },
    ],
  },
});

export const companyCrudConfig = getCompanyCrudConfig("create");
