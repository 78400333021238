import { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useSaveEntitiesStepMutation,
  useGetProjectEntitiesQuery,
  useGetProjectEntityTypeQuery,
  useGetExecutingCompaniesQuery,
} from "features/project/api";
import { useGetCompanyByBusinessGroupQuery } from "features/businessGroup/api";
import { RootState } from "store";
import {
  updateEntitiesField,
  updateProponentsCooperadas,
  updateExecutoras,
  EntitiesFormState,
} from "features/project/slices/projectFormSlice";

interface UseEntitiesProps {
  initialProjectId?: number;
  viewMode?: "view" | "edit" | "create";
  onSaveStep?: (success: boolean, projectId?: number) => void;
}

export const useEntities = ({
  initialProjectId,
  viewMode = "edit",
  onSaveStep,
}: UseEntitiesProps) => {
  const dispatch = useDispatch();
  const isViewMode = viewMode === "view";
  const isCreateMode = viewMode === "create";

  // ===========================================
  // GLOBAL STATE (REDUX)
  // ===========================================

  // Form state from Redux store (persists across navigation)
  const formData = useSelector(
    (state: RootState) => state.projectForm.entities,
  );

  // Get identification data from redux store for creating mode
  const identificationData = useSelector(
    (state: RootState) => state.projectForm.identification,
  );

  const loggedCompany = useSelector(
    (state: RootState) => state.auth.user?.empresa_logada,
  );

  // ===========================================
  // LOCAL STATE (COMPONENT-SPECIFIC)
  // ===========================================

  // UI and operation state (transient, only relevant to this component)
  const [currentProjectId, setCurrentProjectId] = useState<number | undefined>(
    initialProjectId,
  );
  const [error, setError] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  // ===========================================
  // API QUERIES AND MUTATIONS (RTK QUERY)
  // ===========================================

  const [saveEntitiesStep] = useSaveEntitiesStepMutation();

  const { data: entitiesData, isFetching: isLoadingEntities } =
    useGetProjectEntitiesQuery(currentProjectId as number, {
      skip: !currentProjectId || isCreateMode,
    });

  // Options loading through RTK Query
  const {
    data: companyByBusinessGroupData,
    isLoading: isLoadingCompanyByBusinessGroup,
    refetch: refetchCompanyByBusinessGroup,
  } = useGetCompanyByBusinessGroupQuery();

  const { data: entityTypeData, isLoading: isLoadingEntityType } =
    useGetProjectEntityTypeQuery({ tipos: [1, 2] });

  const {
    data: executingCompaniesData,
    isLoading: isLoadingExecutingCompanies,
    refetch: refetchExecutingCompanies,
  } = useGetExecutingCompaniesQuery({});

  // ===========================================
  // DERIVED STATE (COMPUTED VALUES)
  // ===========================================

  const entityTypeOptions = useMemo(() => {
    if (entityTypeData?.data) {
      return entityTypeData.data.map((item) => ({
        value: String(item.tipo_entidade_id),
        label: item.descricao,
      }));
    }
    return [];
  }, [entityTypeData]);

  const companyByBusinessGroupOptions = useMemo(() => {
    if (companyByBusinessGroupData?.data) {
      return companyByBusinessGroupData.data.empresas.map((item) => ({
        value: String(item.empresa_id),
        label: item.nome_fantasia,
      }));
    }
    return [];
  }, [companyByBusinessGroupData]);

  const executingCompaniesOptions = useMemo(() => {
    if (executingCompaniesData?.data?.result) {
      return executingCompaniesData.data.result.map((item) => ({
        value: String(item.executora_id),
        label: item.nome_fantasia,
      }));
    }
    return [];
  }, [executingCompaniesData]);

  // ===========================================
  // SIDE EFFECTS
  // ===========================================

  // Initialize project ID from props
  useEffect(() => {
    if (initialProjectId) {
      setCurrentProjectId(initialProjectId);
    }
  }, [initialProjectId]);

  // Get financial information from identification step when in create mode
  useEffect(() => {
    if (isCreateMode) {
      // Update entities financial values from identification step
      dispatch(
        updateEntitiesField({
          field: "vl_projeto_total",
          value: identificationData.vl_projeto_total || 0,
        }),
      );
      dispatch(
        updateEntitiesField({
          field: "vl_repasse_total",
          value: identificationData.vl_repasse_total || 0,
        }),
      );
      dispatch(
        updateEntitiesField({
          field: "vl_contrapartida",
          value: identificationData.vl_contrapartida || 0,
        }),
      );
      dispatch(
        updateEntitiesField({
          field: "vl_recurso_interno",
          value: identificationData.vl_recurso_interno || 0,
        }),
      );
    }
  }, [dispatch, isCreateMode, identificationData]);

  useEffect(() => {
    if (isViewMode || isInitialized) return;

    if (
      !loggedCompany ||
      !entityTypeData?.data ||
      !formData ||
      formData.vl_repasse_total <= 0
    )
      return;

    if (
      formData.proponentes_cooperadas &&
      formData.proponentes_cooperadas.length > 0
    )
      return;

    const proponenteTypeId = entityTypeData.data.find(
      (type) => type.descricao === "Proponente",
    )?.tipo_entidade_id;

    if (proponenteTypeId) {
      const newProponent = {
        empresa_cooperada: {
          empresa_id: loggedCompany.empresa_id,
          nome_fantasia: loggedCompany.nome_fantasia,
          razao_social: loggedCompany.razao_social || "",
        },
        tipo_entidade: {
          tipo_entidade_id: proponenteTypeId,
          descricao: "Proponente",
        },
        valor_repasse: formData.vl_repasse_total,
        recurso_interno: formData.vl_recurso_interno,
      };

      dispatch(updateProponentsCooperadas([newProponent]));
      setIsInitialized(true);
    }
  }, [
    isViewMode,
    isInitialized,
    loggedCompany,
    entityTypeData,
    formData,
    dispatch,
  ]);

  useEffect(() => {
    if (entitiesData?.data && currentProjectId && !isCreateMode) {
      const project = entitiesData.data;

      dispatch(
        updateEntitiesField({
          field: "vl_projeto_total",
          value: project.vl_projeto_total || 0,
        }),
      );

      dispatch(
        updateEntitiesField({
          field: "vl_repasse_total",
          value: project.vl_repasse_total || 0,
        }),
      );
      dispatch(
        updateEntitiesField({
          field: "vl_contrapartida",
          value: project.vl_contrapartida || 0,
        }),
      );
      dispatch(
        updateEntitiesField({
          field: "vl_recurso_interno",
          value: project.vl_recurso_interno || 0,
        }),
      );

      if (project.proponentes_cooperadas?.length > 0) {
        const transformedProponentes = project.proponentes_cooperadas.map(
          (item) => ({
            projeto_entidade_id: item.projeto_entidade_id,
            empresa_cooperada: {
              empresa_id: item.empresa?.empresa_id,
              nome_fantasia: item.empresa?.nome_fantasia,
              razao_social: item.empresa?.razao_social || "",
            },
            tipo_entidade: {
              tipo_entidade_id: item.tipo_entidade?.tipo_entidade_id,
              descricao: item.tipo_entidade?.descricao,
            },
            valor_repasse: item.valor_repasse,
            recurso_interno: item.recurso_interno,
          }),
        );

        dispatch(updateProponentsCooperadas(transformedProponentes));
      }

      if (project.executoras?.length > 0) {
        const transformedExecutoras = project.executoras.map((item) => ({
          projeto_entidade_id: item.projeto_entidade_id,
          empresa_executora: {
            empresa_id: item.empresa_executora?.empresa_id,
            nome_fantasia: item.empresa_executora?.nome_fantasia,
            razao_social: item.empresa_executora?.razao_social || "",
          },
          tipo_entidade: {
            tipo_entidade_id: item.tipo_entidade?.tipo_entidade_id,
            descricao: item.tipo_entidade?.descricao,
          },
          valor_repasse: item.valor_repasse,
          contrapartida: item.contrapartida,
        }));

        dispatch(updateExecutoras(transformedExecutoras));
      }
    }
  }, [entitiesData, dispatch, currentProjectId, isCreateMode]);

  // ===========================================
  // EVENT HANDLERS
  // ===========================================

  // Handle form field changes (updates Redux store)
  const handleUpdateProponents = useCallback(
    (proponents: EntitiesFormState["proponentes_cooperadas"]) => {
      dispatch(updateProponentsCooperadas(proponents));
    },
    [dispatch],
  );

  // Handle form field changes for executoras (updates Redux store)
  const handleUpdateExecuting = useCallback(
    (executoras: EntitiesFormState["executoras"]) => {
      dispatch(updateExecutoras(executoras));
    },
    [dispatch],
  );

  // Save form data to API
  const handleSave = useCallback(async () => {
    if (isViewMode || !currentProjectId) return false;

    setError(null);
    setIsSaving(true);

    try {
      const response = await saveEntitiesStep({
        projectId: currentProjectId,
        data: formData,
      }).unwrap();

      if (onSaveStep) {
        onSaveStep(true, response.data.projeto_id);
      }

      return true;
    } catch (err: any) {
      const errorMessage = err?.data?.mensagem || "Erro ao salvar entidades";

      setError(errorMessage);

      if (onSaveStep) {
        onSaveStep(false);
      }

      return false;
    } finally {
      setIsSaving(false);
    }
  }, [isViewMode, currentProjectId, formData, onSaveStep, saveEntitiesStep]);
  return {
    formData,
    error,
    entityTypeOptions,
    companyByBusinessGroupOptions,
    executingCompaniesOptions,
    isLoadingEntities,
    isLoadingEntityType,
    isLoadingCompanyByBusinessGroup,
    isLoadingExecutingCompanies,
    isSaving,
    handleSave,
    handleUpdateProponents,
    handleUpdateExecuting,
    setError,
    refetchCompanyByBusinessGroup,
    refetchExecutingCompanies,
  };
};
