// src/common/components/Select/common/components/CompanyType/index.tsx
import Select from "common/components/Select";
import { useGetCompanyTypeQuery } from "features/company/api";
import { CrudComponentProps } from "features/crud/types";
import { useTranslation } from "react-i18next";

const CompanyTypeSelect = ({
  value,
  onChange,
  error,
  disabled,
  className,
}: CrudComponentProps<number>) => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetCompanyTypeQuery();

  // Extract the ID value
  const currentValue =
    typeof value === "object" && value !== null
      ? (value as any).tipo_empresa_id?.toString()
      : typeof value === "number"
        ? value.toString()
        : "";

  const options =
    data?.data.map((item) => ({
      value: item.tipo_empresa_id.toString(),
      label: item.nome,
    })) ?? [];

  const handleChange = (selectedValue: string) => {
    if (!selectedValue) {
      onChange?.(0);
      return;
    }

    const numValue = Number(selectedValue);
    if (!Number.isNaN(numValue)) {
      const selectedItem = data?.data.find(
        (item) => item.tipo_empresa_id.toString() === selectedValue,
      );

      if (selectedItem) {
        onChange?.(selectedItem.tipo_empresa_id);
      } else {
        onChange?.(numValue);
      }
    }
  };

  return (
    <Select
      label={t("companyType.label")}
      options={options}
      value={currentValue}
      onChange={handleChange}
      className={className}
      isLoading={isLoading}
      disabled={disabled}
      error={error}
      searchable={true}
      required
    />
  );
};

export default CompanyTypeSelect;
