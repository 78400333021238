// src/common/components/Input/common/components/UnifiedLocationForm/api/index.ts
export const FetchCepData = async (cep: string) => {
  try {
    const cleanCep = cep.replace(/\D/g, "");

    const response = await fetch(`https://viacep.com.br/ws/${cleanCep}/json/`);
    const data = await response.json();

    if (!data.erro) {
      const transformedData = {
        cep: cep,
        localidade: data.localidade,
        logradouro: data.logradouro,
        complemento: data.complemento,
        bairro: data.bairro,
        uf: data.uf,
        ibge: data.ibge,
      };

      return transformedData;
    } else {
      return null;
    }
  } catch (error) {
    throw error;
  }
};

export default FetchCepData;
