import { createApi } from "@reduxjs/toolkit/query/react";
import type {
  TableQueryParams,
  ApiResponse,
} from "common/components/ServerTable/types";
import { baseQueryWithAuth } from "config/baseQuery";

export const serverTableApi = createApi({
  reducerPath: "serverTableApi",
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    getTableData: builder.query<
      ApiResponse<Record<string, unknown>>,
      {
        endpoint: string;
        params: TableQueryParams;
      }
    >({
      query: ({
        endpoint,
        params,
      }: {
        endpoint: string;
        params: TableQueryParams;
      }) => ({
        url: endpoint,
        params: {
          filtro_generico: params.filtro_generico,
          page: params.page,
          perPage: params.perPage || 10,
          sortDirection: params.sortDirection || "desc",
          sortBy: params.sortBy,
        },
      }),
    }),
  }),
});

export const { useGetTableDataQuery } = serverTableApi;
