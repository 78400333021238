import Button from "common/components/Button";

interface RoleUser {
  id: number;
  name: string;
  status?: "pending" | "active";
  projeto_usuario_papel_id?: number;
}

interface RoleColumnProps {
  title: string;
  users: RoleUser[];
  isViewMode?: boolean;
  allowsInvite?: boolean;
  onRemoveUser?: (user: RoleUser) => void;
  onHeaderClick?: (
    roleId: number,
    roleTitle: string,
    allowsInvite: boolean,
  ) => void;
  roleId: number;
}

const RoleColumn = ({
  title,
  users,
  isViewMode = false,
  allowsInvite = true,
  onRemoveUser,
  onHeaderClick,
  roleId,
}: RoleColumnProps) => {
  const handleRemoveUser = (user: RoleUser) => {
    if (user.projeto_usuario_papel_id && onRemoveUser) {
      onRemoveUser(user);
    }
  };

  const handleHeaderClick = () => {
    if (onHeaderClick && !isViewMode) {
      onHeaderClick(roleId, title, allowsInvite);
    }
  };

  return (
    <div className="flex flex-col border border-gray-200 rounded-md overflow-hidden">
      <Button
        className="w-full rounded-t-md rounded-b-none py-2 text-center font-semibold text-xs"
        disabled={isViewMode}
        onClick={handleHeaderClick}
      >
        {title} {"+"}
      </Button>
      <div className="flex flex-col p-2 bg-white">
        {users.length > 0 ? (
          <ul className="space-y-2">
            {users.map((user) => (
              <li
                key={`${user.projeto_usuario_papel_id || ""}-${user.id}`}
                className="flex items-center justify-between border-b border-gray-100 pb-1"
              >
                <span>{user.name}</span>
                {user.status === "pending" && (
                  <span className="text-xs text-gray-500">- Pendente</span>
                )}
                {!isViewMode &&
                  allowsInvite &&
                  user.projeto_usuario_papel_id && (
                    <button
                      className="text-red-500 text-xl"
                      onClick={() => handleRemoveUser(user)}
                      aria-label="Remover usuário"
                    >
                      ×
                    </button>
                  )}
              </li>
            ))}
          </ul>
        ) : (
          <div className="text-center py-4 text-gray-400">
            Nenhum usuário associado
          </div>
        )}
      </div>
    </div>
  );
};

export default RoleColumn;
