export const DEFAULT_TABLE_STATE = {
  currentPage: 1,
  perPage: 10,
  sortDirection: "desc" as const,
  sortBy: "",
  filtro_generico: "",
  perfil_ativo: false,
} as const;

export const PER_PAGE_OPTIONS = [5, 10, 25, 50] as const;
