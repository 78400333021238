import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import {
  updateIdentificationField,
  type IdentificationFormState,
} from "features/project/slices/projectFormSlice";
import {
  useSaveIdentificationStepMutation,
  useGetProjectIdentificationQuery,
  useGetProjectProductTypeQuery,
  useGetProjectInnovationPhaseQuery,
  useGetProjectSharingTypeQuery,
  useGetProjectTrlQuery,
} from "features/project/api";

interface UseIdentificationProps {
  initialProjectId?: number;
  viewMode?: "view" | "edit" | "create";
  onSaveStep?: (success: boolean, projectId?: number) => void;
}

export const useIdentification = ({
  initialProjectId,
  viewMode = "edit",
  onSaveStep,
}: UseIdentificationProps) => {
  const dispatch = useDispatch();
  const isViewMode = viewMode === "view";
  const isCreateMode = viewMode === "create";

  // ===========================================
  // GLOBAL STATE (REDUX)
  // ===========================================

  // Form state from Redux store (persists across navigation)
  const formData = useSelector(
    (state: RootState) => state.projectForm.identification,
  );

  // ===========================================
  // LOCAL STATE (COMPONENT-SPECIFIC)
  // ===========================================

  // UI and operation state (transient, only relevant to this component)
  const [currentProjectId, setCurrentProjectId] = useState<number | undefined>(
    initialProjectId,
  );
  const [error, setError] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState(false);

  // ===========================================
  // API QUERIES AND MUTATIONS (RTK QUERY)
  // ===========================================

  // Data fetching through RTK Query (handles caching and loading states)
  const [saveIdentificationStep, { isLoading }] =
    useSaveIdentificationStepMutation();

  const { data: apiData, isFetching: isLoadingData } =
    useGetProjectIdentificationQuery(currentProjectId as number, {
      skip: !currentProjectId || isCreateMode,
    });

  // Options loading through RTK Query
  const { data: productTypeData, isLoading: isLoadingProductType } =
    useGetProjectProductTypeQuery();

  const { data: innovationPhaseData, isLoading: isLoadingInnovationPhase } =
    useGetProjectInnovationPhaseQuery();

  const { data: sharingTypeData, isLoading: isLoadingSharingType } =
    useGetProjectSharingTypeQuery();

  const { data: trlData, isLoading: isLoadingTrl } = useGetProjectTrlQuery();

  // ===========================================
  // DERIVED STATE (COMPUTED VALUES)
  // ===========================================

  // Format option lists for dropdown components (derived from API data)
  const productTypeOptions = useMemo(() => {
    if (productTypeData?.data) {
      return productTypeData.data.map((item) => ({
        value: item.tipo_produto_projeto_id,
        label: item.descricao,
      }));
    }
    return [];
  }, [productTypeData]);

  const innovationPhaseOptions = useMemo(() => {
    if (innovationPhaseData?.data) {
      return innovationPhaseData.data.map((item) => ({
        value: item.fase_inovacao_id,
        label: item.descricao,
      }));
    }
    return [];
  }, [innovationPhaseData]);

  const sharingTypeOptions = useMemo(() => {
    if (sharingTypeData?.data) {
      return sharingTypeData.data.map((item) => ({
        value: item.tipo_compartilhamento_id,
        label: item.descricao,
      }));
    }
    return [];
  }, [sharingTypeData]);

  const trlOptions = useMemo(() => {
    if (trlData?.data) {
      return trlData.data.map((item) => ({
        value: item.trl_id.toString(),
        label: item.descricao,
      }));
    }
    return [];
  }, [trlData]);

  // ===========================================
  // SIDE EFFECTS
  // ===========================================

  // Initialize project ID from props
  useEffect(() => {
    if (initialProjectId) {
      setCurrentProjectId(initialProjectId);
    }
  }, [initialProjectId]);

  // Update Redux store when project data is loaded from API
  useEffect(() => {
    if (apiData?.data && currentProjectId && !isCreateMode) {
      const project = apiData.data;

      // Update each field individually with direct dispatches
      dispatch(
        updateIdentificationField({
          field: "nome",
          value: project.nome || "",
        }),
      );
      dispatch(
        updateIdentificationField({
          field: "titulo",
          value: project.titulo || "",
        }),
      );
      dispatch(
        updateIdentificationField({
          field: "titulo_resumido",
          value: project.titulo_resumido || "",
        }),
      );
      dispatch(
        updateIdentificationField({
          field: "codigo_ods",
          value: project.codigo_ods || "",
        }),
      );
      dispatch(
        updateIdentificationField({
          field: "codigo_proposta",
          value: project.codigo_proposta || "",
        }),
      );
      dispatch(
        updateIdentificationField({
          field: "codigo_aneel",
          value: project.codigo_aneel || "",
        }),
      );
      dispatch(
        updateIdentificationField({
          field: "captacao",
          value: project.captacao || "",
        }),
      );
      dispatch(
        updateIdentificationField({
          field: "codigo_tema",
          value: project.codigo_tema || "",
        }),
      );
      dispatch(
        updateIdentificationField({
          field: "outro_tema",
          value: project.outro_tema || "",
        }),
      );
      dispatch(
        updateIdentificationField({
          field: "codigo_subtema",
          value: project.codigo_subtema || "",
        }),
      );
      dispatch(
        updateIdentificationField({
          field: "outro_subtema",
          value: project.outro_subtema || "",
        }),
      );
      dispatch(
        updateIdentificationField({
          field: "tipo_projeto_id",
          value: project.tipo_projeto?.tipo_projeto_id?.toString() || "",
        }),
      );
      dispatch(
        updateIdentificationField({
          field: "duracao",
          value: project.duracao?.toString() || "",
        }),
      );
      dispatch(
        updateIdentificationField({
          field: "trl_inicial",
          value: project.trl_inicial?.toString() || "",
        }),
      );
      dispatch(
        updateIdentificationField({
          field: "trl_final",
          value: project.trl_final?.toString() || "",
        }),
      );
      dispatch(
        updateIdentificationField({
          field: "vl_projeto_total",
          value: project.vl_projeto_total || 0,
        }),
      );
      dispatch(
        updateIdentificationField({
          field: "vl_repasse_total",
          value: project.vl_repasse_total || 0,
        }),
      );
      dispatch(
        updateIdentificationField({
          field: "vl_contrapartida",
          value: project.vl_contrapartida || 0,
        }),
      );
      dispatch(
        updateIdentificationField({
          field: "vl_recurso_interno",
          value: project.vl_recurso_interno || 0,
        }),
      );
      dispatch(
        updateIdentificationField({
          field: "data_inicio_prevista",
          value: project.data_inicio_prevista || null,
        }),
      );
      dispatch(
        updateIdentificationField({
          field: "projectTypeDisplay",
          value: project.tipo_projeto
            ? `${project.tipo_projeto.tipo_projeto_id} - ${project.tipo_projeto.sigla} - ${project.tipo_projeto.descricao}`
            : "",
        }),
      );
      dispatch(
        updateIdentificationField({
          field: "tipo_produto_projeto_id",
          value: project.tipo_produto_projeto?.tipo_produto_projeto_id || "",
        }),
      );
      dispatch(
        updateIdentificationField({
          field: "fase_inovacao_projeto_id",
          value: project.fase_inovacao_projeto?.fase_inovacao_id || "",
        }),
      );
      dispatch(
        updateIdentificationField({
          field: "tipo_compartilhamento_projeto_id",
          value:
            project.tipo_compartilhamento_projeto?.tipo_compartilhamento_id ||
            "",
        }),
      );
    }
  }, [apiData, dispatch, currentProjectId, isCreateMode]);

  // ===========================================
  // EVENT HANDLERS
  // ===========================================

  // Handle form field changes (updates Redux store)
  const handleInputChange = (
    field: keyof IdentificationFormState,
    value: string | number | Date | null,
  ) => {
    if (isViewMode) return;

    // Update global Redux state with the new field value
    if (value instanceof Date) {
      dispatch(
        updateIdentificationField({
          field: field,
          value: value.toISOString(),
        }),
      );
    } else {
      if (
        field === "duracao" ||
        field === "trl_inicial" ||
        field === "trl_final" ||
        field === "tipo_projeto_id"
      ) {
        dispatch(
          updateIdentificationField({
            field: field,
            value: String(value),
          }),
        );
      } else {
        dispatch(
          updateIdentificationField({
            field: field,
            value: value,
          }),
        );
      }
    }
  };

  const handleCurrencyChange = (
    field: keyof IdentificationFormState,
    value: string,
  ) => {
    if (isViewMode) return;

    // Update global Redux state with formatted currency value
    const numericValue = parseFloat(value.replace(/\D/g, "")) / 100;
    dispatch(
      updateIdentificationField({
        field: field,
        value: numericValue,
      }),
    );
  };

  const handleNumericInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const allowedKeys = [
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
    ];

    if (!/^\d$/.test(e.key) && !allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  // Save form data to API
  const handleSave = async () => {
    if (isViewMode) return true;

    // Update local UI state for save operation
    setError(null);
    setIsSaving(true);

    try {
      // API call to save data
      const result = await saveIdentificationStep({
        projectId: currentProjectId,
        data: formData,
      }).unwrap();

      // Update local state with new project ID if creating
      const projectId = result.data?.projeto_id || currentProjectId;
      if (!currentProjectId && projectId) {
        setCurrentProjectId(projectId);
      }

      // Callback to parent component
      if (onSaveStep) {
        onSaveStep(true, projectId);
      }

      return true;
    } catch (error: any) {
      // Handle error in local state
      const errorMessage =
        error?.data?.mensagem || "Erro ao salvar identificação";
      setError(errorMessage);

      if (onSaveStep) {
        onSaveStep(false);
      }

      return false;
    } finally {
      setIsSaving(false);
    }
  };

  return {
    formData,
    handleInputChange,
    handleCurrencyChange,
    handleNumericInput,
    productTypeOptions,
    innovationPhaseOptions,
    sharingTypeOptions,
    trlOptions,
    isLoadingProductType,
    isLoadingInnovationPhase,
    isLoadingSharingType,
    isLoadingTrl,
    isLoadingData,
    isLoading,
    isSaving,
    error,
    setError,
    handleSave,
    currentProjectId,
  };
};
