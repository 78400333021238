import { useTranslation } from "react-i18next";
import TagInput from "features/project/components/PDIProject/components/Project/components/Stepper/steps/Description/components/TagInput";
import { DescriptionFormState } from "features/project/slices/projectFormSlice";
import { TagItem } from "features/project/types";

interface ProjectKeywordsSectionProps {
  formState: DescriptionFormState;
  isViewMode: boolean;
  handleKeywordsChange: (keywords: TagItem[]) => void;
  onError: (error: string | null) => void;
  inputValue: string;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleKeyPress: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  handleRemoveTag: (tagId: number) => void;
  isAddingTag: boolean;
}

const ProjectKeywordsSection = ({
  formState,
  isViewMode,
  inputValue,
  isAddingTag,
  handleInputChange,
  handleKeyPress,
  handleRemoveTag,
}: ProjectKeywordsSectionProps) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-3 p-2 rounded-md border border-[--table-header-bg] bg-[--breadcrumb-bg] shadow-sm">
      <h4 className="text-sm text-primary-dark font-semibold p-1.5 bg-[--primary-light] rounded-md shadow-sm text-center">
        {t("project.descriptionStep.sections.keywords")}
      </h4>
      <div className="flex flex-col gap-4 p-1">
        <TagInput
          label=""
          isViewMode={isViewMode}
          inputValue={inputValue}
          tags={formState.palavras_chave || []}
          isLoading={isAddingTag}
          onInputChange={handleInputChange}
          onKeyPress={handleKeyPress}
          onRemoveTag={handleRemoveTag}
        />
      </div>
    </div>
  );
};

export default ProjectKeywordsSection;
