// src/features/dashboard/api/index.ts
import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "config/baseQuery";
import {
  GetPdiBalanceParams,
  GetPdiEvolutionParams,
  GetPdiEvolutionResponse,
  GetSelicEvolutionResponse,
  GetSelicEvolutionParams,
  GetRegulatoryLimitParams,
  GetRegulatoryLimitResponse,
} from "../types";
import { GetPdiBalanceResponse } from "../types";

export const dashboardApi = createApi({
  baseQuery: baseQueryWithAuth,
  reducerPath: "dashboardApi",
  tagTypes: ["Dashboard"],
  endpoints: (builder) => ({
    // vw_saldo_pdi {{url_api}}/view/saldopdi?idsEmpresa=8,7,6&ano=2023
    getPdiBalance: builder.query<GetPdiBalanceResponse, GetPdiBalanceParams>({
      query: (params) => ({
        url: "/view/saldopdi",
        method: "GET",
        params,
      }),
    }),
    // vw_evolucaopdi {{url_api}}/view/evolucaopdi?idsEmpresa=19&ano=2021
    getPdiEvolution: builder.query<
      GetPdiEvolutionResponse,
      GetPdiEvolutionParams
    >({
      query: (params) => ({
        url: "/view/evolucaopdi",
        method: "GET",
        params,
      }),
    }),
    // vw_evolucaoselic {{url_api}}/view/evolucaoselic?idsEmpresa=19,10&ano=2021
    getSelicEvolution: builder.query<
      GetSelicEvolutionResponse,
      GetSelicEvolutionParams
    >({
      query: (params) => ({
        url: "/view/evolucaoselic",
        method: "GET",
        params,
      }),
    }),
    // vw_limiteregulatorio {{url_api}}/view/limiteregulatorio?idsEmpresa=8,7&ano=2023
    getRegulatoryLimit: builder.query<
      GetRegulatoryLimitResponse,
      GetRegulatoryLimitParams
    >({
      query: (params) => ({
        url: "/view/limiteregulatorio",
        method: "GET",
        params,
      }),
    }),
  }),
});

export const {
  useGetPdiBalanceQuery,
  useGetPdiEvolutionQuery,
  useGetSelicEvolutionQuery,
  useGetRegulatoryLimitQuery,
} = dashboardApi;
