import { useTranslation } from "react-i18next";
import TextareaSection from "features/project/components/PDIProject/components/Project/components/Stepper/steps/Description/components/TextareaSection";
import { DescriptionFormState } from "features/project/slices/projectFormSlice";

interface ProjectRelatedResearchSectionProps {
  formState: DescriptionFormState;
  isViewMode: boolean;
  handleTextareaChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const ProjectRelatedResearchSection = ({
  formState,
  isViewMode,
  handleTextareaChange,
}: ProjectRelatedResearchSectionProps) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-3 p-2 rounded-md border border-[--table-header-bg] bg-[--breadcrumb-bg] shadow-sm">
      <h4 className="text-sm text-primary-dark font-semibold p-1.5 bg-[--primary-light] rounded-md shadow-sm text-center">
        {t("project.descriptionStep.sections.relatedResearch")}
      </h4>
      <div className="flex flex-col gap-4 p-1">
        <TextareaSection
          name="pesquisa_correlata"
          value={formState.pesquisa_correlata}
          isViewMode={isViewMode}
          onChange={handleTextareaChange}
          hideLabel={true}
        />
      </div>
    </div>
  );
};

export default ProjectRelatedResearchSection;
