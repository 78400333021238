// src/features/auth/components/Login/components/UserCompanyAccessModal/hooks/useCompanySelection.ts
import { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useDebouncedValue } from "common/hooks/useDebouncedValue";
import { userApi, useGetUserCompaniesNameOnlyQuery } from "features/user/api";
import { setError } from "features/user/slices/userSlice";
import { CompanyQueryParams, AuthCompany } from "features/auth/types/company";
import { CompanySelectionItem } from "../types";
import { ApiErrorResponse } from "common/types";
import { setSelectedCompany } from "features/user/slices/selectedCompanySlice";
import {
  setSelectedCompanyStorage,
  COMPANY_CODE_HEADER_KEY,
} from "../utils/userCompanyAccessUtil";
import {
  startCompanyChange,
  finishCompanyChange,
} from "features/user/slices/selectedCompanySlice";
import { useNavigate } from "react-router-dom";

interface UseCompanySelectionProps {
  isOpen: boolean;
  onClose: () => void;
}

const DEBOUNCE_DELAY = 300;
const MINIMUM_SEARCH_CHARACTERS = 3;

export const useCompanySelection = ({
  isOpen,
  onClose,
}: UseCompanySelectionProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [queryParams, setQueryParams] = useState<CompanyQueryParams>({});
  const debouncedSearchTerm = useDebouncedValue(searchTerm, DEBOUNCE_DELAY);

  const { data, isLoading, isError, error, refetch, isFetching } =
    useGetUserCompaniesNameOnlyQuery(queryParams, {
      skip: !isOpen,
      refetchOnMountOrArgChange: true,
    });

  const [trigger] = userApi.endpoints.getUser.useLazyQuery();

  useEffect(() => {
    if (isOpen) {
      setSearchTerm("");
      setQueryParams({});
      refetch();
    }
  }, [isOpen, refetch]);

  useEffect(() => {
    if (!debouncedSearchTerm) {
      setQueryParams({});
      return;
    }

    if (debouncedSearchTerm.length >= MINIMUM_SEARCH_CHARACTERS) {
      setQueryParams({ filtro_generico: debouncedSearchTerm.trim() });
    }
  }, [debouncedSearchTerm]);

  const handleCompanySelect = useCallback(
    async (company: CompanySelectionItem) => {
      try {
        dispatch(startCompanyChange());

        setSelectedCompanyStorage(company);
        localStorage.setItem(
          COMPANY_CODE_HEADER_KEY,
          company.empresa_id.toString(),
        );

        navigate("/home");
        dispatch(setSelectedCompany(company));
        dispatch(userApi.util.invalidateTags(["User"]));

        await trigger().unwrap();

        onClose();
        dispatch(finishCompanyChange());
      } catch (error) {
        dispatch(setError("Error loading user data. Please try again."));
        dispatch(finishCompanyChange());
      }
    },
    [dispatch, trigger, onClose],
  );

  const companies: CompanySelectionItem[] =
    data?.data?.result?.map((company: AuthCompany) => ({
      empresa_id: company.empresa_id,
      razao_social: company.razao_social,
      nome_fantasia: company.nome_fantasia || "",
      cnpj: company.cnpj,
    })) || [];

  const handleAutoSelectCompany = useCallback(async () => {
    if (companies.length === 1) {
      await handleCompanySelect(companies[0]);
      return true;
    }
    return false;
  }, [companies, handleCompanySelect]);

  return {
    companies,
    isLoading,
    isError,
    isFetching,
    error: error as ApiErrorResponse | null,
    searchTerm,
    handleSearchChange: setSearchTerm,
    handleCompanySelect,
    refetchCompanies: refetch,
    handleAutoSelectCompany,
  };
};
