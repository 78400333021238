import { useTranslation } from "react-i18next";
import { EntitiesFormState } from "features/project/slices/projectFormSlice";
import { formatCurrencyPtBr } from "common/utils/formatters";

interface FinancialInformationProps {
  formData: EntitiesFormState;
}

const FinancialInformation = ({ formData }: FinancialInformationProps) => {
  const { t } = useTranslation();

  const renderInfoField = (label: string, value: string) => (
    <div className="flex justify-between items-center p-1.5 border rounded-md bg-white shadow-sm">
      <p className="text-sm text-text-secondary font-medium">{label}:</p>
      <p className="font-semibold text-primary">{value}</p>
    </div>
  );

  return (
    <div className="flex flex-col gap-3 p-2 rounded-md border border-[--table-header-bg] bg-[--breadcrumb-bg] shadow-sm">
      <h4 className="text-sm text-primary-dark font-semibold p-1.5 bg-[--primary-light] rounded-md shadow-sm text-center">
        {t("project.identificationStep.sections.financialInformation")}
      </h4>

      <div className="flex flex-col gap-4 p-1">
        <div className="grid grid-cols-1 sm:grid-cols-4 gap-3">
          {renderInfoField(
            t("project.entitiesStep.fields.totalValue"),
            formData.vl_projeto_total
              ? formatCurrencyPtBr(formData.vl_projeto_total)
              : "",
          )}
          {renderInfoField(
            t("project.entitiesStep.fields.transferValue"),
            formData.vl_repasse_total
              ? formatCurrencyPtBr(formData.vl_repasse_total)
              : "",
          )}
          {renderInfoField(
            t("project.entitiesStep.fields.internalValue"),
            formData.vl_recurso_interno
              ? formatCurrencyPtBr(formData.vl_recurso_interno)
              : "",
          )}
          {renderInfoField(
            t("project.entitiesStep.fields.counterpartValue"),
            formData.vl_contrapartida
              ? formatCurrencyPtBr(formData.vl_contrapartida)
              : "",
          )}
        </div>
      </div>
    </div>
  );
};

export default FinancialInformation;
