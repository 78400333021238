// src/features/bond/api/index.ts
import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "config/baseQuery";
import {
  NORDraftCalculationResponse,
  GetAccountBalanceResponse,
  SelicRate,
  saveNORDraftCalculationResponse,
  saveNORDraftCalculationRequest,
  BalanceteImportResponse,
  NORListResponse,
  CalculateSelicValueResponse,
  CalculateSelicValueParams,
  NORMonthlyCalculationResponse,
  ResumoObrigacaoAnualizadoResponse,
  PDIObligatonsSummaryResponse,
  SummaryNewNORResponse,
  BalanceSummaryResponse,
  DifferenceAnualizedResponse,
} from "features/bond/types";
import { TableQueryParams } from "common/components/ServerTable/types";

export const bondApi = createApi({
  reducerPath: "bondApi",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["NORDraft", "AccountBalances"],
  endpoints: (builder) => ({
    // getApuracaoMensalEmRascunho
    getNORDraftCalculation: builder.query<
      | NORDraftCalculationResponse
      | {
          mensagem: string;
          data: null;
        },
      void
    >({
      query: () => ({
        url: "apuracao/rascunho",
        method: "GET",
      }),
    }),

    // getTaxaSelicMesReferencia
    getTaxaSelicMesReferencia: builder.query<
      SelicRate,
      { mes: string; ano: string; forcar_atualizacao: boolean }
    >({
      query: (arg) => ({
        url: `selic?mes=${arg.mes}&ano=${arg.ano}&forcar_atualizacao=${arg.forcar_atualizacao}`,
        method: "GET",
      }),
    }),

    // getSaldoConta
    getAccountBalance: builder.query<
      GetAccountBalanceResponse,
      {
        conta_id: number;
        mes?: string;
        ano?: string;
      }
    >({
      query: (arg) => ({
        url: `apuracao/saldoconta`,
        method: "GET",
        params: {
          conta_id: arg.conta_id,
          mes: arg.mes,
          ano: arg.ano,
        },
      }),
      providesTags: ["AccountBalances"],
    }),

    // salvarEditarApuracao
    saveNORCalculation: builder.mutation<
      saveNORDraftCalculationResponse,
      saveNORDraftCalculationRequest
    >({
      query: (data) => ({
        url: "/apuracao",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["NORDraft", "AccountBalances"],
    }),

    // limparApuracao
    clearNORCalculation: builder.mutation<
      { mensagem: string; data: boolean },
      number
    >({
      query: (id) => ({
        url: `/apuracao/${id}/limpar`,
        method: "POST",
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            bondApi.util.updateQueryData(
              "getNORDraftCalculation",
              undefined,
              () => ({
                mensagem: "",
                data: null,
              }),
            ),
          );
        } catch {
          // Handle error if needed
        }
      },
      invalidatesTags: ["NORDraft", "AccountBalances"],
    }),

    //uploadbalancete
    uploadBalancete: builder.mutation<
      BalanceteImportResponse,
      { mes: number; ano: number; balanceteBase64: string }
    >({
      query: (data) => ({
        url: "/apuracao/uploadbalancete",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["NORDraft", "AccountBalances"],
    }),

    // efetivarApuracao
    consolidateNORCalculation: builder.mutation<
      saveNORDraftCalculationResponse,
      saveNORDraftCalculationRequest
    >({
      query: (data) => ({
        url: "/apuracao/efetivar",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["NORDraft", "AccountBalances"],
    }),

    // listarNORCalculations
    getNORList: builder.query<NORListResponse, TableQueryParams>({
      query: (params) => ({
        url: "/apuracao",
        method: "GET",
        params: {
          filtro_generico: params.filtro_generico,
          page: params.page,
          perPage: params.perPage,
          sortDirection: params.sortDirection,
          sortBy: params.sortBy,
          competencia_de: params.competencia_de,
          competencia_ate: params.competencia_ate,
        },
        keepUnusedDataFor: 0,
      }),
    }),

    // CalcularValorSelic
    calculateSelicValue: builder.query<
      CalculateSelicValueResponse,
      CalculateSelicValueParams
    >({
      query: (params) => ({
        url: "/apuracao/calcularselic",
        method: "GET",
        params: {
          competencia: params.competencia,
          tipo_conta: params.tipo_conta,
        },
      }),
    }),

    // getNORMonthlyCalculation
    getNORMonthlyCalculation: builder.query<
      NORMonthlyCalculationResponse,
      { apuracao_id: number }
    >({
      query: (params) => ({
        url: `/apuracao/${params.apuracao_id}`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
    }),

    //getResumoObrigacaoAnualizado
    getResumoObrigacaoAnualizado: builder.query<
      ResumoObrigacaoAnualizadoResponse,
      { empresa_id: number; ano: number }
    >({
      query: (params) => ({
        url: "/apuracao/resumoanualizado",
        method: "GET",
        params: {
          empresa_id: params.empresa_id,
          ano: params.ano,
        },
      }),
    }),

    // GetResumoAnualizadoTabela62
    getPDIObligatonsSummary: builder.query<
      PDIObligatonsSummaryResponse,
      { empresa_id: number; ano: number; conta_id: number }
    >({
      query: (params) => ({
        url: "/apuracao/resumoanualizadotabela62",

        method: "GET",
        params: {
          empresa_id: params.empresa_id,
          ano: params.ano,
          conta_id: params.conta_id,
        },
      }),
    }),

    // Resumo nova rol
    getSummaryNewNOR: builder.query<
      SummaryNewNORResponse,
      { tipo_conta: number[] }
    >({
      query: (params) => ({
        url: "/apuracao/novarol/resumo",
        method: "GET",
        params: {
          tipo_conta: params.tipo_conta,
        },
        keepUnusedDataFor: 0,
      }),
    }),

    // Reabrir Apuração {{url_api}}/apuracao/1/reabrir POST
    reOpenNORCalculation: builder.mutation<
      { mensagem: string; data: boolean },
      number
    >({
      query: (id) => ({
        url: `/apuracao/${id}/reabrir`,
        method: "POST",
      }),
      invalidatesTags: (result) => {
        if (result?.data) {
          return [{ type: "NORDraft" }];
        }
        return [];
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(bondApi.util.invalidateTags([{ type: "NORDraft" }]));
        } catch {
          // Handle error if needed
        }
      },
    }),

    // resumoSaldoAtualizado {{url_api}}/apuracao/resumosaldoatualizado GET
    /* 
    RESPONSE SAMPLE:
    {
    "mensagem": "Saldo em 2024-04-30: ",
    "data": {
        "data_saldo": "2024-04-30",        
        "contas": [
            {                
                "tipo_conta_id": 1,
                "descricao": "PDI",
                "saldo_consolidado": 250352.89,
                "saldo_pdiee": 20000.00,
                "saldo_selic": 5000,
                "saldo_procel":null
            },
            {                
                "tipo_conta_id": 2,
                "descricao": "EE",
                "saldo_consolidado": 250352.89,
                "saldo_pdiee": 20000.00,
                "saldo_selic": 5000,
                "saldo_procel":0
            }
        ]
    }
}
    */
    getBalanceSummary: builder.query<BalanceSummaryResponse, void>({
      query: () => ({
        url: "/apuracao/resumosaldoatualizado",
        method: "GET",
      }),
      keepUnusedDataFor: 0,
    }),

    // Diferença entre calculado e contabilizado anualizado {{url_api}}/apuracao/diferencaanualizado?ano=2021
    /*
    RESPONSE SAMPLE
{
    "mensagem": "Diferenças:",
    "data": {
        "ano": "2025",
        "dif_acumulada": [
            {
                "obrigacao": "PDI",
                "valor_calculado": 362831.08,
                "valor_contabilizado": 362831.08,
                "diferenca": 362831.08
            },
            {
                "obrigacao": "MME",
                "valor_calculado": 362831.08,
                "valor_contabilizado": 362831.08,
                "diferenca": 362831.08
            }
        ]
    }
}
    */
    getDifferenceAnualized: builder.query<
      DifferenceAnualizedResponse,
      { ano: number }
    >({
      query: (params) => ({
        url: "/apuracao/diferencaanualizado",
        method: "GET",
        params,
      }),
    }),
  }),
});

export const {
  useGetNORDraftCalculationQuery,
  useGetTaxaSelicMesReferenciaQuery,
  useGetAccountBalanceQuery,
  useSaveNORCalculationMutation,
  useClearNORCalculationMutation,
  useUploadBalanceteMutation,
  useConsolidateNORCalculationMutation,
  useGetNORListQuery,
  useCalculateSelicValueQuery,
  useGetNORMonthlyCalculationQuery,
  useGetResumoObrigacaoAnualizadoQuery,
  useGetPDIObligatonsSummaryQuery,
  useGetSummaryNewNORQuery,
  useReOpenNORCalculationMutation,
  useGetBalanceSummaryQuery,
  useGetDifferenceAnualizedQuery,
} = bondApi;
