// src/features/company/api/index.ts
import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "config/baseQuery";
import {
  CompanySegmentResponse,
  CompanyTypeResponse,
  GetCompanyByIdResponse,
  CompanyParameterResponse,
  CompanyUserResponse,
  CompanyUserQueryParams,
  CompanyAccountsResponse,
  CompanyInitialBalanceRequest,
  CompanyInitialBalanceResponse,
  UpdateCompanyLogoRequest,
  UpdateCompanyLogoResponse,
  GetCompanyInitialBalanceResponse,
  UpdateCompanyInitialBalanceResponse,
  UpdateCompanyInitialBalanceRequest,
} from "features/company/types";
import { CompanyParameterResponse as CompanyParameterResponseBond } from "features/bond/types";
import {
  RolModelResponse,
  RolModelAccount,
} from "features/company/types/rolModel";
import { Middleware } from "@reduxjs/toolkit";
import { GovernmentCompaniesResponse } from "features/company/types";

interface CrudUpdateAction {
  type: string;
  meta: {
    arg: {
      endpointName: string;
      originalArgs: {
        id: number;
      };
    };
  };
}

function isCrudUpdateAction(action: unknown): action is CrudUpdateAction {
  return (
    typeof action === "object" &&
    action !== null &&
    "type" in action &&
    "meta" in action &&
    action.type === "crudApi/executeMutation/fulfilled" &&
    typeof action.meta === "object" &&
    action.meta !== null &&
    "arg" in action.meta &&
    typeof action.meta.arg === "object" &&
    action.meta.arg !== null &&
    "endpointName" in action.meta.arg &&
    action.meta.arg.endpointName === "update"
  );
}

export const companyApi = createApi({
  reducerPath: "companyApi",
  baseQuery: baseQueryWithAuth,
  tagTypes: [
    "Company",
    "CompanyParameters",
    "CompanyUsers",
    "CompanyRolModel",
    "CompanyInitialBalance",
  ],
  endpoints: (builder) => ({
    getCompanyType: builder.query<CompanyTypeResponse, void>({
      query: () => ({
        url: "empresa/getTipoEmpresa",
        method: "GET",
      }),
    }),

    getCompanySegment: builder.query<CompanySegmentResponse, void>({
      query: () => ({
        url: "empresa/getSegmentoEmpresa",
        method: "GET",
      }),
    }),

    getCompanyById: builder.query<GetCompanyByIdResponse, number>({
      query: (id) => ({
        url: `empresa/${id}`,
        method: "GET",
      }),
      providesTags: (_result, _error, id) => [{ type: "Company", id }],
    }),

    getCompanyParameters: builder.query<CompanyParameterResponse, number>({
      query: (companyId) => ({
        url: `empresa/${companyId}/parametro`,
        method: "GET",
      }),
      providesTags: (_result, _error, id) => [
        { type: "CompanyParameters", id },
      ],
    }),

    getCompanyUsers: builder.query<
      CompanyUserResponse,
      { id: number; params: CompanyUserQueryParams }
    >({
      query: ({ id, params }) => ({
        url: `empresa/${id}/usuarios`,
        method: "GET",
        params,
      }),
      providesTags: (_result, _error, { id }) => [{ type: "CompanyUsers", id }],
      keepUnusedDataFor: 0,
    }),
    // getPlanoContaPadrao
    getCompanyNORModel: builder.query<
      RolModelResponse,
      { companyId: number; somente_ativo?: boolean }
    >({
      query: ({ companyId, somente_ativo }) => ({
        url: `empresa/${companyId}/planoconta`,
        params: {
          somente_ativo,
        },
      }),
    }),
    // Inserir Atualizar Modelo Plano de contas
    updateCompanyRolModel: builder.mutation<
      { mensagem: string; data: RolModelAccount[] },
      { companyId: number; accounts: Partial<RolModelAccount>[] }
    >({
      query: ({ companyId, accounts }) => ({
        url: `empresa/${companyId}/planoconta`,
        method: "POST",
        body: accounts,
      }),
    }),

    updateCompanyParameters: builder.mutation<
      void,
      {
        companyId: number;
        parameters: Array<{
          data_inicio_vigencia: string;
          data_fim_vigencia: string;
          perc_ped_aneel: number;
          perc_ped_cde: number;
          perc_ped_fndct: number;
          perc_ped_mme: number;
          perc_ee_aneel?: number;
          perc_ee_cde?: number;
          perc_ee_procel?: number;
        }>;
      }
    >({
      query: ({ companyId, parameters }) => ({
        url: `empresa/${companyId}/parametro`,
        method: "POST",
        body: parameters,
      }),
      invalidatesTags: (_result, _error, { companyId }) => [
        { type: "CompanyParameters", id: companyId },
      ],
    }),

    getParametroEmpresaVigente: builder.query<
      CompanyParameterResponseBond,
      { companyId: number; month?: number; year?: number }
    >({
      query: ({ companyId, month, year }) => ({
        url: `empresa/${companyId}/parametro/vigente`,
        method: "GET",
        params: {
          mes: month,
          ano: year,
        },
      }),
    }),

    getCompanyAccounts: builder.query<
      CompanyAccountsResponse,
      {
        companyId: number;
        somente_ativo?: boolean;
        tipos?: number[];
        somente_lancamento_manual?: boolean;
      }
    >({
      query: ({
        companyId,
        somente_ativo,
        tipos,
        somente_lancamento_manual,
      }) => ({
        url: `empresa/${companyId}/conta`,
        method: "GET",
        params: {
          somente_ativo,
          tipos: tipos?.join(","),
          somente_lancamento_manual,
        },
      }),
      keepUnusedDataFor: 0,
    }),

    getGovernmentCompanies: builder.query<GovernmentCompaniesResponse, void>({
      query: () => ({
        url: "empresa/governo",
        method: "GET",
      }),
    }),

    createCompanyInitialBalance: builder.mutation<
      CompanyInitialBalanceResponse,
      CompanyInitialBalanceRequest
    >({
      query: (data) => ({
        url: `empresa/${data.company_id}/saldoinicial`,
        method: "POST",
        body: data,
      }),
    }),

    getCompanyInitialBalance: builder.query<
      GetCompanyInitialBalanceResponse,
      { companyId: number }
    >({
      query: ({ companyId }) => ({
        url: `empresa/${companyId}/saldoinicial`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
      providesTags: (_result, _error, { companyId }) => [
        { type: "CompanyInitialBalance", id: companyId },
      ],
    }),

    updateCompanyInitialBalance: builder.mutation<
      UpdateCompanyInitialBalanceResponse,
      UpdateCompanyInitialBalanceRequest
    >({
      query: ({ companyId, initialBalance }) => ({
        url: `empresa/${companyId}/saldoinicial`,
        method: "PUT",
        body: initialBalance,
      }),
    }),

    updateCompanyLogo: builder.mutation<
      UpdateCompanyLogoResponse,
      UpdateCompanyLogoRequest
    >({
      query: ({ companyId, logo_empresa_base64 }) => ({
        url: `empresa/${companyId}`,

        method: "PUT",
        body: { logo_empresa_base64 },
      }),
    }),
  }),
});

export const invalidateCompanyOnCrudUpdate: Middleware =
  (api) => (next) => (action) => {
    const result = next(action);

    if (isCrudUpdateAction(action)) {
      api.dispatch(
        companyApi.util.invalidateTags([
          { type: "Company", id: action.meta.arg.originalArgs.id },
        ]),
      );
    }

    return result;
  };

export const {
  useGetCompanyTypeQuery,
  useGetCompanySegmentQuery,
  useGetCompanyByIdQuery,
  useGetCompanyParametersQuery,
  useGetCompanyUsersQuery,
  useGetCompanyNORModelQuery,
  useUpdateCompanyRolModelMutation,
  useUpdateCompanyParametersMutation,
  useGetParametroEmpresaVigenteQuery,
  useGetCompanyAccountsQuery,
  useGetGovernmentCompaniesQuery,
  useCreateCompanyInitialBalanceMutation,
  useUpdateCompanyLogoMutation,
  useGetCompanyInitialBalanceQuery,
  useUpdateCompanyInitialBalanceMutation,
} = companyApi;
