// src/common/components/Dashboard/components/Header/index.tsx
import { Menu } from "lucide-react";
import LocationIndicator from "common/components/Breadcrumb";

interface HeaderProps {
  className?: string;
  onOpen: () => void;
}

const Header = ({ className, onOpen }: HeaderProps) => {
  return (
    <header
      className={`bg-light-bg px-4 flex items-center h-14 ${className} z-40 border-b border-breadcrumb-border`}
    >
      <button
        onClick={onOpen}
        className="mr-4 p-2 hover:bg-primary-light hover:text-primary-hover rounded-lg transition-all duration-200"
      >
        <Menu className="w-5 h-5" />
      </button>

      <div className="flex-1 min-w-0 overflow-x-auto">
        <LocationIndicator />
      </div>
    </header>
  );
};

export default Header;
