import { useTranslation } from "react-i18next";
import Input from "common/components/Input";
import { IdentificationFormState } from "features/project/slices/projectFormSlice";
import { formatCurrencyPtBr } from "common/utils/formatters";

interface FinancialInformationProps {
  formData: IdentificationFormState;
  handleInputChange: (
    field: keyof IdentificationFormState,
    value: string | number | Date | null,
  ) => void;
  handleCurrencyChange: (
    field: keyof IdentificationFormState,
    value: string,
  ) => void;
  isViewMode: boolean;
}

const FinancialInformation = ({
  formData,
  handleInputChange,
  handleCurrencyChange,
  isViewMode,
}: FinancialInformationProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-3 p-2 rounded-md border border-[--table-header-bg] bg-[--breadcrumb-bg] shadow-sm">
      <h4 className="text-sm text-primary-dark font-semibold p-1.5 bg-[--primary-light] rounded-md shadow-sm text-center">
        {t("project.identificationStep.sections.financialInformation")}
      </h4>

      <div className="flex flex-col gap-4 p-1">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-3">
          <Input
            label={t("project.identificationStep.fields.totalValue")}
            value={
              formData.vl_projeto_total
                ? formatCurrencyPtBr(formData.vl_projeto_total)
                : ""
            }
            onChange={(e) =>
              handleCurrencyChange("vl_projeto_total", e.target.value)
            }
            maskType="currency"
            required
            readOnly={isViewMode}
            disabled={isViewMode}
            className={`${isViewMode ? "bg-gray-100" : ""} border-[--table-header-bg]`}
          />
          <Input
            label={t("project.identificationStep.fields.transferValue")}
            value={
              formData.vl_repasse_total
                ? formatCurrencyPtBr(formData.vl_repasse_total)
                : ""
            }
            onChange={(e) =>
              handleCurrencyChange("vl_repasse_total", e.target.value)
            }
            maskType="currency"
            required
            readOnly={isViewMode}
            disabled={isViewMode}
            className={`${isViewMode ? "bg-gray-100" : ""} border-[--table-header-bg]`}
          />
          <Input
            label={t("project.identificationStep.fields.counterpartValue")}
            value={
              formData.vl_contrapartida
                ? formatCurrencyPtBr(formData.vl_contrapartida)
                : ""
            }
            onChange={(e) =>
              handleCurrencyChange("vl_contrapartida", e.target.value)
            }
            maskType="currency"
            readOnly={isViewMode}
            disabled={isViewMode}
            className={`${isViewMode ? "bg-gray-100" : ""} border-[--table-header-bg]`}
          />
          <Input
            label={t("project.identificationStep.fields.internalValue")}
            value={
              formData.vl_recurso_interno
                ? formatCurrencyPtBr(formData.vl_recurso_interno)
                : ""
            }
            onChange={(e) =>
              handleCurrencyChange("vl_recurso_interno", e.target.value)
            }
            maskType="currency"
            readOnly={isViewMode}
            disabled={isViewMode}
            className={`${isViewMode ? "bg-gray-100" : ""} border-[--table-header-bg]`}
          />
        </div>

        <div className="w-full">
          <Input
            label={t("project.identificationStep.fields.capitation")}
            value={formData.captacao}
            onChange={(e) => handleInputChange("captacao", e.target.value)}
            readOnly={isViewMode}
            disabled={isViewMode}
            className={`${isViewMode ? "bg-gray-100" : ""} border-[--table-header-bg]`}
          />
        </div>
      </div>
    </div>
  );
};

export default FinancialInformation;
