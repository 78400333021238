import { type ReactNode } from "react";
import { useTranslation } from "react-i18next";

type VariantType = "default" | "light";

interface TextareaVariant {
  border: string;
  label: string;
  bg: string;
}

interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  error?: string;
  variant?: VariantType;
  icon?: ReactNode;
  onIconClick?: () => void;
  required?: boolean;
  readOnly?: boolean;
}

const variants: Record<VariantType, TextareaVariant> = {
  default: {
    border: "border-primary border-2",
    label: "text-primary",
    bg: "bg-white",
  },
  light: {
    border: "border-primary border",
    label: "text-text-secondary",
    bg: "bg-white",
  },
};

const Textarea = ({
  label,
  error,
  variant = "default",
  value,
  onChange,
  className = "",
  icon,
  onIconClick,
  required,
  readOnly,
  rows = 4,
  ...props
}: TextareaProps) => {
  const { t } = useTranslation();
  const currentVariant = variants[variant];

  const isControlled = value !== undefined;

  return (
    <div className="w-full">
      {label && (
        <label className={`block text-sm font-medium ${currentVariant.label}`}>
          {label}
          {required && <span className="ml-1 text-primary">*</span>}
        </label>
      )}
      <div className="relative">
        <textarea
          rows={rows}
          value={isControlled ? value : undefined}
          defaultValue={!isControlled ? value : undefined}
          onChange={isControlled && onChange ? onChange : undefined}
          className={`w-full rounded-lg p-1.5 ${currentVariant.border} ${
            currentVariant.bg
          } ${icon ? "pr-10" : ""} ${
            readOnly ? "cursor-default" : ""
          } ${className}`}
          required={required}
          readOnly={readOnly}
          {...props}
        />
        {icon && (
          <button
            type="button"
            onClick={onIconClick}
            className="absolute right-3 top-3 text-text-secondary"
            aria-label={t("textarea.aria.iconButton")}
          >
            {icon}
          </button>
        )}
      </div>
      {error && <span className="mt-1 text-xs text-error">{error}</span>}
    </div>
  );
};

export default Textarea;
