import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface BreadcrumbState {
  currentView?: {
    mode: "create" | "edit" | "view" | null;
  };
}

const initialState: BreadcrumbState = {
  currentView: undefined,
};

const breadcrumbSlice = createSlice({
  name: "breadcrumb",
  initialState,
  reducers: {
    setCurrentView: (
      state,
      action: PayloadAction<BreadcrumbState["currentView"]>,
    ) => {
      state.currentView = action.payload;
    },
    clearCurrentView: (state) => {
      state.currentView = undefined;
    },
  },
});

export const { setCurrentView, clearCurrentView } = breadcrumbSlice.actions;
export default breadcrumbSlice.reducer;
