// src/features/auth/slices/singleCompanyAccessSlice.ts
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";
import { userApi } from "features/user/api";

interface SingleCompanyAccessState {
  shouldAutoLogin: boolean;
}

const initialState: SingleCompanyAccessState = {
  shouldAutoLogin: false,
};

const singleCompanyAccessSlice = createSlice({
  name: "singleCompanyAccess",
  initialState,
  reducers: {
    resetAutoLogin: (state) => {
      state.shouldAutoLogin = false;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      userApi.endpoints.getUserCompanies.matchFulfilled,
      (state, action) => {
        state.shouldAutoLogin = action.payload.data.result.length === 1;
      },
    );
  },
});

export const { resetAutoLogin } = singleCompanyAccessSlice.actions;

export const selectShouldAutoLogin = (state: RootState) =>
  state.singleCompanyAccess.shouldAutoLogin;

export default singleCompanyAccessSlice.reducer;
