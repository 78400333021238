// src/features/forecasting/api/index.ts
import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "config/baseQuery";
import {
  GetForecastingResponse,
  GetForecastingParams,
  GetForecastingByIdResponse,
  CalculateForecastingRequest,
  CalculateForecastingResponse,
  GetForecastingSummaryResponse,
  InsertForecastingResponse,
  InsertForecastingRequest,
  UpdateForecastingResponse,
  UpdateForecastingRequest,
  ImportForecastingRequest,
  ImportForecastingResponse,
} from "features/forecasting/types";

export const forecastingApi = createApi({
  baseQuery: baseQueryWithAuth,
  reducerPath: "forecastingApi",
  tagTypes: ["Forecasting"],
  endpoints: (builder) => ({
    getForecasting: builder.query<GetForecastingResponse, GetForecastingParams>(
      {
        query: (params) => ({
          url: "/previsao",
          method: "GET",
          params,
        }),
        keepUnusedDataFor: 0,
        providesTags: ["Forecasting"],
      },
    ),
    getForecastingById: builder.query<
      GetForecastingByIdResponse,
      { previsao_id: number }
    >({
      query: ({ previsao_id }) => ({
        url: `/previsao/${previsao_id}`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
    }),
    deleteForecasting: builder.mutation<void, number>({
      query: (previsao_id) => ({
        url: `/previsao/${previsao_id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Forecasting"],
    }),
    calculateForecasting: builder.mutation<
      CalculateForecastingResponse,
      CalculateForecastingRequest
    >({
      query: (body) => ({
        url: "/previsao/calcular",
        method: "POST",
        body,
      }),
    }),
    // Resumo nova previsão {{url_api}}/previsao/novaprevisao/resumo GET
    getForecastingSummary: builder.query<GetForecastingSummaryResponse, void>({
      query: () => ({
        url: "/previsao/novaprevisao/resumo",
      }),
      keepUnusedDataFor: 0,
    }),
    // Inserir previsão {{url_api}}/previsao POST
    insertForecasting: builder.mutation<
      InsertForecastingResponse,
      InsertForecastingRequest
    >({
      query: (body) => ({
        url: "/previsao",
        method: "POST",
        body,
      }),
    }),
    // Alterar previsao {{url_api}}/previsao/786 PUT
    updateForecasting: builder.mutation<
      UpdateForecastingResponse,
      { previsao_id: number } & UpdateForecastingRequest
    >({
      query: ({ previsao_id, ...body }) => ({
        url: `/previsao/${previsao_id}`,
        method: "PUT",
        body,
      }),
    }),
    // Importar previsao {{url_api}}/previsao/importar POST
    importForecasting: builder.mutation<
      ImportForecastingResponse,
      ImportForecastingRequest
    >({
      query: (body) => ({
        url: "/previsao/importar",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Forecasting"],
    }),
  }),
});

export const {
  useGetForecastingQuery,
  useGetForecastingByIdQuery,
  useDeleteForecastingMutation,
  useCalculateForecastingMutation,
  useGetForecastingSummaryQuery,
  useInsertForecastingMutation,
  useUpdateForecastingMutation,
  useImportForecastingMutation,
} = forecastingApi;
