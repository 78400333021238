import { useState } from "react";
import Input from "common/components/Input";

interface PhoneInputProps {
  onChange?: (value: string) => void;
  value?: string;
  className?: string;
  error?: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
}

const PhoneInput = ({
  onChange,
  value: externalValue,
  className,
  error,
  label,
  required,
  disabled,
}: PhoneInputProps) => {
  const [internalValue, setInternalValue] = useState("");

  const value = externalValue ?? internalValue;

  const maskPhone = (value: string): string => {
    return value
      .replace(/\D/g, "") // Remove todos os caracteres não numéricos
      .replace(/^(\d{2})(\d)/, "($1) $2") // Adiciona parênteses ao DDD
      .replace(/(\d{1})(\d{4})(\d)/, "$1 $2-$3") // Adiciona espaço e hífen no número
      .replace(/(-\d{4})\d+?$/, "$1"); // Limita o número total de caracteres
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const maskedValue = maskPhone(e.target.value);

    if (!externalValue) {
      setInternalValue(maskedValue);
    }

    onChange?.(maskedValue);
  };

  return (
    <Input
      type="text"
      label={label}
      value={value}
      onChange={handleChange}
      placeholder="(00) 0 0000-0000"
      className={className}
      error={error}
      required={required}
      disabled={disabled}
    />
  );
};

export default PhoneInput;
