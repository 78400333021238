import { useTranslation } from "react-i18next";
import Input from "common/components/Input";
import { IdentificationFormState } from "features/project/slices/projectFormSlice";

interface BasicProjectInformationProps {
  formData: IdentificationFormState;
  handleInputChange: (
    field: keyof IdentificationFormState,
    value: string | number | Date | null,
  ) => void;
  isViewMode: boolean;
  displayProjectType: string;
}

const BasicProjectInformation = ({
  formData,
  handleInputChange,
  isViewMode,
  displayProjectType,
}: BasicProjectInformationProps) => {
  const { t } = useTranslation();

  const renderInfoField = (label: string, value: string) => (
    <div className="flex justify-between items-center p-1.5 border rounded-md bg-white shadow-sm">
      <p className="text-sm text-text-secondary font-medium">{label}:</p>
      <p className="font-semibold text-primary">{value}</p>
    </div>
  );

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-3 p-2 rounded-md border border-[--table-header-bg] bg-[--breadcrumb-bg] shadow-sm">
        <h4 className="text-sm text-primary-dark font-semibold p-1.5 bg-[--primary-light] rounded-md shadow-sm text-center">
          {t("project.identificationStep.sections.basicInformation")}
        </h4>

        <div className="flex flex-col gap-3 p-1">
          {renderInfoField(
            t("project.identificationStep.fields.projectType"),
            displayProjectType,
          )}

          <div className="w-full">
            <Input
              label={t("project.identificationStep.fields.projectName")}
              value={formData.nome}
              onChange={(e) => handleInputChange("nome", e.target.value)}
              required
              readOnly={isViewMode}
              disabled={isViewMode}
              className={`${isViewMode ? "bg-gray-100" : ""} border-[--table-header-bg]`}
            />
          </div>

          <div className="flex flex-col md:flex-row gap-2">
            <div className="flex-grow md:w-3/5">
              <Input
                label={t("project.identificationStep.fields.projectTitle")}
                value={formData.titulo}
                onChange={(e) => handleInputChange("titulo", e.target.value)}
                required
                readOnly={isViewMode}
                disabled={isViewMode}
                className={`${isViewMode ? "bg-gray-100" : ""} border-[--table-header-bg]`}
              />
            </div>
            <div className="md:w-2/5">
              <Input
                label={t("project.identificationStep.fields.shortTitle")}
                value={formData.titulo_resumido}
                onChange={(e) =>
                  handleInputChange("titulo_resumido", e.target.value)
                }
                readOnly={isViewMode}
                disabled={isViewMode}
                className={`${isViewMode ? "bg-gray-100" : ""} border-[--table-header-bg]`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicProjectInformation;
