// src/features/project/components/Executing/index.tsx
import { useTranslation } from "react-i18next";
import { useState, useMemo, useEffect, useRef } from "react";
import Button from "common/components/Button";
import Select from "common/components/Select";
import Modal from "common/components/Modal";
import Input from "common/components/Input";
import { EntitiesFormState } from "features/project/slices/projectFormSlice";
import { formatCurrencyPtBr } from "common/utils/formatters";
import { Trash2 } from "lucide-react";
import Table from "common/components/ServerTable";
import ExecutingCreateModal from "./components/ExecutingCreateModal";
import { CrudMode } from "features/crud/types";
import { ExecutingCompany as ExecutingCompanyType } from "features/project/types";
import {
  ColumnDefinition,
  TableAction,
} from "common/components/ServerTable/types";

interface ExecutingProps {
  formData: EntitiesFormState;
  isViewMode: boolean;
  executingCompaniesOptions: Array<{ value: string; label: string }>;
  isLoadingExecutingCompanies: boolean;
  refetchExecutingCompanies: () => void;
  onUpdateExecuting: (executoras: EntitiesFormState["executoras"]) => void;
}

interface ExecutingCompany extends Record<string, unknown> {
  projeto_entidade_id?: number;
  empresa_id: number;
  empresa_nome: string;
  valor_repasse: number;
  contrapartida: number;
  participacao: number;
}

const Executing = ({
  formData,
  executingCompaniesOptions,
  isLoadingExecutingCompanies,
  isViewMode,
  refetchExecutingCompanies,
  onUpdateExecuting,
}: ExecutingProps) => {
  const { t } = useTranslation();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [newCompanyName, setNewCompanyName] = useState("");
  const [selectedCompanyId, setSelectedCompanyId] = useState("");
  const [repasseValue, setRepasseValue] = useState(0);
  const [contrapartidaValue, setContrapartidaValue] = useState(0);
  const [total, setTotal] = useState(0);
  const [executingCompanies, setExecutingCompanies] = useState<
    ExecutingCompany[]
  >([]);
  const [error, setError] = useState<string | null>(null);
  const formRef = useRef<{ submitForm: () => void } | null>(null);

  const calculateParticipation = (repasse: number, contrapartida: number) => {
    const total = formData.vl_repasse_total + formData.vl_contrapartida;
    if (total === 0) return 0;

    const entityTotal = repasse + contrapartida;
    return (entityTotal / total) * 100;
  };

  const handleCreateOption = (inputValue: string) => {
    setNewCompanyName(inputValue);
    setIsCreateModalOpen(true);
  };

  const handleCreateModalClose = () => {
    setIsCreateModalOpen(false);
    setNewCompanyName("");
  };

  const handleCreateSuccess = (mode: CrudMode, data: ExecutingCompanyType) => {
    setIsCreateModalOpen(false);
    refetchExecutingCompanies();

    if (data && data.executora_id) {
      setSelectedCompanyId(String(data.executora_id));
    }
  };

  const handleRepasseValueChange = (value: string) => {
    const numericValue = parseFloat(value.replace(/\D/g, "")) / 100;
    setRepasseValue(numericValue);
    setTotal(numericValue + contrapartidaValue);
  };

  const handleContrapartidaValueChange = (value: string) => {
    const numericValue = parseFloat(value.replace(/\D/g, "")) / 100;
    setContrapartidaValue(numericValue);
    setTotal(repasseValue + numericValue);
  };

  const validateAdd = () => {
    if (!selectedCompanyId || repasseValue <= 0) {
      setError("Preencha todos os campos obrigatórios");
      return false;
    }

    const companyExists = executingCompanies.some(
      (item) => Number(item.empresa_id) === Number(selectedCompanyId),
    );

    if (companyExists) {
      setError("Esta empresa já foi adicionada");
      return false;
    }

    const currentTotalRepasse = executingCompanies.reduce(
      (sum, item) => sum + item.valor_repasse,
      0,
    );

    const currentTotalContrapartida = executingCompanies.reduce(
      (sum, item) => sum + item.contrapartida,
      0,
    );

    if (currentTotalRepasse + repasseValue > formData.vl_repasse_total) {
      setError(
        `O valor de repasse não pode exceder o valor total de repasse do projeto (${formatCurrencyPtBr(formData.vl_repasse_total)})`,
      );
      return false;
    }

    if (
      currentTotalContrapartida + contrapartidaValue >
      formData.vl_contrapartida
    ) {
      setError(
        `O valor de contrapartida não pode exceder o valor total de contrapartida do projeto (${formatCurrencyPtBr(formData.vl_contrapartida)})`,
      );
      return false;
    }

    setError(null);
    return true;
  };

  const addEntity = () => {
    if (!validateAdd()) return;

    const selectedCompany = executingCompaniesOptions.find(
      (option) => option.value === selectedCompanyId,
    );

    const participation = calculateParticipation(
      repasseValue,
      contrapartidaValue,
    );

    const newEntity: ExecutingCompany = {
      empresa_id: Number(selectedCompanyId),
      empresa_nome: selectedCompany?.label || "",
      valor_repasse: repasseValue,
      contrapartida: contrapartidaValue,
      participacao: participation,
    };

    const updatedExecuting = [...executingCompanies, newEntity];

    setExecutingCompanies(updatedExecuting);

    const formattedExecuting = updatedExecuting.map((item) => ({
      projeto_entidade_id: item.projeto_entidade_id,
      empresa_executora: {
        empresa_id: Number(item.empresa_id),
        nome_fantasia: item.empresa_nome,
        razao_social: "",
      },
      tipo_entidade: {
        tipo_entidade_id: 3,
        descricao: "Executora",
      },
      valor_repasse: item.valor_repasse,
      contrapartida: item.contrapartida,
    }));

    onUpdateExecuting(formattedExecuting);

    setSelectedCompanyId("");
    setRepasseValue(0);
    setContrapartidaValue(0);
    setTotal(0);
  };

  const handleRemoveEntity = (entity: ExecutingCompany) => {
    const entityId = Number(entity.empresa_id);

    const updatedExecuting = executingCompanies.filter(
      (item) => Number(item.empresa_id) !== entityId,
    );

    setExecutingCompanies(updatedExecuting);

    const formattedExecuting = updatedExecuting.map((item) => ({
      projeto_entidade_id: item.projeto_entidade_id,
      empresa_executora: {
        empresa_id: Number(item.empresa_id),
        nome_fantasia: item.empresa_nome,
        razao_social: "",
      },
      tipo_entidade: {
        tipo_entidade_id: 3,
        descricao: "Executora",
      },
      valor_repasse: item.valor_repasse,
      contrapartida: item.contrapartida,
    }));

    onUpdateExecuting(formattedExecuting);
  };

  const tableColumns: ColumnDefinition<ExecutingCompany>[] = useMemo(
    () => [
      {
        key: "empresa_nome",
        title: t("project.entitiesStep.table.headers.company"),
        width: "30%",
      },
      {
        key: "valor_repasse",
        title: t("project.entitiesStep.table.headers.transfer"),
        width: "20%",
        render: (value) => (
          <span className="flex justify-end">
            {formatCurrencyPtBr(value as number)}
          </span>
        ),
      },
      {
        key: "contrapartida",
        title: t("project.entitiesStep.table.headers.counterpart"),
        width: "20%",
        render: (value) => (
          <span className="flex justify-end">
            {formatCurrencyPtBr(value as number)}
          </span>
        ),
      },
      {
        key: "participacao",
        title: t("project.entitiesStep.table.headers.participation"),
        width: "15%",
        render: (value) => (
          <span className="flex justify-center">
            {`${(value as number).toFixed(2)}%`}
          </span>
        ),
      },
    ],
    [t],
  );

  const tableActions: TableAction<ExecutingCompany>[] = useMemo(
    () => [
      {
        label: t("project.entitiesStep.actions.remove"),
        icon: Trash2,
        onClick: (row) => {
          handleRemoveEntity(row);
        },
        variant: "destructive",
        disabled: () => isViewMode,
        show: () => !isViewMode,
      },
    ],
    [t, isViewMode, handleRemoveEntity],
  );

  const renderExecutorCard = (entity: ExecutingCompany) => (
    <div
      key={entity.empresa_id}
      className="p-3 mb-2 bg-white rounded-md border border-[--table-header-bg] shadow-sm"
    >
      <div className="grid grid-cols-12 gap-3">
        <div className="col-span-6">
          <p className="text-sm text-text-secondary font-medium mb-1">
            {t("project.entitiesStep.table.headers.company")}
          </p>
          <p className="font-semibold text-primary">{entity.empresa_nome}</p>
        </div>
        <div className="col-span-2">
          <p className="text-sm text-text-secondary font-medium mb-1">
            {t("project.entitiesStep.table.headers.transfer")}
          </p>
          <p className="font-semibold text-primary text-right">
            {formatCurrencyPtBr(entity.valor_repasse)}
          </p>
        </div>
        <div className="col-span-2">
          <p className="text-sm text-text-secondary font-medium mb-1">
            {t("project.entitiesStep.table.headers.counterpart")}
          </p>
          <p className="font-semibold text-primary text-right">
            {formatCurrencyPtBr(entity.contrapartida)}
          </p>
        </div>
        <div className="col-span-2">
          <p className="text-sm text-text-secondary font-medium mb-1">
            {t("project.entitiesStep.table.headers.participation")}
          </p>
          <p className="font-semibold text-primary text-center">{`${entity.participacao.toFixed(2)}%`}</p>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    if (formData?.executoras?.length > 0) {
      const formattedData = formData.executoras.map((item) => ({
        projeto_entidade_id: item.projeto_entidade_id,
        empresa_id: Number(item.empresa_executora.empresa_id),
        empresa_nome: item.empresa_executora.nome_fantasia,
        valor_repasse: item.valor_repasse,
        contrapartida: item.contrapartida,
        participacao: calculateParticipation(
          item.valor_repasse,
          item.contrapartida,
        ),
      }));

      setExecutingCompanies(formattedData);
    }
  }, [formData]);

  const handleSaveButtonClick = () => {
    if (formRef.current) {
      formRef.current.submitForm();
    } else {
      console.warn("Form reference not available for submission");
    }
  };

  return (
    <div className="flex flex-col gap-3 p-2 rounded-md border border-[--table-header-bg] bg-[--breadcrumb-bg] shadow-sm">
      <h4 className="text-sm text-primary-dark font-semibold p-1.5 bg-[--primary-light] rounded-md shadow-sm text-center">
        {t("project.entitiesStep.sections.executing")}
      </h4>

      {!isViewMode && (
        <>
          <div className="grid grid-cols-12 gap-2 items-end">
            <div className="col-span-4">
              <Select
                label={t("project.entitiesStep.fields.companyByBusinessGroup")}
                options={executingCompaniesOptions}
                value={selectedCompanyId}
                onChange={(value) => setSelectedCompanyId(value)}
                placeholder={t(
                  "project.entitiesStep.placeholders.companyByBusinessGroup",
                )}
                isLoading={isLoadingExecutingCompanies}
                required
                disabled={isViewMode}
                searchable
                allowCreate
                onCreateOption={handleCreateOption}
              />
            </div>

            <div className="col-span-3">
              <Input
                label={t("project.entitiesStep.fields.transfer")}
                value={repasseValue ? formatCurrencyPtBr(repasseValue) : ""}
                onChange={(e) => handleRepasseValueChange(e.target.value)}
                maskType="currency"
                required
                disabled={isViewMode}
                className="border-[--table-header-bg] w-full"
                placeholder="0,00"
              />
            </div>

            <div className="col-span-3">
              <Input
                label={t("project.entitiesStep.fields.counterpart")}
                value={
                  contrapartidaValue
                    ? formatCurrencyPtBr(contrapartidaValue)
                    : ""
                }
                onChange={(e) => handleContrapartidaValueChange(e.target.value)}
                maskType="currency"
                required
                disabled={isViewMode}
                className="border-[--table-header-bg] w-full"
                placeholder="0,00"
              />
            </div>

            <div className="col-span-2">
              <Input
                label={t("project.entitiesStep.fields.total")}
                value={total ? formatCurrencyPtBr(total) : ""}
                readOnly
                disabled
                className="bg-gray-100 border-[--table-header-bg] w-full"
                placeholder="0,00"
              />
            </div>
          </div>

          {error && <div className="text-red-500 text-sm mt-1">{error}</div>}

          <div className="flex justify-end mt-2">
            <Button
              variant="primary"
              onClick={addEntity}
              disabled={isViewMode || !selectedCompanyId || repasseValue <= 0}
            >
              {t("project.entitiesStep.buttons.add")}
            </Button>
          </div>
        </>
      )}

      <div className="mt-4">
        {isViewMode ? (
          <div className="flex flex-col">
            {executingCompanies.length > 0 ? (
              executingCompanies.map((entity) => renderExecutorCard(entity))
            ) : (
              <div className="p-4 text-center text-gray-500">
                {t("project.entitiesStep.table.emptyMessage")}
              </div>
            )}
          </div>
        ) : (
          <Table
            columns={tableColumns}
            data={executingCompanies}
            keyField="empresa_id"
            emptyMessage={t("project.entitiesStep.table.emptyMessage")}
            actionColumn={{
              position: "end",
              actions: tableActions,
            }}
            pagination={false}
          />
        )}
      </div>

      <Modal
        isOpen={isCreateModalOpen}
        onClose={handleCreateModalClose}
        title={t("project.entitiesStep.executingModal.title")}
        size="xl"
        closeOnBackdropClick={false}
        className="flex flex-col w-[1000px] max-w-[95vw] h-fit max-h-[90vh]"
        footer={
          <div className="flex justify-end gap-2">
            <Button variant="link" onClick={handleCreateModalClose}>
              {t("button.cancel")}
            </Button>
            <Button variant="primary" onClick={handleSaveButtonClick}>
              {t("button.save")}
            </Button>
          </div>
        }
      >
        <ExecutingCreateModal
          isOpen={isCreateModalOpen}
          onClose={handleCreateModalClose}
          onSuccess={handleCreateSuccess}
          initialData={{
            nome_fantasia: newCompanyName,
          }}
          formRef={formRef}
        />
      </Modal>
    </div>
  );
};

export default Executing;
