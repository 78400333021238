import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ErrorAlert from "common/components/ErrorAlert";
import Spinner from "common/components/Spinner";
import StepContainer from "features/company/components/CompanyInitialBalanceStepper/components/StepContainer";
import FinancialInformation from "features/project/components/PDIProject/components/Project/components/Stepper/steps/Entities/components/FinancialInformation";
import { useEntities } from "./hooks/useEntities";
import ProponentsPartners from "features/project/components/PDIProject/components/Project/components/Stepper/steps/Entities/components/ProponentsPartners";
import Executing from "features/project/components/PDIProject/components/Project/components/Stepper/steps/Entities/components/Executing";
import EntitiesView from "features/project/components/PDIProject/components/Project/components/Stepper/steps/Entities/components/EntitiesView";

interface EntitiesProps {
  onSaveStep?: (success: boolean, projectId?: number) => void;
  viewMode?: "view" | "edit" | "create";
  projectId?: number;
  registerSaveHandler?: (handler: () => Promise<boolean>) => void;
}

const Entities = ({
  onSaveStep,
  viewMode = "edit",
  projectId: initialProjectId,
  registerSaveHandler,
}: EntitiesProps) => {
  const { t } = useTranslation();
  const isViewMode = viewMode === "view";

  const {
    formData,
    isLoadingEntities,
    isSaving,
    handleSave,
    entityTypeOptions,
    companyByBusinessGroupOptions,
    executingCompaniesOptions,
    isLoadingEntityType,
    isLoadingCompanyByBusinessGroup,
    isLoadingExecutingCompanies,
    handleUpdateProponents,
    handleUpdateExecuting,
    error,
    setError,
    refetchCompanyByBusinessGroup,
    refetchExecutingCompanies,
  } = useEntities({
    initialProjectId,
    viewMode,
    onSaveStep,
  });

  useEffect(() => {
    if (registerSaveHandler) {
      registerSaveHandler(handleSave);
    }
  }, [registerSaveHandler, handleSave]);

  return (
    <StepContainer>
      <div className="flex flex-col gap-4 p-4 h-full">
        {error && (
          <ErrorAlert
            message={error}
            onDismiss={() => setError(null)}
            className="mb-2"
          />
        )}

        {isSaving && (
          <div className="flex justify-center items-center my-2">
            <Spinner size="sm" label={t("project.entitiesStep.saving")} />
          </div>
        )}

        {isLoadingEntities && (
          <div className="flex justify-center items-center p-8">
            <Spinner
              size="lg"
              label={t("common.loading")}
              className="flex items-center justify-center"
            />
          </div>
        )}

        {!isLoadingEntities && isViewMode && (
          <EntitiesView formData={formData} />
        )}

        {!isLoadingEntities && !isViewMode && (
          <div className="grid grid-cols-1 gap-6">
            <FinancialInformation formData={formData} />

            <ProponentsPartners
              formData={formData}
              entityTypeOptions={entityTypeOptions}
              isLoadingEntityType={isLoadingEntityType}
              isViewMode={isViewMode}
              companyByBusinessGroupOptions={companyByBusinessGroupOptions}
              isLoadingCompanyByBusinessGroup={isLoadingCompanyByBusinessGroup}
              refetchCompanyByBusinessGroup={refetchCompanyByBusinessGroup}
              onUpdateProponents={handleUpdateProponents}
            />

            <Executing
              formData={formData}
              isViewMode={isViewMode}
              executingCompaniesOptions={executingCompaniesOptions}
              isLoadingExecutingCompanies={isLoadingExecutingCompanies}
              refetchExecutingCompanies={refetchExecutingCompanies}
              onUpdateExecuting={handleUpdateExecuting}
            />
          </div>
        )}
      </div>
    </StepContainer>
  );
};

export default Entities;
