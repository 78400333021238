import { useMemo, useImperativeHandle, useRef } from "react";
import { useTranslation } from "react-i18next";
import CrudForm from "features/crud/components/CrudForm";
import { ExecutingCompany } from "features/project/types";
import { CrudMode, BaseEntity } from "features/crud/types";
import { ApiError } from "features/crud/types/error";
import Spinner from "common/components/Spinner";
import { getExecutingCompanyCrudConfig } from "./config";
import { getDisabledFields } from "./utils/formUtils";
import { entityTransformers } from "features/crud/utils/transformer";
import { useGetExecutingCompanyByIdQuery } from "features/project/api";
import { ApiErrorResponse } from "common/types";
import { LocationFormData } from "features/location/components/UnifiedLocationForm";

interface ExecutingCompanyForm extends BaseEntity {
  razao_social: string;
  nome_fantasia: string;
  cnpj: string;
  cep: string;
  endereco: string;
  numero: string;
  bairro: string;
  municipio_ibge_id: number;
  telefone: string;
  locationData?: LocationFormData;
  ativa: boolean;
}

interface ExecutingCreateModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: (mode: CrudMode, data: ExecutingCompany) => void;
  onError?: (error: ApiError) => void;
  initialId?: number;
  mode?: CrudMode;
  initialData?: Partial<ExecutingCompanyForm>;
  disabledFields?: string[];
  formRef?: React.MutableRefObject<{ submitForm: () => void } | null>;
}

const ExecutingCreateModal = ({
  onClose,
  onSuccess,
  onError,
  initialId,
  mode = "create",
  initialData,
  disabledFields: customDisabledFields,
  formRef,
}: ExecutingCreateModalProps) => {
  const { t } = useTranslation();
  const formContainerRef = useRef<HTMLDivElement>(null);

  const {
    data: executingCompanyResponse,
    isLoading,
    error,
  } = useGetExecutingCompanyByIdQuery(initialId as number, {
    skip: !initialId || mode === "create",
    refetchOnMountOrArgChange: true,
  });

  const formInitialData = useMemo(() => {
    if (initialData) return initialData;

    if (!executingCompanyResponse?.data) return undefined;

    const transformedData = entityTransformers.executora?.response(
      executingCompanyResponse.data as unknown as Record<string, unknown>,
    );
    return transformedData;
  }, [executingCompanyResponse, initialData]);

  const defaultDisabledFields = useMemo(() => getDisabledFields(mode), [mode]);

  const mergedDisabledFields = useMemo(() => {
    if (!customDisabledFields) return defaultDisabledFields;
    return Array.from(
      new Set([...defaultDisabledFields, ...customDisabledFields]),
    );
  }, [defaultDisabledFields, customDisabledFields]);

  const handleSuccess = (mode: CrudMode, data: ExecutingCompanyForm) => {
    const executingCompany = {
      executora_id: data.id,
      razao_social: data.razao_social,
      nome_fantasia: data.nome_fantasia,
      cnpj: data.cnpj,
      cep: data.cep,
      endereco: data.endereco,
      numero: data.numero,
      bairro: data.bairro,
      municipio_ibge_id: data.municipio_ibge_id,
      telefone: data.telefone,
      ativa: data.ativa,
    } as ExecutingCompany;

    onSuccess?.(mode, executingCompany);
    onClose();
  };

  const enhancedConfig = useMemo(() => {
    const baseConfig = getExecutingCompanyCrudConfig(mode);

    return {
      ...baseConfig,
      fields: baseConfig.fields.map((field) => ({
        ...field,
        disabled: mergedDisabledFields.includes(field.name as string),
      })),
    };
  }, [mode, mergedDisabledFields]);

  useImperativeHandle(formRef, () => ({
    submitForm: () => {
      if (formContainerRef.current) {
        const formElement = formContainerRef.current.querySelector("form");
        if (formElement) {
          try {
            formElement.dispatchEvent(
              new Event("submit", { bubbles: true, cancelable: true }),
            );
          } catch (e) {
            console.error("Error submitting form:", e);
          }
        }
      }
    },
  }));

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-[200px]">
        <Spinner size="lg" />
      </div>
    );
  }

  const initialError = error
    ? ({
        status: (error as ApiErrorResponse).statusCode || 500,
        data: {
          mensagem:
            (error as ApiErrorResponse)?.detalhes?.errorIdentifier ||
            t("executingCompany.error.loading"),
        },
      } as ApiError)
    : undefined;

  return (
    <div
      ref={formContainerRef}
      className="executing-form-container"
      style={{ position: "relative" }}
    >
      <style>
        {`
          .executing-form-container form > div:last-child {
            display: none;
          }
        `}
      </style>
      <CrudForm<ExecutingCompanyForm>
        config={{
          ...enhancedConfig,
        }}
        initialData={formInitialData}
        onSuccess={handleSuccess}
        onError={onError}
        initialId={initialId}
        mode={mode}
        initialFormError={initialError}
        headerConfig={{
          title: "",
          showBackButton: false,
          onBack: onClose,
        }}
      />
    </div>
  );
};

export default ExecutingCreateModal;
