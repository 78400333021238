export const removeMask = (value: string): string => {
  return value.replace(/[^\d]/g, "");
};

export const formatCNPJ = (cnpj: string): string => {
  const cleaned = removeMask(cnpj);
  return cleaned.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
    "$1.$2.$3/$4-$5",
  );
};

export const formatPhone = (phone?: string): string => {
  if (!phone) return "";
  const numbers = removeMask(phone);
  return numbers
    .replace(/^(\d{2})(\d)/, "($1) $2")
    .replace(/(\d{1})(\d{4})(\d)/, "$1 $2-$3")
    .replace(/(-\d{4})\d+?$/, "$1");
};

export const formatCurrency = (value: number): string => {
  if (isNaN(value)) return "0,00";

  const formattedValue = Math.abs(value)
    .toFixed(2) // Ensures two decimal places
    .replace(".", ",") // Replace decimal point with a comma
    .replace(/\B(?=(\d{3})+(?!\d))/g, "."); // Add thousands separator

  return value < 0 ? `-${formattedValue}` : `${formattedValue}`;
};

export const formatCurrencyPtBr = (value: number): string => {
  return new Intl.NumberFormat("pt-BR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
};

export const formatDate = (date: string): string => {
  if (!date) return "";

  const [year, month, day] = date.split("-");
  return `${day}/${month}/${year}`;
};

export const formatDateWithMonth = (date: string): string => {
  if (!date) return "";

  const [year, month] = date.split("-");
  return `${month}/${year}`;
};

type BrazilianTimeZone =
  | "America/Sao_Paulo"
  | "America/Manaus"
  | "America/Belem"
  | "America/Rio_Branco";

export const formatDateWithBrazilianTime = (
  dateString: string | null,
  timeZone: BrazilianTimeZone = "America/Sao_Paulo",
): string => {
  if (!dateString) return "-";

  try {
    const date = new Date(dateString + "Z");

    return new Intl.DateTimeFormat("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      timeZone,
    }).format(date);
  } catch (error) {
    console.error("Error formatting date:", error);
    return dateString || "-";
  }
};

export const formatCompetence = (date: string): string => {
  if (!date) return "";

  const [year, month] = date.split("-");
  return `${month}/${year}`;
};

export const getLastPeriod = (period: string): string => {
  const [year, month] = period.split("-").map(Number);

  // If month is January (01), go back to December of previous year
  if (month === 1) {
    return `${year - 1}-12`;
  }

  // For all other months, just decrease month by 1
  // Pad month with leading zero if needed
  const lastMonth = month - 1;
  const paddedMonth = lastMonth.toString().padStart(2, "0");

  return `${year}-${paddedMonth}`;
};

export const getNextPeriod = (period: string): string => {
  const [year, month] = period.split("-").map(Number);

  // If month is December (12), go to January of next year
  if (month === 12) {
    return `${year + 1}-01`;
  }

  // For all other months, just increase month by 1
  // Pad month with leading zero if needed
  const nextMonth = month + 1;
  const paddedMonth = nextMonth.toString().padStart(2, "0");

  return `${year}-${paddedMonth}`;
};
