import { useTranslation } from "react-i18next";
import {
  useCreateBusinessCompanyMutation,
  useGetCompanyGroupQuery,
} from "features/businessGroup/api";
import Select from "common/components/Select";
import { handleError } from "common/hooks/handleErr";
import { BusinessGroupRequest } from "features/businessGroup/types";

interface BusinessGroupSelectProps {
  value: number;
  onChange: (value: number) => void;
  error?: string;
  disabled?: boolean;
  className?: string;
}

const BusinessGroupSelect = ({
  value,
  onChange,
  error,
  disabled,
  className,
}: BusinessGroupSelectProps) => {
  const { t } = useTranslation();
  const { data, error: fetchError, refetch } = useGetCompanyGroupQuery();
  const [createBusinessCompany] = useCreateBusinessCompanyMutation();

  const currentValue =
    typeof value === "object" && value !== null
      ? (value as any).grupo_empresarial_id?.toString()
      : typeof value === "number"
        ? value.toString()
        : "";

  const options =
    data?.data.map((item) => ({
      value: item.grupo_empresarial_id.toString(),
      label: item.nome_grupo_empresarial,
    })) ?? [];

  const handleChange = (selectedValue: string) => {
    if (!selectedValue) {
      onChange?.(0);
      return;
    }

    const numValue = Number(selectedValue);
    if (!Number.isNaN(numValue)) {
      const selectedItem = data?.data.find(
        (item) => item.grupo_empresarial_id.toString() === selectedValue,
      );

      if (selectedItem) {
        onChange?.(selectedItem.grupo_empresarial_id);
      } else {
        onChange?.(numValue);
      }
    }
  };

  const handleCreateOption = async (inputValue: string) => {
    try {
      const requestData: BusinessGroupRequest = {
        nome_grupo_empresarial: inputValue,
      };

      await createBusinessCompany(requestData).unwrap();

      await refetch();
      const updatedData = await refetch();

      const newGroup = updatedData.data?.data.find(
        (group) => group.nome_grupo_empresarial === inputValue,
      );

      if (newGroup?.grupo_empresarial_id) {
        onChange?.(newGroup.grupo_empresarial_id);
      }
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <Select
      label={t("businessGroup.label")}
      options={options}
      value={currentValue}
      onChange={handleChange}
      className={className}
      disabled={disabled}
      error={error || (fetchError ? t("businessGroup.error") : undefined)}
      searchable={true}
      allowCreate={true}
      onCreateOption={handleCreateOption}
      required
    />
  );
};

export default BusinessGroupSelect;
