import Modal from "common/components/Modal";
import { useEffect, useRef } from "react";

interface SuccessModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  message: string;
  confirmButtonText?: string;
  showConfirmButton?: boolean;
  size?: "sm" | "md" | "lg" | "xl";
  additionalContent?: React.ReactNode;
}

const SuccessModal = ({
  isOpen,
  onClose,
  title = "Sucesso",
  message,
  confirmButtonText = "Continuar",
  showConfirmButton = true,
  size = "md",
  additionalContent,
}: SuccessModalProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (isOpen && buttonRef.current) {
      buttonRef.current.focus();
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      size={size}
      closeOnBackdropClick={false}
      footer={
        showConfirmButton ? (
          <div className="flex items-center justify-center">
            <button
              ref={buttonRef}
              onClick={onClose}
              className="rounded-md bg-primary px-4 py-2 text-white hover:bg-primary-dark transition-colors"
            >
              {confirmButtonText}
            </button>
          </div>
        ) : null
      }
    >
      <div className="flex flex-col items-center justify-center text-center">
        <div className="mb-4">
          <svg
            className="w-16 h-16 text-green-500 mx-auto"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>

        <p className="text-lg mb-4">{message}</p>

        {additionalContent && (
          <div className="w-full mt-4">{additionalContent}</div>
        )}
      </div>
    </Modal>
  );
};

export default SuccessModal;
