import React from "react";
import NiceAvatar from "react-nice-avatar";
import { AvatarConfig } from "features/user/types";

interface AvatarProps {
  config: AvatarConfig | null | undefined;
  className?: string;
  size?: string;
  email?: string; // We'll keep this for backwards compatibility but won't use it
}

const Avatar = ({ config, className, size = "40px" }: AvatarProps) => {
  if (!config) return null;

  return (
    <NiceAvatar
      className={className}
      style={{ width: size, height: size }}
      {...config}
    />
  );
};

export default Avatar;
